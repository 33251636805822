const productInfoList = [
  {
    name: 'Extreme Precipitation Tables - HTML',
    size: 'full',
    mapNeeded: true,
    menus: {
      options: [
        {
          name: 'Smoothing',
          options: [
            'Yes',
            'No'
          ]
        },
        {
          name: 'Delivery',
          options: [
            'Popup',
            'Download'
          ]
        }
      ],
    }
  },
  {
    name: 'Extreme Precipitation Tables - Text/CSV',
    size: 'full',
    mapNeeded: true,
    menus: {
      options: [
        {
          name: 'Smoothing',
          options: [
            'Yes',
            'No'
          ]
        }
      ],
    }
  },
  {
    name: 'Partial Duration Series - by Point',
    size: 'full',
    mapNeeded: true,
    menus: {
      options: [
        {
          name: 'Duration',
          selected: '1day',
          options: [
            '1hr',
            '2hr',
            '3hr',
            '6hr',
            '12hr',
            '24hr',
            '48hr',
            'blank',
            '1day',
            '2day',
            '4day',
            '7day',
            '10day',
          ]
        },
        {
          name: 'Delivery',
          options: [
            'Popup',
            'Download'
          ]
        }
      ],
    }
  },
  {
    name: 'Partial Duration Series - by Station',
    size: 'full',
    mapNeeded: false,
    menus: {
      options: [
        {
          name: 'Duration',
          selected: '1day',
          options: [
            '1hr',
            '2hr',
            '3hr',
            '6hr',
            '12hr',
            '24hr',
            '48hr',
            'blank',
            '1day',
            '2day',
            '4day',
            '7day',
            '10day',
          ]
        }
      ],
      extra: {
        name: 'Search Parameter(s)',
        options: [
          {
            name: 'State',
            options: [
              '',
              'Connecticut',
              'Maine',
              'Massachusetts',
              'New Hampshire',
              'New York',
              'Rhode Island',
              'Vermont',
            ]
          },
          {
            name: 'Station ID',
            options: []
          },
          {
            name: 'Name',
            options: []
          }
        ]
      },
    }
  },
  {
    name: 'Distribution Curves - Graphical',
    size: 'full',
    mapNeeded: true,
    menus: {
      options: [
        {
          name: 'Recurrence',
          options: [
            '1yr',
            '2yr ( 50% )',
            '5yr ( 20% )',
            '10yr ( 10% )',
            '25yr ( 4% )',
            '50yr ( 2% )',
            '100yr ( 1% )',
            '200yr ( .5% )',
            '500yr ( .2% )',
          ]
        },
        {
          name: 'Compare to',
          options: [
            'None',
            'Type II Curve',
            'Type III Curve',
            '1yr',
            '2yr ( 50% )',
            '5yr ( 20% )',
            '10yr ( 10% )',
            '25yr ( 4% )',
            '50yr ( 2% )',
            '100yr ( 1% )',
            '200yr ( .5% )',
            '500yr ( .2% )',
          ]
        },
        {
          name: 'Smoothing',
          options: [
            'Yes',
            'No'
          ]
        }
      ],
    }
  },
  {
    name: 'Distribution Curves - Text/TBL',
    size: 'full',
    mapNeeded: true,
    menus: {
      options: [
        {
          name: 'Recurrence',
          options: [
            '1yr',
            '2yr ( 50% )',
            '5yr ( 20% )',
            '10yr ( 10% )',
            '25yr ( 4% )',
            '50yr ( 2% )',
            '100yr ( 1% )',
            '200yr ( .5% )',
            '500yr ( .2% )',
          ]
        },
        {
          name: 'Smoothing',
          options: [
            'Yes',
            'No'
          ]
        },
        {
          name: 'Delivery',
          options: [
            'Download',
            'Popup'
          ]
        }
      ],
    }
  },
  {
    name: 'Intensity Frequency Duration Graphs',
    size: 'full',
    mapNeeded: true,
    menus: {
      options: [
        {
          name: 'Duration Range',
          selected: '1hr  - 48hr',
          options: [
            '5min - 120min',
            '1hr  - 48hr',
            '1day - 10day'
          ]
        },
        {
          name: 'Intensity Units',
          selected: 'inches/hour',
          options: [
            'inches/minute',
            'inches/hour',
            'inches/day'
          ]
        },
        {
          name: 'Recurrence',
          options: [
            '1yr',
            '2yr ( 50% )',
            '5yr ( 20% )',
            '10yr ( 10% )',
            '25yr ( 4% )',
            '50yr ( 2% )',
            '100yr ( 1% )',
            '200yr ( .5% )',
            '500yr ( .2% )',
          ]
        },
        {
          name: 'Confidence Limits',
          selected: 'Hide',
          options: [
            'Show',
            'Hide'
          ]
        }
      ],
    }
  },
  {
    name: 'Precipitation Frequency Duration Graphs',
    size: 'full',
    mapNeeded: true,
    menus: {
      options: [
        {
          name: 'Duration Range',
          options: [
            '5min - 120min',
            '1hr  - 48hr',
            '1day - 10day'
          ]
        },
        {
          name: 'Recurrence',
          options: [
            '1yr',
            '2yr ( 50% )',
            '5yr ( 20% )',
            '10yr ( 10% )',
            '25yr ( 4% )',
            '50yr ( 2% )',
            '100yr ( 1% )',
            '200yr ( .5% )',
            '500yr ( .2% )',
          ]
        },
        {
          name: 'Confidence Limits',
          options: [
            'Show',
            'Hide'
          ]
        }
      ],
    }
  },
  {
    name: 'GIS Data Files',
    size: 'half',
    mapNeeded: false,
    menus: {
      options: [
        {
          name: 'Duration',
          options: [
            '5min',
            '10min',
            '15min',
            '30min',
            '60min',
            '120min',
            '1hr',
            '2hr',
            '3hr',
            '6hr',
            '12hr',
            '24hr',
            '48hr',
            'blank',
            '1day',
            '2day',
            '4day',
            '7day',
            '10day',
          ]
        },
        {
          name: 'Recurrence',
          options: [
            '1yr',
            '2yr ( 50% )',
            '5yr ( 20% )',
            '10yr ( 10% )',
            '25yr ( 4% )',
            '50yr ( 2% )',
            '100yr ( 1% )',
            '200yr ( .5% )',
            '500yr ( .2% )',
          ]
        }
      ],
      extra: {
        name: 'Data Type',
        options: [
          {
            name: 'Data Type',
            selected: 'Return Period Estimates',
            options: [
              'Return Period Estimates',
              'Upper Confidence Limit Values',
              'Lower Confidence Limit Values'
            ]
          }
        ]}
    }
  },
  {
    name: 'Regional/State Maps',
    size: 'half',
    mapNeeded: false,
    menus: {
      options: [
        {
          name: 'Duration',
          options: [
            '5min',
            '10min',
            '15min',
            '30min',
            '60min',
            '120min',
            '1hr',
            '2hr',
            '3hr',
            '6hr',
            '12hr',
            '24hr',
            '48hr',
            'blank',
            '1day',
            '2day',
            '4day',
            '7day',
            '10day',
          ]
        },
        {
          name: 'Recurrence',
          options: [
            '1yr',
            '2yr ( 50% )',
            '5yr ( 20% )',
            '10yr ( 10% )',
            '25yr ( 4% )',
            '50yr ( 2% )',
            '100yr ( 1% )',
            '200yr ( .5% )',
            '500yr ( .2% )',
          ]
        },
        {
          name: 'Display',
          options: [
            'Color',
            'Greyscale',
            'Black & White'
          ]
        }
      ],
      extra: {
        name: 'Region/State',
        options: [
          {
            name: 'Region/State',
            options: [
              '',
              'Northeast US',
              'Connecticut',
              'Maine',
              'Massachusetts',
              'New Hampshire',
              'New York',
              'Rhode Island',
              'Vermont'
            ]
          }
        ]
      }
    }
  }
];

export default productInfoList;