import React from 'react';
import PropTypes from 'prop-types';

import HelpIcon from '@material-ui/icons/Help';
import { makeStyles } from '@material-ui/core/styles';

import {
  generateManualImage,
  generateManualParagraph,
  generateManualLists
} from '../HelperScripts/generateManualContent';

// Create style classes for entire component
const useStyles = makeStyles(() => ({
  contentRight: {
    boxSizing: 'border-box',
    width: '452px',
    height: '752px',
    backgroundColor: 'rgb(222,222,222)',
    border: '1px solid white'
  },
  iconBackground: {
    backgroundColor: 'white',
    borderRadius: '50%',
    height: '38px',
    width: '38px',
    margin: '5px 10px 15px 3px',
    border: '1px solid black'
  },
  helpIcon: {
    height: '38px',
    width: '38px',
    color: '#17177d',
    boxSizing: 'border-box',
    margin: '0px'
  },
  headingText: {
    fontFamily: '"Verdana", "Arial", "sans-serif"',
    fontSize: '11pt',
    fontWeight: '700',
    color: 'white',
    marginTop: '8px'
  },
  headingCont: {
    backgroundColor: 'rgb(0,0,107)',
    height: '30px',
    display: 'flex'
  },
  main: {
    width: '100%',
    height: '720px',
    padding: '0px 50px 15px 50px',
    boxSizing: 'border-box',
    overflow: 'auto',
  }
}));

const useOtherStyles = makeStyles(() => ({
  text: {
    marginTop: '15px',
    fontSize: '10pt',
    fontFamily: '"Arial", "sans-serif"',
    lineHeight: '125%',
  },
  sectionHeading: {
    marginTop: '20px',
    marginBottom: '5px',
    fontFamily: '"Verdana", "Arial", "sans-serif"',
    fontSize: '11pt',
    fontWeight: 'bold',
    borderBottom: '1px solid #444444'
  },
  link: {
    color: '#000099',
    fontFamily: '"Arial", "sans-serif"',
    fontSize: '10pt',
    textDecoration: 'none',
    '&:hover': {
      color: '#aa0000',
      cursor: 'pointer'
    }
  },
  list: {
    listStyleType: 'circle',
    listStylePosition: 'inside',
    fontSize: '9pt'
  },
  li: {
    margin: '2px'
  },
  nonLinkItem: {
    fontFamily: '"Arial", "sans-serif"',
    fontSize: '10pt'
  },
  heading: {
    marginTop: '15px',
    fontFamily: '"Verdana", "Arial", "sans-serif"',
    fontSize: '9pt',
    fontWeight: 'bold',
    fontStyle: 'italic'
  },
  img: {
    width: '250px',
    border: '1px solid #888888',
    margin: '2px 25px 0px 25px'
  },
}));


export default function ManualContent({ title, content }) {
  const classes = useStyles();
  const childClasses = useOtherStyles();

  const renderContent = (content) => {
    if (Array.isArray(content)) {
      return (
        <main className={classes.main}>
          {
            content.map(part => {
              if (part.type === 'p') {
                return generateManualParagraph(part.text, childClasses);
              } else if (part.type === 'li') {
                return generateManualLists(part.lists, childClasses);
              } else if (part.type === 'img') {
                let width = part.width || '250px';

                return generateManualImage(part.fileName, width, part.heading, childClasses);
              } else {
                return '';
              }
            })
          }
        </main>
      );
    } else {
      return content;
    }
  };
  
  return (
    <div className={classes.contentRight}>
      <div className={classes.headingCont}>
        <div className={classes.iconBackground}>
          <HelpIcon className={classes.helpIcon} />
        </div>

        <h2 className={classes.headingText}>{title}</h2>
      </div>

      {renderContent(content)}
      
    </div>
  );
}

ManualContent.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.element.isRequired
};