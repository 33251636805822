import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';


// Create style classes for entire component
const useStyles = makeStyles(() => ({
  navLeft: {
    boxSizing: 'border-box',
    width: '302px',
    height: '752px',
    backgroundColor: 'rgb(204,204,204)',
    border: '1px solid white',
    textAlign: 'center',
    padding: '15px',
    overflow: 'auto'
  },
  titleLink: {
    '&:hover': {
      color: '#aa0000',
      cursor: 'pointer'
    }
  },
  sectionTitle: {
    // eslint-disable-next-line quotes
    fontFamily: "'Arial','sans-serif'",
    fontSize: '12pt',
    fontWeight: '700',
  },
  sectionSubTitle: {
    // eslint-disable-next-line quotes
    fontFamily: "'Arial','sans-serif'",
    fontSize: '8pt',
    fontWeight: '700',
  },
  sectionTitleCont: {
    marginTop: '22px',
    textDecoration: 'none',
    color: 'black',
  },
  navItem: {
    // eslint-disable-next-line quotes
    fontFamily: "'Arial','sans-serif'",
    marginTop: '4px',
    textDecoration: 'none',
    color: 'rgb(0,0,238)',
    fontSize: '11pt',
    lineHeight: '16px',
    '&:hover': {
      color: '#aa0000',
      cursor: 'pointer'
    }
  },
  selected: {
    color: 'rgb(85,26,139)',
    '&:hover': {
      color: 'rgb(85,26,139)',
      cursor: 'default'
    }
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    fontSize: 'inherit'
  }
}));



export default function ManualNav({ selected, setSelected, manualNav }) {
  const classes = useStyles();

  const renderNavItems = () => {
    return (
      manualNav.map((item, index) => {
        let href = item.contentTitle.replaceAll(' ', '_').replaceAll('/','&');
        
        if (item.name === 'Interface') {
          return (
            <li key={item.name} className={classes.sectionTitleCont}>
              <div className={`${classes.sectionTitle} ${selected === item.name && classes.selected}`}>{item.name}</div>
            </li>
          );
        } else if (item.title) {
          return (
            <li
              key={item.name + index}
              className={`${classes.sectionTitleCont} ${classes.titleLink} ${selected === item.name && classes.selected}`}
              onClick={() => setSelected(item)}
            >
              <Link className={classes.link} to={href}>
                <div className={`${classes.sectionTitle} ${selected === item.name && classes.selected}`}>{item.name}</div>
                {item.subtitle && <div className={`${classes.sectionSubTitle} ${selected === item.name && classes.selected}`}>{item.subtitle}</div>}
              </Link>
            </li>
          );
        } else {
          return (
            <li
              key={item.name}
              className={`${classes.navItem} ${selected === item.name && classes.selected}`}
              onClick={() => setSelected(item)}
            >
              <Link className={classes.link} to={href}>{item.name}</Link>
            </li>
          );
        }
      })
    );
  };
  
  return (
    <div className={classes.navLeft}>
      <ul>
        {renderNavItems()}
      </ul>
    </div>
  );
}

ManualNav.propTypes = {
  selected: PropTypes.string.isRequired,
  setSelected: PropTypes.func.isRequired,
  manualNav: PropTypes.array.isRequired
};