import React, { useState, useEffect } from 'react';
import {
  useParams
} from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import ManualNav from './ManualNav';
import ManualContent from './ManualContent';

import { manualNav } from '../Assets/manual';

// Create style classes for entire component
const useStyles = makeStyles(() => ({
  main: {
    boxSizing: 'border-box',
    height: '805px',
  },
  heading: {
    textAlign: 'center',
    color: '#ffff66',
    fontSize: '24pt',
    fontWeight: 'bold',
    // eslint-disable-next-line quotes
    fontFamily: "'Verdana', 'Arial', 'sans-serif'",
    marginBottom: '10px'
  },
  contentCont: {
    display: 'flex',
    width: '802px',
    justifyContent: 'space-between',
    margin: '0 auto'
  },
  contentRight: {
    boxSizing: 'border-box',
    width: '452px',
    height: '752px',
    backgroundColor: 'rgb(222,222,222)',
    border: '1px solid white'
  },
}));



export default function UserManual() {
  const classes = useStyles();
  let { page } = useParams();

  const [selected, setSelected] = useState(() => {
    let str = page.replaceAll('&', '/').replaceAll('_', ' ');
    return manualNav.find(obj => obj.contentTitle === str);
  });

  useEffect(() => {
    let str = page.replaceAll('&', '/').replaceAll('_', ' ');
    setSelected(manualNav.find(obj => obj.contentTitle === str));
  }, [page]);

  return (
    <main className={classes.main}>
      <h1 className={classes.heading}>Precip.net Web Site User Manual</h1>

      <div className={classes.contentCont}>
        <ManualNav
          selected={selected.name}
          setSelected={setSelected}
          manualNav={manualNav}
        />
        
        <ManualContent
          title={selected.contentTitle}
          content={selected.content}
        />
      </div>
    </main>
  );
}

