import React, { useState, useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

import { makeStyles } from '@material-ui/core/styles';

import Header from './Header';
import Footer from './Footer';
import Nav from './Nav';
import About from './About';
import Data from './Data';
import Daily from './Daily';
import Documentation from './Documentation';
import UserManual from './UserManual';
import PdsByStation from './PdsByStation';
import PdsResults from './PdsResults';
import XprecipHtml from './XprecipHtml';
import DistributionCurvesGraph from './DistributionCurvesGraph';
import DistributionCurvesTextTbl from './DistributionCurvesTextTbl';
import IntensityFreqDurGraphs from './IntensityFreqDurGraphs';
import PrecipFreqDurGraphs from './PrecipFreqDurGraphs';

import { categories } from '../Assets/documentation';

import {
  downloadAsHtml,
  downloadAsTextCSV,
  downloadAsTextTbl,
  downloadGIS,
  downloadMap,
} from '../HelperScripts/download';
import {
  getPds,
  getXprecip,
  compute_rf_table,
  TYPE_II,
  TYPE_III,
} from '../HelperScripts/getData';
import { round3Digits, round5Digits } from '../HelperScripts/rounding';

const MAPBOX_API_TOKEN =
  'pk.eyJ1IjoicHJlY2lwYWRtaW4iLCJhIjoiY2txYjNjMHYxMGF4NTJ1cWhibHNub3BrdiJ9.1T_U5frbnHaHonvFpHenxQ';
// 'pk.eyJ1IjoicHJlY2lwYWRtaW4iLCJhIjoiY2xkdWFlNHZvMDN4dzNzcW9rMXBleW56biJ9.xqjan57XV8KdZeM4givEMw';

// Create style classes for entire component
const useStyles = makeStyles(() => ({
  app: {
    boxSizing: 'border-box',
    backgroundColor: '#446666',
    padding: '6px',
    height: '100vh',
    width: '100vw',
    overflow: 'auto',
  },
  manual: {
    backgroundColor: 'rgb(34,34,68)',
  },
  results: {
    backgroundColor: 'white',
  },
}));

const STATE_CODES = {
  '06': ['CT', 'Connecticut'],
  17: ['ME', 'Maine'],
  19: ['MA', 'Massachusetts'],
  27: ['NH', 'New Hampshire'],
  30: ['NY', 'New York'],
  37: ['RI', 'Rhode Island'],
  43: ['VT', 'Vermont'],
};

let blankOptions = {
  Smoothing: null,
  Delivery: null,
  Duration: null,
  State: null,
  'Station ID': null,
  Name: null,
  Recurrence: null,
  'Compare to': null,
  'Duration Range': null,
  'Intensity Unit': null,
  'Confidence Limits': null,
  'Data Type': null,
  Display: null,
  'Region/State': null,
  productName: null,
  point: null,
  elev: null,
  lat: null,
  lon: null,
  omega: null,
  yor: null,
  stateAbbr: null,
  pointData: null,
  start: null,
  end: null,
};

export default function App() {
  const [options, setOptions] = useState(
    JSON.parse(localStorage.getItem('options')) || blankOptions
  );
  const [xprecip, setXprecip] = useState(null);
  const [pds, setPds] = useState(null);
  const [meta, setMeta] = useState(null);
  const [distData, setDistData] = useState({});
  const [freqDurGraphData, setFreqDurGraphData] = useState([]);
  const [filePath, setFilePath] = useState('');

  const classes = useStyles();
  const location = useLocation();

  // Send analytics data to Google
  useEffect(() => {
    ReactGA.initialize('G-03JGMXL4VC');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    if (
      location.pathname === '/product/pds_results' ||
      options['productName'] === 'Partial Duration Series - by Point'
    ) {
      getPds(options, STATE_CODES).then((allData) => {
        setPds(allData.data);
        setMeta(allData.meta);
      });
    } else if (
      location.pathname === '/product/xprecip_results' ||
      options['productName'] === 'Extreme Precipitation Tables - HTML' ||
      options['productName'] === 'Extreme Precipitation Tables - Text/CSV'
    ) {
      getXprecip(options.lat, options.lon, options.Smoothing).then(
        (results) => {
          setXprecip(results);
          setMeta({
            meta: [
              ['Smoothing', options.Smoothing],
              ['State', options.State],
              ['Location', options.Name],
              ['Latitude', `${round3Digits(options.lat)} degrees North`],
              [
                'Longitude',
                `${Math.abs(round3Digits(options.lon))} degrees West`,
              ],
              ['Elevation', `${options.elev} feet`],
              ['Date/Time', Date()],
            ],
          });
        }
      );
    } else if (
      options['productName'] === 'Distribution Curves - Graphical' ||
      options['productName'] === 'Distribution Curves - Text/TBL'
    ) {
      getXprecip(options.lat, options.lon, options.Smoothing).then(
        (results) => {
          const GRAPH_TIMES = [
            '5min',
            '10min',
            '15min',
            '30min',
            '60min',
            '120min',
            '3hr',
            '6hr',
            '12hr',
            '24hr',
          ];
          let selected = GRAPH_TIMES.map(
            (time) => results[time][options['Recurrence'].split(' ')[0]][0]
          );
          let table1 = compute_rf_table(selected);
          let title1 = options['Recurrence'];

          let table2;
          let title2 = options['Compare to'];
          if (options['productName'] === 'Distribution Curves - Graphical') {
            if (options['Compare to'] === 'Type II Curve') {
              table2 = TYPE_II;
            } else if (options['Compare to'] === 'Type III Curve') {
              table2 = TYPE_III;
            } else if (options['Compare to'] === 'None') {
              table2 = false;
            } else {
              let yearOnly = options['Compare to'].split(' ')[0];
              title2 = yearOnly;
              let compare = GRAPH_TIMES.map(
                (time) => results[time][yearOnly][0]
              );
              table2 = compute_rf_table(compare);
            }
          } else {
            const TITLE = `${options['Recurrence'].split('y')[0]}_yr_${
              options['Smoothing'] === 'Yes' ? 'sm' : 'tbl'
            }`;

            let tblOutput = [[TITLE, '', '0.1', '', '', '']];

            let rowNum = 0;
            let colNum = 6;
            table1.forEach((value) => {
              if (colNum === 6) {
                rowNum += 1;
                colNum = 1;
                tblOutput.push(['']);
              }

              tblOutput[rowNum].push(round5Digits(value));
              colNum += 1;
            });

            table1 = tblOutput;
          }

          setDistData({ title1, title2, table1, table2 });
          setMeta({ meta: 'none' });
        }
      );
    } else if (
      options['productName'] === 'Intensity Frequency Duration Graphs' ||
      options['productName'] === 'Precipitation Frequency Duration Graphs'
    ) {
      getXprecip(options.lat, options.lon, 'No').then((results) => {
        let times = [];
        if (options['Duration Range'].includes('min')) {
          times = ['5min', '10min', '15min', '30min', '60min', '120min'];
        } else if (options['Duration Range'].includes('hr')) {
          times = ['1hr', '2hr', '3hr', '6hr', '12hr', '24hr', '48hr'];
        } else if (options['Duration Range'].includes('day')) {
          times = ['1day', '2day', '4day', '7day', '10day'];
        }

        setFreqDurGraphData(
          times.map(
            (time) => results[time][options['Recurrence'].split(' ')[0]]
          )
        );
        setMeta({ meta: 'none' });
      });
    } else if (options['productName'] === 'GIS Data Files') {
      let fileName = 'final_';
      if (options['Data Type'].includes('Return')) {
        fileName += 'NRCS';
      } else if (options['Data Type'].includes('Upper')) {
        fileName += 'upper';
      } else if (options['Data Type'].includes('Lower')) {
        fileName += 'lower';
      }

      setFilePath(
        `${process.env.PUBLIC_URL}/data/gis/${fileName}_${
          options['Duration']
        }_${options['Recurrence'].split(' ')[0]}.asc.gz`
      );
      setMeta({ meta: 'none' });
    } else if (options['productName'] === 'Regional/State Maps') {
      let stateAbbr = 'NRCS';
      Object.keys(STATE_CODES).forEach((code) => {
        if (STATE_CODES[code][1] === options['Region/State']) {
          stateAbbr = STATE_CODES[code][0];
        }
      });

      let color;
      if (options['Display'] === 'Color') {
        color = 'color';
      } else if (options['Display'] === 'Black & White') {
        color = 'blackwhite';
      } else {
        color = 'greyscale';
      }

      setFilePath(
        `${process.env.PUBLIC_URL}/data/maps/map_${stateAbbr}_${
          options['Duration']
        }_${options['Recurrence'].split(' ')[0]}_${color}.jpg`
      );
      setMeta({ meta: 'none' });
    }

    localStorage.setItem('options', JSON.stringify(options));
  }, [options]);

  useEffect(() => {
    if (localStorage.getItem('trigger') === 'true') {
      let component = '';
      let fileName = '';

      if (options['productName'] === 'Extreme Precipitation Tables - HTML') {
        fileName = 'xprecip';
        component = <XprecipHtml xprecip={xprecip} meta={meta} />;
      } else if (
        options['productName'] === 'Partial Duration Series - by Point'
      ) {
        fileName = 'pds';
        component = <PdsResults options={options} pds={pds} meta={meta} />;
      } else if (
        options['productName'] === 'Extreme Precipitation Tables - Text/CSV'
      ) {
        try {
          downloadAsTextCSV(
            options,
            xprecip,
            'extreme_precip_tables_output.txt'
          );
        } catch (e) {
          console.log(e);
        }
      } else if (options['productName'] === 'Distribution Curves - Text/TBL') {
        fileName = 'distribution_curves';
      }

      if (options['productName'] === 'Distribution Curves - Graphical') {
        window.open('/#/product/distribution_curves_graph', '_blank');
      } else if (
        options['productName'] === 'Intensity Frequency Duration Graphs'
      ) {
        window.open('/#/product/intensity_frequency_duration_graphs', '_blank');
      } else if (
        options['productName'] === 'Precipitation Frequency Duration Graphs'
      ) {
        window.open(
          '/#/product/precipitation_frequency_duration_graphs',
          '_blank'
        );
      } else if (options['productName'] === 'Regional/State Maps') {
        downloadMap(filePath);
      } else if (options['productName'] === 'GIS Data Files') {
        downloadGIS(filePath);
      } else if (
        options['productName'] === 'Partial Duration Series - by Station'
      ) {
        if (location.pathname === '/product/pds_by_station') {
          window.open('/#/product/pds_results', '_blank');
        } else {
          window.open('/#/product/pds_by_station', '_blank');
        }
      } else if (options['Delivery'] === 'Download') {
        if (options['productName'] === 'Distribution Curves - Text/TBL') {
          downloadAsTextTbl(distData.table1);
        } else {
          downloadAsHtml(component, `${fileName}_output.html`);
        }
      } else if (options['Delivery'] === 'Popup') {
        window.open(`/#/product/${fileName}_results`, '_blank');
      }

      localStorage.setItem('trigger', 'false');
    }
  }, [meta]);

  const renderMainPage = (main) => {
    const tabs = [
      {
        name: 'About this Project',
        url: '/',
      },
      {
        name: 'Data & Products',
        url: '/data_and_products',
      },
      {
        name: 'Daily Monitoring',
        url: '/daily_monitoring',
      },
      {
        name: 'Documentation',
        url: '/documentation',
      },
    ];

    return (
      <div className={classes.app}>
        <Header />
        <Nav path={location.pathname} tabs={tabs} />

        {main}

        <Footer />
      </div>
    );
  };

  return (
    <Switch>
      <Route path='/product/xprecip_results'>
        <div className={`${classes.app} ${classes.results}`}>
          <XprecipHtml xprecip={xprecip} meta={meta} />
        </div>
      </Route>

      <Route path='/product/pds_results'>
        <div className={`${classes.app} ${classes.results}`}>
          <PdsResults options={options} pds={pds} meta={meta} />
        </div>
      </Route>

      <Route path='/product/pds_by_station'>
        <div className={`${classes.app} ${classes.results}`}>
          <PdsByStation
            options={options}
            STATE_CODES={STATE_CODES}
            setOptions={setOptions}
            setMeta={setMeta}
          />
        </div>
      </Route>

      <Route path='/product/distribution_curves_graph'>
        <div className={`${classes.app} ${classes.results}`}>
          <DistributionCurvesGraph options={options} graphData={distData} />
        </div>
      </Route>

      <Route path='/product/distribution_curves_results'>
        <div className={`${classes.app} ${classes.results}`}>
          <DistributionCurvesTextTbl tableData={distData.table1} />
        </div>
      </Route>

      <Route path='/product/intensity_frequency_duration_graphs'>
        <div className={`${classes.app} ${classes.results}`}>
          <IntensityFreqDurGraphs
            options={options}
            graphData={freqDurGraphData}
          />
        </div>
      </Route>

      <Route path='/product/precipitation_frequency_duration_graphs'>
        <div className={`${classes.app} ${classes.results}`}>
          <PrecipFreqDurGraphs options={options} graphData={freqDurGraphData} />
        </div>
      </Route>

      <Route path='/user_manual/:page'>
        <div className={`${classes.app} ${classes.manual}`}>
          <UserManual />
        </div>
      </Route>

      <Route path='/data_and_products'>
        {renderMainPage(
          <Data
            token={MAPBOX_API_TOKEN}
            STATE_CODES={STATE_CODES}
            setOptions={setOptions}
            blankOptions={blankOptions}
            meta={meta}
            setMeta={setMeta}
          />
        )}
      </Route>

      <Route path='/daily_monitoring'>
        {renderMainPage(
          <Daily STATE_CODES={STATE_CODES} token={MAPBOX_API_TOKEN} />
        )}
      </Route>

      <Route path='/documentation'>
        {renderMainPage(<Documentation categories={categories} />)}
      </Route>

      <Route path='/' exact>
        {renderMainPage(<About />)}
      </Route>
    </Switch>
  );
}
