/* eslint-disable no-undef */
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';


// Create style classes for entire component
const useStyles = makeStyles(() => ({
  main: {
    width: '100%',
    height: '720px',
    padding: '0px 50px 15px 50px',
    boxSizing: 'border-box',
    overflow: 'auto',
  },
  text: {
    marginTop: '15px',
    fontSize: '10pt',
    fontFamily: '"Arial", "sans-serif"',
    lineHeight: '125%',
  },
  detail: {
    fontSize: '10pt',
    fontFamily: '"Arial", "sans-serif"',
    lineHeight: '125%',
  },
  link: {
    color: '#000099',
    fontFamily: '"Arial", "sans-serif"',
    fontSize: '10pt',
    textDecoration: 'none',
    '&:hover': {
      color: '#aa0000',
      cursor: 'pointer'
    }
  },
  sectionHeading: {
    marginTop: '20px',
    marginBottom: '5px',
    fontFamily: '"Verdana", "Arial", "sans-serif"',
    fontSize: '11pt',
    fontWeight: 'bold',
    borderBottom: '1px solid #444444'
  },
  bolded: {
    fontWeight: 'bold'
  },
  contacts: {
    marginTop: '18px'
  },
  img: {
    width: '80px',
    border: '1px solid #888888'
  },
  contact: {
    display: 'flex',
    margin: '5px 2px'
  },
  contactDetails: {
    margin: '23px 0px 0px 4px'
  },
}));



export default function AboutManual() {
  const classes = useStyles();

  return (
    <main className={classes.main}>
      <p className={classes.text}>This guide serves as a quick reference for the functionality included on the Data & Products page of the Extreme Precipitation in New York & New England web site. This information can also be retrieved by clicking any question mark icon on the site.</p>

      <h2 className={classes.sectionHeading}>Project Contacts</h2>
      <p className={classes.detail}><span className={classes.bolded}>Web Address:</span> <a className={classes.link} href="/" target='_blank' rel='noreferrer'>www.precip.net</a></p>
      <p className={classes.detail}><span className={classes.bolded}>E-mail Address:</span> <a className={classes.link} href="mailto:precip@cornell.edu">precip@cornell.edu</a></p>
      
      <div className={classes.contacts}>
        <div className={classes.contact}>
          <img src={process.env.PUBLIC_URL + '/assets/Manual/pic_degaetano.jpg'} className={classes.img}></img>

          <div className={classes.contactDetails}>
            <p className={classes.detail}><span className={classes.bolded}>Dr. Art DeGaetano</span></p>
            <p className={classes.detail}>Director</p>
            <p className={classes.detail}>Northeast Regional Climate Center</p>
            <p className={classes.detail}><a className={classes.link} href='mailto:atd2@cornell.edu'>atd2@cornell.edu</a></p>
          </div>
        </div>
        <div className={classes.contact}>
          <img src={process.env.PUBLIC_URL + '/assets/Manual/pic_zarrow.jpg'} className={classes.img}></img>

          <div className={classes.contactDetails}>
            <p className={classes.detail}><span className={classes.bolded}>Dan Zarrow</span></p>
            <p className={classes.detail}>Research Support Specialist</p>
            <p className={classes.detail}>Northeast Regional Climate Center</p>
            <p className={classes.detail}><a className={classes.link} href='mailto:daz8@cornell.edu'>daz8@cornell.edu</a></p>
          </div>
        </div>
        <div className={classes.contact}>
          <img src={process.env.PUBLIC_URL + '/assets/Manual/pic_merkel.jpg'} className={classes.img}></img>

          <div className={classes.contactDetails}>
            <p className={classes.detail}><span className={classes.bolded}>Bill Merkel</span></p>
            <p className={classes.detail}>Hydraulic Engineer</p>
            <p className={classes.detail}>Natural Resources Conservation Service</p>
            <p className={classes.detail}><a className={classes.link} href='mailto:William.Merkel@wdc.usda.gov'>William.Merkel@wdc.usda.gov</a></p>
          </div>
        </div>
        <div className={classes.contact}>
          <img src={process.env.PUBLIC_URL + '/assets/Manual/pic_quan.jpg'} className={classes.img}></img>

          <div className={classes.contactDetails}>
            <p className={classes.detail}><span className={classes.bolded}>Quan Quan</span></p>
            <p className={classes.detail}>Hydraulic Engineer</p>
            <p className={classes.detail}>Natural Resources Conservation Service</p>
            <p className={classes.detail}><a className={classes.link} href='mailto:Quan.Quan@wdc.usda.gov'>Quan.Quan@wdc.usda.gov</a></p>
          </div>
        </div>
        <div className={classes.contact}>
          <img src={process.env.PUBLIC_URL + '/assets/Manual/pic_wright.jpg'} className={classes.img}></img>

          <div className={classes.contactDetails}>
            <p className={classes.detail}><span className={classes.bolded}>Peter Wright</span></p>
            <p className={classes.detail}>NY State Conservation Engineer</p>
            <p className={classes.detail}>Natural Resources Conservation Service</p>
            <p className={classes.detail}><a className={classes.link} href='mailto:Peter.Wright@ny.usda.gov'>Peter.Wright@ny.usda.gov</a></p>
          </div>
        </div>
      </div>
    </main>
  );
}