export function round0Digits( number ) {
  return (Math.round( Math.round( number * 10 ) / 10 ) / 1).toFixed(0);
}

export function round1Digits( number ) {
  return (Math.round( Math.round( number * 100 ) / 10 ) / 10).toFixed(1);
}

export function round2Digits( number ) {
  return (Math.round( Math.round( number * 1000 ) / 10 ) / 100).toFixed(2);
}

export function round3Digits( number ) {
  return (Math.round( Math.round( number * 10000 ) / 10 ) / 1000).toFixed(3);
}

export function round4Digits( number ) {
  return (Math.round( Math.round( number * 100000 ) / 10 ) / 10000).toFixed(4);
}

export function round5Digits( number ) {
  return (Math.round( Math.round( number * 1000000 ) / 10 ) / 100000).toFixed(5);
}