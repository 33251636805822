import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactMapGL, {
  NavigationControl,
  Popup,
  FlyToInterpolator,
  Marker
} from 'react-map-gl';
import { format, formatISO, parseISO, subDays } from 'date-fns';

import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { getStationList, getStationCalendarData, getMapData } from '../HelperScripts/realTimeData';
import { getXprecip } from '../HelperScripts/getData';
import { round2Digits } from '../HelperScripts/rounding';

import Calendar from './Calendar';
import DailyTable from './DailyTable';


// Create style classes for entire component
const useStyles = makeStyles(() => ({
  dailyCont: {
    boxSizing: 'border-box',
    display: 'flex',
    width: '922px',
    height: '602px',
    padding: '10px',
    backgroundColor: '#eeeeee',
    border: '1px solid black',
    boxShadow: '3px 4px 3px 1px rgba(0,0,0,0.7)',
    margin: '0 auto',
    zIndex: '2'
  },
  main: {
    position: 'relative',
    zIndex: '1',
    padding: '0 17px'
  },
  acisLogo: {
    height: '60px',
  },
  logoCont: {
    width: '200px'
  },
  logoInner: {
    margin:'0 auto',
    width: 'fit-content'
  },
  leftSideSection: {
    border: '1px solid black',
    width: '200px',
    marginBottom: '8px'
  },
  view: {
    height: '25px',
    boxSizing: 'border-box',
    padding: '2px',
    fontFamily: '"Arial","san-serif"',
    display: 'flex',
    gap: '2px',
  },
  viewText: {
    fontSize: '8pt',
    marginTop: '5px'
  },
  viewBtn: {
    backgroundColor: 'black',
    color: 'white',
    fontSize: '12px',
    fontWeight: '700',
    padding: '4px',
    flexGrow: 1,
    textAlign: 'center',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  selectedView: {
    color: 'yellow'
  },
  options: {
    height: '92px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '2px 5px 5px 5px',
    boxSizing: 'border-box'
  },
  results: {
    height: '357px',
    backgroundColor: 'rgb(221,221,221)',
    overflowY: 'auto'
  },
  input: {
    display : 'flex',
    justifyContent: 'space-between'
  },
  date: {
    maxWidth: '130px'
  },
  dateLabel: {
    marginTop: '6px'
  },
  durationLabel: {
    marginTop: '4px'
  },
  label: {
    fontSize: '12px',
    fontFamily: '"Arial","san-serif"',
    fontWeight: '700'
  },
  submit: {
    backgroundColor: 'rgb(19,119,188)',
    boxSizing: 'border-box',
    color: 'white',
    border: 'none',
    height: '22px',
    width: '107px',
    borderRadius: '9px',
    fontSize: '15px',
    fontWeight: '700',
    paddingBottom: '3px',
    margin: '0 auto',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  disclaimer: {
    boxSizing: 'border-box',
    width: '687px',
    height: '27px',
    color: '#cc0000',
    backgroundColor: 'rgb(255,255,170)',
    fontFamily: '"Arial","san-serif"',
    fontSize: '10pt',
    fontWeight: '700',
    border: '1px solid rgb(103,103,103)',
    textAlign: 'center',
    paddingTop: '6px',
    marginBottom: '6px'
  },
  displayCont: {
    display: 'flex',
    position: 'relative'
  },
  mainCont: {
    width: '622px',
    height: '527px',
    border: '1px solid #676767',
    backgroundColor: 'rgb(248,248,248)',
    overflowX: 'hidden',
    overflowY: 'auto',
    boxSizing: 'border-box',
    paddingBottom: '5px'
  },
  legendCont: {
    width: '50px',
    height: '527px',
    marginLeft: '11px',
    borderBottom: '1px solid #676767',
    display: 'flex',
    flexDirection: 'column',
  },
  legendBin: {
    flexGrow: 1,
    border: '1px solid #676767',
    borderBottom: 'none',
    textAlign: 'center',
    fontFamily: '"Arial","san-serif"',
    fontSize: '10pt',
  },
  yrTop: {
    marginTop: '10px',
    fontWeight: '700',
  },
  valueBottom: {
    marginTop: '10px',
  },
  rightPortion: {
    marginLeft: '12px'
  },
  progressStations: {
    margin: '145px 0px 0px 80px',
    color: 'rgb(19,119,188)'
  },
  progressMain: {
    margin: '230px 0px 0px 300px',
    color: 'rgb(19,119,188)'
  },
  stationList: {
    listStyle: 'none'
  },
  stationItem: {
    borderBottom: '1px solid #aaa',
    fontFamily: '"Arial","san-serif"',
    fontSize: '12px',
    padding: '2px 0px 2px 39px',
    lineHeight: '14px',
    textIndent: '-37px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(0,0,0,0.05)'
    }
  },
  stationListTitle: {
    marginBottom: '5px',
    fontSize: '14.4px',
    fontFamily: '"Arial","san-serif"',
    fontWeight: '700',
    textAlign: 'center'
  },
  selectedStn: {
    backgroundColor: '#ff9933',
    '&:hover': {
      cursor: 'default',
      backgroundColor: '#ff9933'
    }
  },
  title: {
    fontFamily: '"Arial","san-serif"',
    fontSize: '14pt',
    fontWeight: '700',
    marginTop: '10px',
    marginBottom: '5px',
    textAlign: 'center'
  },
  subtitle: {
    fontFamily: '"Arial","san-serif"',
    fontSize: '11pt',
    textAlign: 'center'
  },
  mapCont: {
    width: '0px',
    height: '0px',
    position: 'absolute',
    top: '1px',
    left: '1px'
  },
  showMap: {
    width: '620px',
    height: '525px',
  },
  mapNav: {
    position: 'absolute',
    bottom: '40px',
    right: '7px'
  },
  styleSelector: {
    position: 'absolute',
    top: '4px',
    left: '4px',
    display: 'none'
  },
  showStyleSelector: {
    display: 'inline-block'
  },
  styleBtn: {
    border: 'none',
    width: '70px',
    height: '24.5px',
    boxSizing: 'border-box',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgb(228,228,228)'
    }
  },
  topLeft: {
    borderRight: '1px solid rgb(180,180,180)'
  },
  bottomLeft: {
    borderRight: '1px solid rgb(180,180,180)'
  },
  styleBtnRowTop: {
    boxSizing: 'border-box',
    display: 'flex',
    width: '139px',
    border: '1px solid black',
    borderBottom: '1px solid rgb(180,180,180)'
  },
  styleBtnRowBottom: {
    boxSizing: 'border-box',
    display: 'flex',
    width: '139px',
    border: '1px solid black',
    borderTop: 'none'
  },
  selectedStyle: {
    backgroundColor: 'rgb(220,220,220)',
    boxShadow: 'inset 1px 1px 1px 1px rgba(0,0,0,0.15)',
    '&:hover': {
      cursor: 'default',
      backgroundColor: 'rgb(220,220,220)'
    }
  },
  noStations: {
    fontSize: '14pt',
    fontFamily: '"Arial","san-serif"',
    fontWeight: '700',
    color: 'rgb(170,0,0)',
    textAlign: 'center',
    padding: '5px',
    marginTop: '25px'
  },
  popupStorm: {
    color: 'rgb(211,0,0)',
    fontSize: '10pt',
    fontWeight: '700',
    fontFamily: '"Arial","san-serif"',
    margin: '2px 0px'
  },
  popupLocation: {
    fontSize: '10pt',
    fontWeight: '700',
    fontFamily: '"Arial","san-serif"',
    margin: '2px 0px'
  },
  popupMeta: {
    fontSize: '8pt',
    fontWeight: '300',
    fontFamily: '"Roboto", "Arial","san-serif"',
    margin: '2px 0px'
  },
  popupTable: {
    marginTop: '12px',
    border: '1px solid rgb(147,147,147)'
  },
  borderedCell: {
    border: '2px solid rgb(80,80,80)',
    fontSize: '8pt',
    fontFamily: '"Roboto", "Arial","san-serif"',
    fontWeight: '700',
    padding: '2px 16px'
  },
  marker: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: '11px',
    height: '11px',
    transform: 'translate(0, -100%) rotate(45deg)',
    borderRadius: '8px 8px 0px 8px',
    boxShadow: '1px 1px 2px 2px rgba(0, 0, 0, 0.2)',
    border: '1px solid black',
    cursor: 'pointer',
  },
  legendMarker: {
    width: '11px',
    height: '11px',
    transform: 'rotate(45deg)',
    borderRadius: '8px 8px 0px 8px',
    boxShadow: '1px 1px 2px 2px rgba(0, 0, 0, 0.2)',
    border: '1px solid black',
    margin: '12px auto'
  }
}));

const DURATIONS = ['1day','2day','4day','7day','10day'];
const YEARS_LIST = ['1yr','2yr','5yr','10yr','25yr','50yr','100yr','200yr','500yr'];

const LEGEND_BINS = {
  '1yr': '#ff776b',
  '2yr': '#fd8d08',
  '5yr': '#ffed5c',
  '10yr': '#bce3ff',
  '25yr': '#6b98ff',
  '50yr': '#97ec7d',
  '100yr': '#01bf00',
  '200yr': '#c89bff',
  '500yr': '#fdabff'
};

const clearLegend = () => {
  let legendVals = {};
  Object.keys(LEGEND_BINS).forEach(yr => legendVals[yr] = '');
  return legendVals;
};



export default function Daily({ STATE_CODES, token }) {
  const [view, setView] = useState('Map');
  const [date, setDate] = useState(formatISO(subDays(new Date(), 1), { representation: 'date' }));
  const [duration, setDuration] = useState(DURATIONS[0]);
  const [state, setState] = useState('CT');
  const [year, setYear] = useState('2009');
  const [loadingStations, setLoadingStations] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [sortedStations, setSortedStations] = useState([]);
  const [selectedStation, setSelectedStation] = useState('');
  const [legendValues, setLegendValues] = useState(clearLegend());
  const [acisData, setAcisData] = useState({});
  const [mapData, setMapData] = useState(false);
  const [tableData, setTableData] = useState({});
  const [mapStyle, setMapStyle] = useState('mapbox://styles/mapbox/streets-v11');
  const [selectedStyle, setSelectedStyle] = useState('Map');
  const [location, setLocation] = useState(false);
  const [popup, setPopup] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [viewport, setViewport] = useState({
    latitude: 44.11,
    longitude: -73.39,
    zoom: 4.9,
    minZoom: 4.9,
  });

  const classes = useStyles();


  useEffect(() => {
    setSortedStations([]);
  }, [view]);

  useEffect(() => {
    if (location) {
      setViewport({
        ...viewport,
        latitude: location[0],
        longitude: location[1],
        transitionInterpolator: new FlyToInterpolator(),
        transitionDuration: 500
      });
    }
  }, [location]);
  
  useEffect(() => {
    setTimeout(() => {
      setViewport({
        ...viewport,
        zoom: viewport.zoom + 0.000001
      });
    }, 0);
  }, [markers]);

  useEffect(() => {
    setMapData(false);
  }, [duration, date]);

  const renderFormContents = () => {
    if (view === 'Map') {
      return (
        <>
          <div className={classes.input}>
            <label className={`${classes.label} ${classes.dateLabel}`}>Date:</label>
            <input className={classes.date} type='date' value={date} max={formatISO(subDays(new Date(), 1), { representation: 'date' })} onChange={(event) => setDate(event.target.value)}></input>
          </div>
          <div className={classes.input}>
            <label className={`${classes.label} ${classes.durationLabel}`}>Duration:</label>
            <select
              value={duration}
              onChange={(event) => setDuration(event.target.value)}
            >
              {DURATIONS.map(dur => <option value={dur} key={dur}>{dur}</option>)}
            </select>
          </div></>
      );
    } else if (view === 'Table') {
      return (
        <>
          <div className={classes.input}>
            <label className={`${classes.label} ${classes.dateLabel}`}>Date:</label>
            <input className={classes.date} type='date' value={date} onChange={(event) => setDate(event.target.value)}></input>
          </div>
          <div className={classes.input}>
            <label className={`${classes.label} ${classes.durationLabel}`}>State:</label>
            <select
              value={state}
              onChange={(event) => setState(event.target.value)}
            >
              {Object.keys(STATE_CODES).sort((a,b) => parseInt(a) - parseInt(b)).map(stateCode => <option value={STATE_CODES[stateCode][0]} key={STATE_CODES[stateCode][0]}>{STATE_CODES[stateCode][1]}</option>)}
            </select>
          </div></>
      );
    } else {
      let opt = [];
      for (let i = 2009; i <= new Date().getFullYear(); i++) {
        opt.push(<option value={String(i)} key={i}>{String(i)}</option>);
      }

      return (
        <>
          <div className={classes.input}>
            <label className={`${classes.label} ${classes.dateLabel}`}>Year:</label>
            <select
              value={year}
              onChange={(event) => setYear(event.target.value)}
              style={{marginTop: '4px'}}
            >
              {opt}
            </select>
          </div>
          <div className={classes.input}>
            <label className={`${classes.label} ${classes.durationLabel}`}>State:</label>
            <select
              value={state}
              onChange={(event) => setState(event.target.value)}
            >
              {Object.keys(STATE_CODES).sort((a,b) => parseInt(a) - parseInt(b)).map(stateCode => <option value={STATE_CODES[stateCode][0]} key={STATE_CODES[stateCode][0]}>{STATE_CODES[stateCode][1]}</option>)}
            </select>
          </div></>
      );
    }
  };

  const renderLegend = () => {
    return Object.keys(LEGEND_BINS).map(yr => {
      if (view === 'Map') {
        return (
          <div key={yr} className={classes.legendBin} style={{backgroundColor: LEGEND_BINS[yr]}}>
            <div
              className={classes.legendMarker}
              style={{backgroundColor: LEGEND_BINS[yr]}}
            >
            </div>
            <div className={classes.yrTop}>{yr}</div>
          </div>
        );
      } else if (view === 'Table') {
        return (
          <div key={yr} className={classes.legendBin} style={{backgroundColor: LEGEND_BINS[yr]}}>
            <div className={classes.yrTop}>{yr}</div>
            <div className={classes.valueBottom}></div>
          </div>
        );
      } else {
        return (
          <div key={yr} className={classes.legendBin} style={{backgroundColor: LEGEND_BINS[yr]}}>
            <div className={classes.yrTop}>{yr}</div>
            <div className={classes.valueBottom}>{legendValues[yr]}</div>
          </div>
        );
      }
    });
  };

  const renderMain = () => {
    if (view === 'Table' && Object.keys(tableData).length > 0) {
      return (
        <>
          <h2 className={classes.title}>{tableData.meta.name}, (#{tableData.meta.sid})</h2>
          <h3 className={classes.subtitle}><i>{tableData.meta.lat}&deg;N, {tableData.meta.lon}&deg;W, {tableData.meta.elev} feet</i></h3>

          <DailyTable 
            data={tableData}
            LEGEND_BINS={LEGEND_BINS}
            YEARS_LIST={YEARS_LIST}
          />
        </>
      );
    } else if (view === 'Calendar' && Object.keys(acisData).length > 0) {
      return (
        <>
          <h2 className={classes.title}>{year} Extreme Precipitation Calendar</h2>
          <h3 className={classes.subtitle}><i>{acisData.meta.name}, (#{acisData.sid})</i></h3>

          <Calendar
            year={year}
            data={acisData}
            LEGEND_BINS={LEGEND_BINS}
            legendValues={legendValues}
          />
        </>
      );
    } else {
      return '';
    }
  };

  const renderStationList = () => {
    if (view === 'Map') {
      if (mapData) {
        if (mapData.length === 0) {
          return (
            <div className={classes.noStations}>No stations reported a {duration} extreme precipitation event on {format(parseISO(date), 'MMM d, y')}</div>
          );
        } else {
          return (
            <ul className={classes.stationList}>
              {
                mapData.map(stationObj => {
                  return (
                    <li
                      key={stationObj.id}
                      onClick={() => handleStationPopup(stationObj)}
                      className={classes.stationItem}
                      style={{backgroundColor: LEGEND_BINS[stationObj.yrStorm]}}
                    >
                      <span style={{fontWeight: '700'}}>{stationObj.yrStorm}</span>: {stationObj.name}, {stationObj.state}
                    </li>
                  );
                })
              }
            </ul>
          );
        }
      }
    } else {
      return (<>
        <div className={classes.stationListTitle}>Select a Station:</div>
        <ul className={classes.stationList}>
          {
            sortedStations.map(stationArr => {
              return (
                <li
                  key={stationArr[0]}
                  onClick={() => handleStationSelected(stationArr)}
                  className={`${classes.stationItem} ${selectedStation === stationArr[0] && classes.selectedStn}`}
                >
                  {stationArr[0]}
                </li>
              );
            })
          }
        </ul>
      </>);
    }
  };

  const handleStyleSelect = (name, style) => {
    setSelectedStyle(name);
    setMapStyle(style);
  };

  const handlePanning = (view) => {
    let nextView = view;

    if (nextView.latitude > 49 || nextView.latitude < 40) {
      nextView.latitude = viewport.latitude;
    }

    if (nextView.longitude > -66 || nextView.longitude < -81) {
      nextView.longitude = viewport.longitude;
    }

    setViewport(nextView);
  };

  const handleStationPopup = (stnObj) => {
    setLocation([stnObj.lat, stnObj.lon]);
    setPopup(stnObj);
  };

  const handleStationSelected = async (stnArr) => {
    setLoadingData(true);

    if (view === 'Table') {
      let xprecip = await getXprecip(stnArr[1].meta.lat, stnArr[1].meta.lon, false);

      YEARS_LIST.forEach(yr => {
        stnArr[1][yr] = DURATIONS.map(dur => {
          return round2Digits(xprecip[dur][yr][0]);
        });
      });

      setTableData(stnArr[1]);
    } else {
      let aData = await getStationCalendarData(year, stnArr[1]);
      let xPrecipData = await getXprecip(aData.meta.ll[1], aData.meta.ll[0], false);
      
      let legendVals = {};
      Object.keys(LEGEND_BINS).forEach(yr => legendVals[yr] = round2Digits(xPrecipData['1day'][yr][0]) + '"');
      
      setAcisData({ ...aData, sid: stnArr[1]});
      setLegendValues(legendVals);
      setSelectedStation(stnArr[0]);
    }
    setLoadingData(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    setTableData({});
    setAcisData({});
    setMapData(false);
    setLegendValues(clearLegend());
    setSortedStations([]);
    setSelectedStation('');
    setLoadingStations(true);
    setPopup(null);

    if (view === 'Map') {
      setLoadingData(true);
      
      let eDate = parseISO(date);
      let bDate = subDays(eDate, parseInt(duration.split('y')[0]));
      let results = await getMapData(formatISO(bDate, { representation: 'date' }), date, duration.split('d')[0]);

      setMarkers(results.map(stnObj => {
        return (
          <Marker
            key={stnObj.id}
            latitude={stnObj.lat}
            longitude={stnObj.lon}
          >
            <div
              className={classes.marker}
              style={{backgroundColor: LEGEND_BINS[stnObj.yrStorm]}}
              onClick={() => handleStationPopup(stnObj)}
            >
            </div>
          </Marker>
        );
      }));
      
      setMapData(results);
      setLoadingData(false);
    } else if (view === 'Table') {
      let sDate = formatISO(subDays(parseISO(date), 9), { representation: 'date' });
      let url = `https://data.rcc-acis.org/MultiStnData?state=${state}&sDate=${sDate}&eDate=${date}&elems=pcpn&meta=name,state,sids,ll,elev&output=json`;
      let results = await getStationList(url, date);
      setSortedStations(results);
    } else {
      let url = `https://data.rcc-acis.org/MultiStnData?state=${state}&date=${year}-01-01&elems=pcpn&meta=name,state,sids&output=json`;
      let results = await getStationList(url);
      setSortedStations(results);
    }

    setLoadingStations(false);
  };

  return (
    <main className={classes.main}>
      <div className={classes.dailyCont}>
        <div>
          <div className={`${classes.leftSideSection} ${classes.view}`}>
            <div className={classes.viewText}>View:</div>
            <div onClick={() => setView('Map')} className={`${classes.viewBtn} ${view === 'Map' && classes.selectedView}`}>Map</div>
            <div onClick={() => setView('Table')} className={`${classes.viewBtn} ${view === 'Table' && classes.selectedView}`}>Table</div>
            <div onClick={() => setView('Calendar')} className={`${classes.viewBtn} ${view === 'Calendar' && classes.selectedView}`}>Calendar</div>
          </div>

          <form className={`${classes.leftSideSection} ${classes.options}`}>
            {renderFormContents()}
            
            <button className={classes.submit} onClick={handleSubmit}>Continue &gt;&gt;</button>
          </form>

          <div className={`${classes.leftSideSection} ${classes.results}`}>
            {loadingStations && <CircularProgress className={classes.progressStations} />}
            {((sortedStations.length > 0 && view !== 'Map') || (mapData && view === 'Map')) && renderStationList()}
          </div>

          <div className={classes.logoCont}>
            <div className={classes.logoInner}>
              <a className={classes.logoA} href="http://www.rcc-acis.org" target='_blank' rel='noreferrer'><img className={classes.acisLogo} src={process.env.PUBLIC_URL + '/assets/Logos/acis_btn.png'}></img></a>
            </div>
          </div>
        </div>
        
        <div className={classes.rightPortion}>
          <div className={classes.disclaimer}>Real-time monitoring is in BETA testing. Please be patient - products may take 10-15 seconds to load.</div>

          <div className={classes.displayCont}>
            <div className={classes.mainCont}>
              {loadingData && <CircularProgress className={classes.progressMain} />}
              {!loadingData && renderMain()}
            </div>

            <div className={`${classes.mapCont} ${mapData && view === 'Map' && classes.showMap}`}>
              <ReactMapGL
                {...viewport}
                width= "100%"
                height= "100%"
                onViewportChange={nextViewport => handlePanning(nextViewport)}
                mapboxApiAccessToken={token}
                mapStyle={mapStyle}
                dragRotate={false}
                doubleClickZoom={false}
              >

                <NavigationControl
                  className={classes.mapNav}
                  showCompass={false}
                />

                {markers}

                {popup && <Popup
                  tipSize={10}
                  anchor="top"
                  longitude={popup.lon}
                  latitude={popup.lat}
                  closeOnClick={false}
                  closeButton={true}
                  onClose={() => setPopup(null)}
                  offsetLeft={7}
                  offsetTop={-10}
                >
                  <div className={classes.popupStorm}>{popup.yrStorm}+ Storm Event</div>
                  <div className={classes.popupLocation}>{popup.name}, {popup.state}</div>
                  <div className={classes.popupMeta}>( {popup.lat}&deg;N, {popup.lon}&deg;W, {popup.elev} feet )</div>
                  <table className={classes.popupTable}>
                    <tbody>
                      <tr><th colSpan={2} className={classes.borderedCell}>{duration} Precipitation:</th></tr>
                      <tr style={{color:'#dd0000'}}>
                        <th className={classes.borderedCell}>{date}</th>
                        <th className={classes.borderedCell} style={{textAlign: 'right', padding: '2px 2px 2px 30px'}}>{popup.val}&quot;</th>
                      </tr>
                      
                      {
                        popup.xPrecip.map((val, i) => {
                          let yrs = Object.keys(LEGEND_BINS);
                          
                          return (
                            <tr key={val}>
                              <th className={classes.borderedCell}>{yrs[i]}</th>
                              <td className={classes.borderedCell} style={{fontWeight: '400', textAlign: 'right', padding: '2px 2px 2px 30px'}}>{val}&quot;</td>
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  </table>
                </Popup>}
              </ReactMapGL>

              <div className={`${classes.styleSelector} ${mapData && view === 'Map' && classes.showStyleSelector}`}>
                <div className={classes.styleBtnRowTop}>
                  <button className={`${classes.styleBtn} ${classes.topLeft} ${selectedStyle === 'Hybrid' && classes.selectedStyle}`} onClick={() => handleStyleSelect('Hybrid', 'mapbox://styles/mapbox/satellite-streets-v11')}>Hybrid</button>
                  <button className={`${classes.styleBtn} ${selectedStyle === 'Map' && classes.selectedStyle}`} onClick={() => handleStyleSelect('Map', 'mapbox://styles/mapbox/streets-v11')}>Map</button>
                </div>
                <div className={classes.styleBtnRowBottom}>
                  <button className={`${classes.styleBtn} ${classes.bottomLeft} ${selectedStyle === 'Satellite' && classes.selectedStyle}`} onClick={() => handleStyleSelect('Satellite', 'mapbox://styles/mapbox/satellite-v9')}>Satellite</button>
                  <button className={`${classes.styleBtn} ${selectedStyle === 'Terrain' && classes.selectedStyle}`} onClick={() => handleStyleSelect('Terrain', 'mapbox://styles/mapbox/outdoors-v11')}>Terrain</button>
                </div>
              </div>
            </div>

            <div className={classes.legendCont}>
              {renderLegend()}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

Daily.propTypes = {
  STATE_CODES: PropTypes.object,
  token: PropTypes.string
};