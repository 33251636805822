import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { round2Digits } from '../HelperScripts/rounding';

const TABLE_ROWS = [
  '1yr',
  '2yr',
  '5yr',
  '10yr',
  '25yr',
  '50yr',
  '100yr',
  '200yr',
  '500yr',
];
const TABLE_COLS = [
  '',
  '5min',
  '10min',
  '15min',
  '30min',
  '60min',
  '120min',
  '',
  '1hr',
  '2hr',
  '3hr',
  '6hr',
  '12hr',
  '24hr',
  '48hr',
  '',
  '1day',
  '2day',
  '4day',
  '7day',
  '10day',
  '',
];

// Styles in this component are inline to allow for style html downloads
export default function XPrecipHtml({ xprecip, meta }) {
  const renderSections = () => {
    let sectionTitles = [
      'Extreme Precipitation Estimates',
      'Lower Confidence Limits',
      'Upper Confidence Limits',
    ];

    return sectionTitles.map((title, index) => renderSection(title, index));
  };

  const renderSection = (title, number) => {
    let color = title.includes('Extreme') ? 'black' : 'rgb(102,102,102)';

    const BODY = {
      height: '24px',
      width: '53px',
      padding: '0px',
      border: '1px solid rgb(150,150,150)',
      outline: '1px solid black',
      fontSize: 16,
      lineHeight: '16px',
      textAlign: 'center',
      fontFamily: '"Times New Roman", "sans-serif"',
      color: color,
    };

    const HEAD = {
      ...BODY,
      fontWeight: '700',
      height: 'auto',
      paddingTop: '5px',
      paddingBottom: '5px',
    };

    return (
      <div
        key={title}
        style={{ boxSizing: 'border-box', margin: '10px 20px 20px 20px' }}
      >
        <h2 style={{ fontSize: '24px', fontWeight: '700', margin: '5px 0px' }}>
          {title}
        </h2>

        <TableContainer
          style={{
            border: '1px solid rgb(80,80,80)',
            display: 'flex',
            width: '1166px',
          }}
        >
          <Table key={uuidv4()}>
            <TableHead>
              {TABLE_COLS.map((name) => (
                <TableCell key={uuidv4()} sx={HEAD}>
                  {name}
                </TableCell>
              ))}
            </TableHead>

            <TableBody>
              {TABLE_ROWS.map((tablerow) => {
                return (
                  <TableRow key={uuidv4()}>
                    {TABLE_COLS.map((col) => {
                      return col === '' ? (
                        <TableCell key={uuidv4()} sx={HEAD}>
                          {tablerow}
                        </TableCell>
                      ) : (
                        <TableCell key={uuidv4()} sx={BODY}>
                          {xprecip[col][tablerow][number] === -999
                            ? -999
                            : round2Digits(xprecip[col][tablerow][number])}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  if (meta) {
    try {
      return (
        <main>
          <h1
            style={{ fontSize: '32px', fontWeight: '700', margin: '5px 0px' }}
          >
            Extreme Precipitation Tables
          </h1>
          <h2
            style={{ fontSize: '24px', fontWeight: '700', margin: '5px 0px' }}
          >
            Northeast Regional Climate Center
          </h2>
          <h4
            style={{
              fontSize: '16px',
              fontWeight: '700',
              fontStyle: 'italic',
              margin: '5px 0px',
            }}
          >
            Data represents point estimates calculated from partial duration
            series. All precipitation amounts are displayed in inches.
          </h4>

          <div
            style={{
              border: '3px double black',
              boxSizing: 'border-box',
              width: '605px',
              margin: '10px 20px 20px 20px',
            }}
          >
            <h3
              style={{
                fontSize: '19px',
                fontWeight: '700',
                margin: '10px 0px',
                textAlign: 'center',
              }}
            >
              Metadata for Point
            </h3>

            {meta.meta.map((tup) => {
              return (
                <div key={uuidv4()} style={{ display: 'flex' }}>
                  <div
                    style={{
                      width: '175px',
                      padding: '2px',
                      fontSize: '16px',
                      fontWeight: '700',
                      textAlign: 'center',
                    }}
                  >
                    {tup[0]}
                  </div>
                  <div
                    style={{
                      boxSizing: 'border-box',
                      padding: '2px 2px 2px 10px',
                    }}
                  >
                    {tup[1]}
                  </div>
                </div>
              );
            })}
          </div>

          {renderSections()}

          <a href='http://www.nrcc.cornell.edu/'>
            <img
              src={process.env.PUBLIC_URL + '/assets/Logos/logo_acis.png'}
              alt='Powered by ACIS'
              style={{ width: '144px' }}
            ></img>
          </a>
        </main>
      );
    } catch (e) {
      console.log(e);
      return (
        <main>
          <div
            style={{
              maxWidth: '700px',
              minWidth: '500px',
              margin: '0 auto',
              border: '1px solid rgb(128,128,128)',
            }}
          >
            There was an error getting your data. Please try again later.
          </div>
        </main>
      );
    }
  } else {
    return (
      <main>
        <div
          style={{
            maxWidth: '700px',
            minWidth: '500px',
            margin: '0 auto',
            border: '1px solid rgb(128,128,128)',
          }}
        >
          NO OPTIONS WERE PROVIDED
        </div>
      </main>
    );
  }
}

XPrecipHtml.propTypes = {
  xprecip: PropTypes.object,
  meta: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};
