import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles, styled } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import { fetchStations } from '../HelperScripts/getData';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#333333',
    fontWeight: '700',
    fontSize: 16,
    border: '1px solid rgb(150,150,150)',
    color: theme.palette.common.white,
    padding: '12px 6px',
    textAlign: 'center',
    lineHeight:'20px',
    boxSizing: 'border-box',
    fontFamily: '"Times New Roman", "sans-serif"'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    border: '2px solid rgb(128,128,128)',
    color: theme.palette.common.black,
    padding: '6px',
    textAlign: 'center',
    lineHeight:'16px',
    boxSizing: 'border-box',
    fontFamily: '"Times New Roman", "sans-serif"'
  },
}));

// Create style classes for entire component
const useStyles = makeStyles(() => ({
  heading: {
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: '700',
    marginBottom: '20px'
  },
  contentCont: {
    maxWidth: '700px',
    minWidth: '500px',
    margin: '0 auto',
    border: '1px solid rgb(128,128,128)',
    overflowX: 'hidden !important'
  },
}));



export default function PdsByStation({ options, STATE_CODES, setOptions, setMeta }) {
  const [stations, setStations] = useState(null);
  
  const classes = useStyles();
  
  useEffect(() => {
    let period = options['Duration'].includes('day') ? 'daily' : 'hourly';

    fetchStations(period)
      .then(results => {
        let optStCode = Object.keys(STATE_CODES).filter(code => STATE_CODES[code][1] === options['State'])[0];

        let filteredStations = results.filter(station => {
          return (
            (optStCode === station.id.slice(0,2) ||
            options['Station ID'] === station.id ||
            (options['Name'] !== '' && options['Name'].toUpperCase() === station.name.toUpperCase()))
            &&
            station.name !== ''
            &&
            station.yor >= 0
          );
        });

        setStations(filteredStations);
      });
  }, []);

  const handleSelect = (stationObj) => {
    setOptions({
      ...options,
      'elev': stationObj.elev,
      'end': stationObj.end,
      'Station ID': stationObj.id,
      'lat': stationObj.lat,
      'lon': stationObj.lon,
      'Name': stationObj.name,
      'omega': stationObj.omega,
      'start': stationObj.start,
      'yor': stationObj.yor,
    });

    setMeta(prev => !prev);

    localStorage.setItem('trigger', 'true');
  };

  if (options && stations) {
    try {
      return (
        <main>
          <h1 className={classes.heading}>Select station to view Partial Duration Series</h1>

          <TableContainer className={classes.contentCont}>
            <Table size='small'>
              <TableHead>
                <StyledTableCell style={{width: '90px'}}></StyledTableCell>
                <StyledTableCell style={{width: '90px'}}>Station ID</StyledTableCell>
                <StyledTableCell>Station Name</StyledTableCell>
                <StyledTableCell style={{width: '126px'}}>Length of PDS</StyledTableCell>
              </TableHead>
              <TableBody>
                {
                  stations.map(station => {
                    return (
                      <TableRow key={station.id}>
                        <StyledTableCell><button onClick={() => handleSelect(station)}>View PDS</button></StyledTableCell>
                        <StyledTableCell style={{ fontWeight: '700' }}>#{station.id}</StyledTableCell>
                        <StyledTableCell style={{textAlign:'left'}}>{station.name},{STATE_CODES[station.id.slice(0,2)][0]}</StyledTableCell>
                        <StyledTableCell><span style={{ fontWeight: '700' }}>{station.yor}</span> years</StyledTableCell>
                      </TableRow>
                    );
                  })
                }
              </TableBody>
            </Table>
          </TableContainer>
        </main>
      );
    } catch (e) {
      console.log(e);
      return (
        <main className={classes.main}>
          <div className={classes.contentCont}>
            There was an error getting your data. Please try again later.
          </div>
        </main>
      ); 
    }
  } else {
    return (
      <main className={classes.main}>
        <div className={classes.contentCont}></div>
      </main>
    );
  }
}

PdsByStation.propTypes = {
  options: PropTypes.object.isRequired,
  STATE_CODES: PropTypes.object.isRequired,
  setOptions: PropTypes.func.isRequired,
  setMeta: PropTypes.func.isRequired
};