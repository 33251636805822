import React from 'react';

import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import SlideshowIcon from         '@mui/icons-material/Slideshow';
import DescriptionIcon from       '@mui/icons-material/Description';
import HandymanIcon from          '@mui/icons-material/Handyman';
import HelpIcon from              '@material-ui/icons/Help';

const iconCircleBackground = {
  backgroundColor: 'white',
  borderRadius: '50%',
  height: '48px',
  width: '48px',
  border: '1px solid #b7b7b7'
};

const iconStyle = {
  height: '40px',
  width: '40px',
  color: '#17177d',
  boxSizing: 'border-box',
  marginTop: '4px',
  marginLeft: '4px',
};

const iconStyleHelp = {
  height: '48px',
  width: '48px',
  color: '#17177d',
  boxSizing: 'border-box',
};

export const categories = [
  {
    name: 'Help & Manuals',
    icon: <div style={iconCircleBackground}>
      <HelpIcon style={iconStyleHelp} />
    </div>,
    files: [
      {
        name: 'Users Manual',
        description: 'Need help with the web site? Get help here',
        href: '/user_manual/About_this_Users_Manual',
        loc: 'endpoint'
      },
      {
        name: 'Technical Manual',
        description: 'Detailed background info and project methodology',
        href: '/docs/Technical_Manual.pdf',
        loc: 'local'
      }
    ]
  },
  {
    name: 'NRCS Tools',
    icon: <div style={iconCircleBackground}>
      <HandymanIcon style={iconStyle} />
    </div>,
    files: [
      {
        name: 'WinTR-20 Help',
        description: 'Link to NRCS Web Site',
        href: 'https://directives.sc.egov.usda.gov/OpenNonWebContent.aspx?content=18654.wba',
        loc: 'external'
      },
      {
        name: 'WinTR-55 Help',
        description: 'Link to NRCS Web Site',
        href: 'https://www.nrcs.usda.gov/Internet/FSE_DOCUMENTS/stelprdb1042897.pdf',
        loc: 'external'
      },
      {
        name: 'EFH-2 Help',
        description: 'Link to NRCS Web Site',
        href: 'https://www.nrcs.usda.gov/wps/portal/nrcs/detailfull/national/water/manage/?cid=stelprdb1042921',
        loc: 'external'
      }
    ]
  },
  {
    name: 'Outreach Documents',
    icon: <div style={iconCircleBackground}>
      <DescriptionIcon style={iconStyle} />
    </div>,
    files: [
      {
        name: 'About the NRCC',
        description: 'PDF File (0.1 MB)',
        href: '/docs/About_the_NRCC.pdf',
        loc: 'local'
      },
      {
        name: 'EHF-2 Info Sheet',
        description: 'PDF File (0.1 MB)',
        href: '/docs/EHF_2_Info_Sheet.pdf',
        loc: 'local'
      },
      {
        name: 'WinTR-55 Info Sheet',
        description: 'PDF File (0.1 MB)',
        href: '/docs/WinTR55_Info_Sheet.pdf',
        loc: 'local'
      },
      {
        name: 'WinTR-20 Info Sheet',
        description: 'PDF File (0.1 MB)',
        href: '/docs/WinTR20_Info_Sheet.pdf',
        loc: 'local'
      },
      {
        name: 'GIS Shapefiles',
        description: 'ZIP File (5.1 MB)',
        href: '/docs/NE_states_counties_WGS72.zip',
        loc: 'download'
      }
    ]
  },
  {
    name: 'Outreach Presentations',
    icon: <div style={iconCircleBackground}>
      <SlideshowIcon style={iconStyle} />
    </div>,
    files: [
      {
        name: 'Background & Methodology',
        description: 'PowerPoint Presentation (11.0 MB)',
        href: '/docs/NRCC_0_Outreach.ppt',
        loc: 'download'
      },
      {
        name: 'Differences: NRCC vs. TP-40',
        description: 'PowerPoint Presentation (7.3 MB)',
        href: '/docs/NRCS_1_TP40Differences.ppt',
        loc: 'download'
      },
      {
        name: 'Rainfall Distribution Curves',
        description: 'PowerPoint Presentation (1.3 MB)',
        href: '/docs/NRCS_2_Distributions.ppt',
        loc: 'download'
      },
      {
        name: 'Preparing GIS Files',
        description: 'PowerPoint Presentation (6.3 MB)',
        href: '/docs/NRCS_3_PrepareGIS.ppt',
        loc: 'download'
      },
      {
        name: 'EFH-2 Software: Overview',
        description: 'PowerPoint Presentation (0.3 MB)',
        href: '/docs/NRCS_4_EFH2_Overview.ppt',
        loc: 'download'
      },
      {
        name: 'EFH-2 Software: How to Use',
        description: 'PowerPoint Presentation (6.1 MB)',
        href: '/docs/NRCS_5_EFH2_HowToUse.ppt',
        loc: 'download'
      },
      {
        name: 'WinTR-55 Software: Overview',
        description: 'PowerPoint Presentation (4.7 MB)',
        href: '/docs/NRCS_6_WinTR55_Overview.ppt',
        loc: 'download'
      },
      {
        name: 'WinTR-55 Software: How to Use',
        description: 'PowerPoint Presentation (9.5 MB)',
        href: '/docs/NRCS_7_WinTR55_HowToUse.ppt',
        loc: 'download'
      },
      {
        name: 'WinTR-20 Software: Overview',
        description: 'PowerPoint Presentation (5.6 MB)',
        href: '/docs/NRCS_8_WinTR20_Overview.ppt',
        loc: 'download'
      },
      {
        name: 'WinTR-20 Software: How to Use',
        description: 'PowerPoint Presentation (7.1 MB)',
        href: '/docs/NRCS_9_WinTR20_HowToUse.ppt',
        loc: 'download'
      },
    ]
  },
  {
    name: 'Related Studies',
    icon: <div style={iconCircleBackground}>
      <AutoAwesomeMotionIcon style={iconStyle} />
    </div>,
    files: [
      {
        name: 'Portland, ME Radar Climatology',
        description: 'PDF File (0.7 MB)',
        href: '/docs/Portland_ME_Rainfall_Climatology.pdf',
        loc: 'local'
      },
      {
        name: 'Portland, ME Radar Climatology',
        description: 'Link to Data',
        href: 'https://figshare.com/articles/dataset/Average_rainfall_rates_from_KGYX_radar_over_Maine/4210299',
        loc: 'external'
      },
      {
        name: 'Maine Intensity-Duration-Frequencies',
        description: 'PDF File (4.1 MB)',
        href: '/docs/Maine_IDF_User_Manual_2017.pdf',
        loc: 'local'
      },
      {
        name: 'Maine IDF Summary',
        description: 'PDF File (2.1 MB)',
        href: '/docs/Presentation_Summary_IDF_Development_2017.pdf',
        loc: 'local'
      },
      {
        name: 'Portland, ME Urban Flood Analysis',
        description: 'PDF File (3.3 MB)',
        href: '/docs/Urban Flooding Analysis_radar-rainfall and 2-D hydrodynamic model_Portland ME 1-3-2017.pdf',
        loc: 'local'
      },
      {
        name: 'Portland, ME Urban Flood Summary',
        description: 'PowerPoint Presentation (39.1 MB)',
        href: '/docs/Presentation_Summary_Urban_Flooding_Analysis_2017.pptx',
        loc: 'download'
      },
    ]
  }
];