import React from 'react';
import PropTypes from 'prop-types';
import { getDaysInMonth, parseISO, getDay, isPast, isToday, formatISO } from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
import { v4 as uuidv4 } from 'uuid';

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const DAYS = [
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
];

// Create style classes for entire component
const useStyles = makeStyles(() => ({
  calendar: {
    display: 'flex',
    flexWrap: 'wrap' ,
    gap: '10px',
    margin: '50px auto 0 auto',
    width: '581px',
  },
  monthCont: {
    width: '187px',
  },
  monthName: {
    height: '20px',
    backgroundColor: 'rgb(51,0,0)',
    color: 'rgb(255,255,102)',
    fontFamily: '"Trebuchet MS","sans-serif"',
    fontSize: '12px',
    fontWeight: '700',
    textAlign: 'center',
    boxSizing: 'border-box',
    paddingTop: '4px'
  },
  dayNames: {
    display: 'flex',
    height: '20px',
    fontFamily: '"Trebuchet MS","sans-serif"',
    fontSize: '11px',
    textAlign: 'center',
    color: 'rgb(255,255,102)',
    gap: '2px',
    marginTop: '2px'
  },
  dayName: {
    width: '25px',
    backgroundColor: 'rgb(51,0,0)',
    paddingTop: '5px'
  },
  days: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '2px',
    marginTop: '2px'
  },
  day: {
    fontFamily: '"Arial","sans-serif"',
    fontSize: '8pt',
    height: '28px',
    width: '25px',
    boxSizing: 'border-box',
    border: '1px solid black',
    backgroundColor: 'white'
  },
  dayNum: {
    textAlign: 'right'
  },
  dayValue: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginTop: '4px'
  },
  placeholder: {
    border: 'none'
  },
  clickable: {
    color: 'rgb(0,0,255)',
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

export default function Calendar({ year, data, LEGEND_BINS, legendValues }) {
  const classes = useStyles();
  let dataArr = [ ...data.data ];
  
  const renderDays = (month) => {
    let numDays = getDaysInMonth(new Date(year, month));

    let dayDivs= [];
    let firstDay = getDay(parseISO(dataArr[0][0]));
    for (let i = 0; i < firstDay; i++) {
      dayDivs.push(<div key={uuidv4()} className={`${classes.day} ${classes.placeholder}`}></div>);
    }

    for (let i = 0; i < numDays; i++) {
      let thisDate = new Date(year, month, i + 1);

      const dayData = dataArr.shift();

      let value = dayData[1];
      let fVal = parseFloat(value);
      let bgColor = 'inherit';
      if (fVal === 0 || value === 'T' || !isPast(thisDate) || isToday(thisDate)) {
        value = <span>&nbsp;</span>;
        bgColor = 'white';
      } else if (fVal < 0 || value === 'M') {
        bgColor = '#bbbbbb';
      } else {
        let yr = Object.keys(legendValues).filter(y => fVal > parseFloat(legendValues[y].split('"')[0])).sort((a,b) => parseInt(b.split('y')[0]) - parseInt(a.split('y')[0]));

        if (yr.length > 0) {
          bgColor = LEGEND_BINS[yr[0]];

          dayDivs.push(
            <div key={uuidv4()} className={classes.day} style={{backgroundColor: bgColor}}>
              <div className={classes.dayNum}>{i + 1}</div>
              <div
                className={`${classes.dayValue} ${classes.clickable}`}
                onClick={() => {
                  alert(`Station exceeded 1-day ${yr[0].split('y')[0]}-yr storm on ${formatISO(thisDate, { representation: 'date' })}\nActual amount ${value} inches\n${yr[0].split('y')[0]}-yr storm amount ${legendValues[yr[0]].split('"')[0]} inches`);
                }}
              >
                {value}
              </div>
            </div>
          );
  
          continue;
        } else {
          bgColor = 'white';
        }
      }

      dayDivs.push(
        <div key={uuidv4()} className={classes.day} style={{backgroundColor: bgColor}}>
          <div className={classes.dayNum}>{i + 1}</div>
          <div className={classes.dayValue}>{value}</div>
        </div>
      );
    }

    return dayDivs;
  };


  return (
    <div className={classes.calendar}>
      {
        MONTHS.map((month, i) => {
          return (
            <div key={month} className={classes.monthCont}>
              <div className={classes.monthName}>{month}</div>
              
              <div className={classes.dayNames}>
                {
                  DAYS.map(day => <div key={uuidv4()} className={classes.dayName}>{day}</div>)
                }
              </div>

              <div className={classes.days}>
                {renderDays(i)}
              </div>
            </div>
          );
        })
      }
    </div>
  );
}

Calendar.propTypes = {
  year: PropTypes.string,
  data: PropTypes.object,
  LEGEND_BINS: PropTypes.object,
  legendValues: PropTypes.object
};