import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  List,
  ListItem,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { makeStyles, styled } from '@material-ui/core/styles';

import exporting from 'highcharts/modules/exporting';
import Highcharts from 'highcharts';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more'; 
import HC_offline_exporting from 'highcharts/modules/offline-exporting';
highchartsMore(Highcharts); 
exporting(Highcharts);
HC_offline_exporting(Highcharts);
NoDataToDisplay(Highcharts);

import { round3Digits, round2Digits } from '../HelperScripts/rounding';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 12,
    padding: '0px',
    lineHeight: '12px',
    boxSizing: 'border-box',
    fontFamily: '"monospace", "sans-serif"',
    borderBottom: '3px double black',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    border: 'none',
    color: theme.palette.common.black,
    padding: '2px',
    lineHeight:'12px',
    boxSizing: 'border-box',
    fontFamily: '"monospace", "sans-serif"',
  },
}));

// Create style classes for entire component
const useStyles = makeStyles(() => ({
  contentCont: {
    width: 'fit-content !important',
    marginLeft: '80px'
  },
  li: {
    padding: '2px 0px !important'
  },
  ul: {
    padding: '6px 0px !important'
  }, 
  tableFootnote: {
    fontFamily: 'monospace',
    marginLeft: '75px'
  }
}));

const interpolate = (start, end = 999, num = 0) => {
  num = num + 1;
  let output = [start];

  let step = (end - start) / num;

  for (let i = 1; i < num; i++) {
    output.push(start + (i * step));
  }

  return output;
};

const interpolateData = (data, durRange, cls) => {
  let lowers = [];
  let values = [];
  let uppers = [];
  let x;
  let numArr;
  if (durRange.includes('min')) {
    x = Array.from({length: 116}, (_, i) => i + 5);
    numArr = [4,4,14,29,59,0];
  } else if (durRange.includes('hr')) {
    x = Array.from({length: 48}, (_, i) => i + 1);
    numArr = [0,0,2,5,11,23,0];
  } else {
    x = Array.from({length: 10}, (_, i) => i + 1);
    numArr = [0,1,2,2,0];
  }

  
  numArr.forEach((num,i) => {
    if (num === 0) {
      values.push(data[i][0]);

      if (cls) {
        lowers.push(data[i][1]);
        uppers.push(data[i][2]);
      }
    } else {
      values = values.concat(interpolate(data[i][0], data[i + 1][0], num));

      if (cls) {
        lowers = lowers.concat(interpolate(data[i][1], data[i + 1][1], num));
        uppers = uppers.concat(interpolate(data[i][2], data[i + 1][2], num));
      }
    }
  });

  return {
    newValues: values,
    lowers,
    uppers,
    x
  };
};



export default function PrecipFreqDurGraphs({ options, graphData }) {
  const [values, setValues] = useState([]);
  const [ciValues, setCiValues] = useState([]);
  const [yLims, setYLims] = useState([0,999]);
  
  const classes = useStyles();

  useEffect(() => {
    if (graphData.length > 0 && Object.keys(options).length > 0) {
      let {newValues, lowers, uppers, x} = interpolateData(graphData, options['Duration Range'], options['Confidence Limits'] === 'Show');

      let yMin = 999;
      let yMax = 0;
      x.forEach((num, i) => {
        let val = newValues[i];
        
        newValues[i] = [num, val];

        if (val > yMax) {
          yMax = val;
        }

        if (val < yMin) {
          yMin = val;
        }
        
        if (options['Confidence Limits'] === 'Show') {
          let low = lowers[i];
          let up = uppers[i];

          lowers[i] = low;
          uppers[i] = up;

          if (low < yMin) {
            yMin = low;
          }

          if (up > yMax) {
            yMax = up;
          }
        }
      });
      
      setYLims([yMin * 0.5, yMax * 1.13]);
      setCiValues(lowers.map((val,i) => [options['Duration Range'].includes('min') ? i + 5 : i + 1, val, uppers[i]]));
      setValues(newValues);
    }
  }, [graphData]);
  
  const renderChart = () => {
    let chartTitle = `Precipitation Frequency Duration - ${options['Recurrence'].split('(')[0]}`;
    let subtitle = `(${round3Digits(options.lat)}N, ${round3Digits(options.lon)}W)`;
    
    let xTicks, xMax, xUnit;
    if (options['Duration Range'].includes('min')) {
      xUnit = 'minutes';
      xMax = 125;
      xTicks = [5,10,15,30,60,120];
    } else if (options['Duration Range'].includes('hr')) {
      xUnit = 'hours';
      xMax = 50;
      xTicks = [1,2,3,6,12,24,48];
    } else {
      xUnit = 'days';
      xMax = 11;
      xTicks = [1,2,4,7,10];
    }
    
    let seriesInfo = [
      {
        data: values,
        name: 'Precipitation Estimates',
        color: 'blue',
        marker: {
          enabled: false
        },
        zIndex: 2
      }
    ];
    if (options['Confidence Limits'] === 'Show') {
      seriesInfo.push({
        name: 'Confidence Interval Limits',
        type: 'arearange',
        fillColor: 'rgba(255,0,0,0)',
        color: 'red',
        data: ciValues,
        marker: {
          enabled: false
        },
        zIndex: 1
      });
    }


    let chartOptions =  {
      exporting:{
        fallbackToExportServer: false,
        enabled: true,
        buttons: {
          contextButton: {
            menuItems: ['viewFullscreen', 'printChart', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadSVG']
          }
        }
      },

      credits: {enabled: false},

      chart: {
        height: 600,
        width: 600,
        backgroundColor: 'rgb(255,255,255)',
        plotBorderWidth: 1,
        plotBorderColor: 'black',
        events: {
          load: function() {
            this.renderer
              .image((process.env.PUBLIC_URL + '/assets/Logos/logo_acis.png'),485,482,90,40)
              .on('click', function() {
                location.href = 'http://www.rcc-acis.org';
              })
              .attr({zIndex: 3})
              .css({cursor: 'pointer'})
              .add();
          }
        }
      },

      plotOptions: {
        line: {
          lineWidth: 2
        },
        series: {
          states: {
            inactive: {
              opacity: 1
            }
          },
        }
      },

      legend: {
        align: 'right',
        verticalAlign: 'top',
        layout: 'vertical',
        backgroundColor: 'white',
        floating: true,
        x: 2,
        y: 54,
        borderColor: 'black',
        borderWidth: 1,
        padding: 10
      },

      title: {
        text: chartTitle,
        x: 40
      },

      subtitle: {
        text: subtitle,
        style: {
          color: '#333333',
          fontSize: '18px'
        },
        x: 40
      },

      series: seriesInfo,

      xAxis: {
        title: {
          text: `Duration (${xUnit})`
        },
        min: 0,
        max: xMax,
        tickInterval: 1,
        gridLineDashStyle: 'ShortDot',
        gridLineColor: 'rgb(80,80,80)',
        gridLineWidth: 1,
        labels: {
          step: 1,
          style: {
            color: 'black'
          }
        },
        lineColor: 'black',
        tickColor: 'black',
        tickPositions: xTicks
      },

      yAxis: {
        title: {
          text: 'Precipitation (inches)'
        },
        startOnTick: false,
        endOnTick: false,
        min: yLims[0],
        max: yLims[1],
        tickWidth: 1,
        tickColor: 'black',
        gridLineDashStyle: 'ShortDot',
        gridLineColor: 'rgb(80,80,80)',
        gridLineWidth: 1,
        labels: {
          step: 1,
          style: {
            color: 'black'
          },
          rotation: -90
        },
        lineColor: 'black',
      },

      tooltip: {
        formatter: function () {
          return this.points.reduce(function (acc, point) {
            if (point.color === 'blue') {
              return `<b>${point.x} ${xUnit}</b><hr/><b>${point.series.name}: </b>${round2Digits(point.y)} inches<br/>${acc}`;
            } else {
              return `${acc}<b>${point.series.name}: </b>${round2Digits(point.point.options.low)}-${round2Digits(point.point.options.high)} inches`;
            }
          }, '');
        },
        backgroundColor: '#FFFFFF',
        shared: true,
        useHTML: true,
        outside: true
      }
    };

    return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
  };

  const renderTable = () => {
    let nonCalcPts = [1,2,3,6,12,24,48];
    if (options['Intensity Units'] === 'inches/minute') {
      nonCalcPts = [1,2,3,6,12,24,48];
    } else if (options['Intensity Units'] === 'inches/day') {
      nonCalcPts = [1,2,3,6,12,24,48];
    }

    let headers = ['Time (hours)', 'Estimate (inches)'];

    if (options['Confidence Limits'] === 'Show') {
      headers.push('Lower Limit (inches)');
      headers.push('Upper Limit (inches)');
    }
    
    return (
      <TableContainer className={classes.contentCont}>
        <Table size='small'>
          <TableHead>
            {
              headers.map((colHead, index) => {
                let width = '100px';
                
                if (index === headers.length - 1) {
                  width = 'fit-content';
                }
                
                if (index === headers.length - 1) {
                  width = '165px';
                }

                return <StyledTableCell key={colHead} style={{ width: width }}>{
                  <List className={classes.ul}>
                    <ListItem className={classes.li}>{colHead.split('(')[0]}</ListItem>
                    <ListItem className={classes.li}>({colHead.split('(')[1]}</ListItem>
                  </List>  
                }</StyledTableCell>;
              })
            }
          </TableHead>
          <TableBody>
            {
              values.map((data, i) => {

                return (
                  <TableRow key={data[0]}>
                    <StyledTableCell>{data[0]}{!nonCalcPts.includes(data[0]) ? '*' : ''}</StyledTableCell>
                    <StyledTableCell>{round2Digits(data[1])}</StyledTableCell>
                    {
                      options['Confidence Limits'] === 'Show' && <>
                        <StyledTableCell>{round2Digits(ciValues[i][1])}</StyledTableCell>
                        <StyledTableCell>{round2Digits(ciValues[i][2])}</StyledTableCell>
                      </>
                    }
                  </TableRow>
                );
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  
  return (
    <main>
      <div>
        {graphData.length > 0 && renderChart()}
      </div>

      <div>
        {graphData.length > 0 && renderTable()}
        <div className={classes.tableFootnote}>*values for noted rows are calculated estimates</div>
      </div>
    </main>
  );
}

PrecipFreqDurGraphs.propTypes = {
  options: PropTypes.object,
  graphData: PropTypes.array
};