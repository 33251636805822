export const leftFooterLogos = [
  {
    url: 'http://www.nrcc.cornell.edu',
    src: 'logo_nrcc.png'
  },
  {
    url: 'http://www.rcc-acis.org',
    src: 'logo_acis.png'
  },
  {
    url: 'http://www.ncdc.noaa.gov',
    src: 'logo_ncdc.png'
  },
  {
    url: 'http://www.ncdc.noaa.gov/oa/climate/regionalclimatecenters.html',
    src: 'logo_rcc.png'
  },
  {
    url: 'http://www.cornell.edu/',
    src: 'logo_cornell.png'
  }
];

export const rightFooterLogos = [
  {
    url: 'http://www.usda.gov/',
    src: 'logo_usda.png'
  },
  {
    url: 'http://www.nrcs.usda.gov/',
    src: 'logo_nrcs.png'
  },
  {
    url: 'http://www.ny.nrcs.usda.gov/',
    src: 'logo_ny.png'
  },
  {
    url: 'http://www.ct.nrcs.usda.gov/',
    src: 'logo_ct.png'
  },
  {
    url: 'http://www.ri.nrcs.usda.gov/',
    src: 'logo_ri.png'
  },
  {
    url: 'http://www.ma.nrcs.usda.gov/',
    src: 'logo_ma.png'
  },
  {
    url: 'http://www.nh.nrcs.usda.gov/',
    src: 'logo_nh.png'
  },
  {
    url: 'http://www.vt.nrcs.usda.gov/',
    src: 'logo_vt.png'
  },
  {
    url: 'http://www.me.nrcs.usda.gov/',
    src: 'logo_me.png'
  },
];