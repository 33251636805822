/* eslint-disable quotes */
import React from 'react';
// import { generateManualImage, generateManualParagraph, generateManualLists } from '../HelperScripts/generateManualContent';

import AboutManual from '../Components/ManualContent/AboutManual';



export const manualNav = [
  {
    title: false,
    subtitle: false,
    name: 'About this Users Manual',
    contentTitle: 'About this Users Manual',
    content: <AboutManual />
  },
  {
    title: true,
    subtitle: false,
    name: 'Interface',
    contentTitle: 'Interface',
    content: []
  },
  {
    title: false,
    subtitle: false,
    name: 'Product',
    contentTitle: 'Select Product',
    content: [
      {
        type: 'p',
        text: 'The left side menu on the Data & Products page allows you to select your desired product or output format. Each product has customizable location and options. Most information about the available products and outputs can be found at the respective help pages.'
      },
      {
        type: 'li',
        lists: {
          'seeAlso': [
            'Extreme Precipitation Tables - HTML',
            'Extreme Precipitation Tables - Text/CSV',
            'Partial Duration Series - by Point',
            'Partial Duration Series - by Station',
            'Distribution Curves - Graphical',
            'Distribution Curves - Text/TBL',
            'Intensity Frequency Duration Graphs',
            'Precipitation Frequency Duration Graphs',
            'GIS Data Files',
            'Regional/State Maps',
          ]
        }
      }
    ]
  },
  {
    title: false,
    subtitle: false,
    name: 'Location',
    contentTitle: 'Select Location',
    content: [
      {
        type: 'p',
        text: 'Most products require the selection of a specific location in the study area to view point data. Several options are available to select a location – the easiest of which is to utilize the built-in MapBox interface. To select a point, simply double-click – a red marker will appear and the web site will register the location. Panning and zooming can be done with the mouse or the controls at the bottom-right corner of the map.'
      },
      {
        type: 'img',
        fileName: 'maptype.png',
        heading: 'MapBox Style Control',
        width: '100px'
      },
      {
        type: 'p',
        text: 'Additional views, including Map, Satellite, Terrain, and Hybrid (default), are available through the buttons in the top-left corner of the map interface.'
      },
      {
        type: 'img',
        fileName: 'hybrid.png',
        heading: 'Hybrid map view (default)'
      },
      {
        type: 'img',
        fileName: 'map.png',
        heading: 'Map map view'
      },
      {
        type: 'img',
        fileName: 'satellite.png',
        heading: 'Satellite map view'
      },
      {
        type: 'img',
        fileName: 'terrain.png',
        heading: 'Terrain map view'
      },
      {
        type: 'p',
        text: 'To clear the point that has been selected, click the “arrow” button next to a blank field (address, lat/lon).'
      },
      {
        type: 'p',
        text: 'For details on selecting a location by a street address, latitude/longitude coordinates, or the center of a specific state or county, please reference the relevant help file.'
      },
      {
        type: 'li',
        lists: {
          'seeAlso': [
            'Locate by Address',
            'Locate by Lat/Lon',
            'Locate by State/County',
            'MapBox Documentation'
          ]
        }
      }
    ]
  },
  {
    title: false,
    subtitle: false,
    name: 'Data Type',
    contentTitle: 'Select Data Type',
    content: [
      {
        type: 'p',
        text: 'Used only on the GIS Data Files product, this dialog allows users to display gridded files containing the Return Period Estimates (the "values"), or either of the Confidence Limits (Upper or Lower).'
      },
      {
        type: 'p',
        text: 'Unless you have a specific need for using the upper or lower bounds, it is highly recommended that the Return Period Estimates be used for design and research work.'
      },
      {
        type: 'li',
        lists: {
          'options': [
            'Return Period Estimates',
            'Upper Confidence Limits',
            'Lower Confidence Limits'
          ],
          'products': ['GIS Data Files'],
        }
      }
    ]
  },
  {
    title: false,
    subtitle: false,
    name: 'Search',
    contentTitle: 'Select Search Parameter(s)',
    content: [
      {
        type: 'p',
        text: 'Used only in the Partial Duration Series (by Station) product, this tool allows users to search for a specific station. The search parameters available are full or partial station ID number (6-digit COOP), station name (case insensitive), and state.'
      },
      {
        type: 'p',
        text: "The search parameters use an 'AND' boolean, so entering search terms in more than one box would enforce all of them in the final result set."
      },
      {
        type: 'p',
        text: 'Be aware that many station names are abbreviated throughout the database. In addition, if you attempt to return a large number of station results using this product (for instance, all stations in New York), the web server will likely time out and you will receive a blank result page. If this happens to you, please make your query more specific and try again. If you have a specific need for a mass download of all partial duration series, please contact us.'
      },
      {
        type: 'li',
        lists: {
          'products': ['Partial Duration Series - by Station'],
          'seeAlso': [
            'Search by State',
            'Search by Station ID',
            'Search by Name'
          ]
        }
      }
    ]
  },
  {
    title: false,
    subtitle: false,
    name: 'Region/State',
    contentTitle: 'Select Region/State',
    content: [
      {
        type: 'p',
        text: 'Used only for the Maps product, this location selector allows the user to select any of the states in the product or the entire region (Northeast US) to be displayed in a pre-processed map form. Please note that some maps may have a "buffer" built in, where the contour colors bleed slightly beyond the state or regional borders - this is normal.'
      },
      {
        type: 'li',
        lists: {
          'options': [
            'Northeast US',
            'Connecticut',
            'Maine',
            'Massachusetts',
            'New Hampshire',
            'New York',
            'Rhode Island',
            'Vermont',
          ],
          'products': ['Regional/State Maps'],
        }
      }
    ]
  },
  {
    title: false,
    subtitle: false,
    name: 'Options',
    contentTitle: 'Select Options',
    content: [
      {
        type: 'p',
        text: 'Each available product has a set of options to customize the output display. All option dropdown boxes must have a value (either the default or user-selected) to activate the submit button.'
      },
      {
        type: 'p',
        text: 'For more information about a specific option, see the respective help page.'
      },
      {
        type: 'li',
        lists: {
          'seeAlso': [
            'Smoothing',
            'Delivery',
            'Duration',
            'Recurrence',
            'Compare to',
            'Duration Range',
            'Intensity Units',
            'Confidence Limits',
          ]
        }
      }
    ]
  },
  {
    title: false,
    subtitle: false,
    name: 'Submit',
    contentTitle: 'Submit',
    content: [
      {
        type: 'p',
        text: 'When you are ready to retrieve the requested data from the server, click the submit button. This will cause either a popup window, new tab, or browser download window to appear (depending on the product and delivery options).'
      },
      {
        type: 'p',
        text: 'The submit button dynamically changes colors to indicate if all required information has been input. When the submit button is red, you will not be able to submit the product for processing. When it turns green, all necessary information has been entered (or defaults will be used) and the user can retrieve their desired results.'
      },
      {
        type: 'img',
        heading: 'Red Submit Button - Disabled',
        fileName: 'submit_red.png'
      },
      {
        type: 'img',
        heading: 'Green Submit Button - Good to go!',
        fileName: 'submit_green.png'
      },
      {
        type: 'li',
        lists: {
          'seeAlso': [
            'Select Location',
            'Select Search Parameter(s)',
            'Select Data Type',
            'Select Region/State',
            'Select Options',
          ]
        }
      }
    ]
  },
  {
    title: true,
    subtitle: '(for map products only)',
    name: 'Location',
    contentTitle: 'Select Location',
    content: [
      {
        type: 'p',
        text: 'Most products require the selection of a specific location in the study area to view point data. Several options are available to select a location – the easiest of which is to utilize the built-in MapBox interface. To select a point, simply double-click – a red marker will appear and the web site will register the location. Panning and zooming can be done with the mouse or the controls at the bottom-right corner of the map.'
      },
      {
        type: 'img',
        fileName: 'maptype.png',
        heading: 'MapBox Style Control',
        width: '100px'
      },
      {
        type: 'p',
        text: 'Additional views, including Map, Satellite, Terrain, and Hybrid (default), are available through the buttons in the top-left corner of the map interface.'
      },
      {
        type: 'img',
        fileName: 'hybrid.png',
        heading: 'Hybrid map view (default)'
      },
      {
        type: 'img',
        fileName: 'map.png',
        heading: 'Map map view'
      },
      {
        type: 'img',
        fileName: 'satellite.png',
        heading: 'Satellite map view'
      },
      {
        type: 'img',
        fileName: 'terrain.png',
        heading: 'Terrain map view'
      },
      {
        type: 'p',
        text: 'To clear the point that has been selected, click the “arrow” button next to a blank field (address, lat/lon).'
      },
      {
        type: 'p',
        text: 'For details on selecting a location by a street address, latitude/longitude coordinates, or the center of a specific state or county, please reference the relevant help file.'
      },
      {
        type: 'li',
        lists: {
          'seeAlso': [
            'Locate by Address',
            'Locate by Lat/Lon',
            'Locate by State/County',
            'MapBox Documentation'
          ]
        }
      }
    ]
  },
  {
    title: false,
    subtitle: false,
    name: 'Address',
    contentTitle: 'Locate by Address',
    content: [
      {
        type: 'p',
        text: 'This method of location searching is best for users looking for point extreme precipitation data for a specific street address, city/state, zip code. Enter any address in the “Locate by Address” search box and either press Enter or click the right arrow button to search and select the best matching point.'
      },
      {
        type: 'img',
        heading: 'Search Interface',
        fileName: 'address.png',
        width: '200px'
      },
      {
        type: 'p',
        text: 'The project makes use of the MapBox API to geocode any well-formed address to usable latitude/longitude coordinates. Any combination of street address, city, state, zip code, business name, etc. can be entered in the search box (each element should be separated by a comma).'
      },
      {
        type: 'li',
        lists: { 'addresses': true }
      },
      {
        type: 'p',
        text: 'This system does have its flaws, as an address search may return multiple or incorrect locations. If you experience trouble finding a given address, it is recommended that you use Google Maps to locate it. You can then determine a more specific address, or double-click the map on the extreme precipitation web site to select the desired point.'
      },
      {
        type: 'p',
        text: 'Data is only available for the seven states in the study – Connecticut, Maine, Massachusetts, New Hampshire, New York, Rhode Island, and Vermont. Selecting a point elsewhere will result in very odd results or an error message. In addition, the resolution of the project dataset is approximately 0.5km, so a selected point will be matched with the closest grid point to pull the output data.'
      },
      {
        type: 'li',
        lists: {
          'seeAlso': [
            'Select Location',
            'Locate by Lat/Lon',
            'Locate by State/County',
            'Google Maps'
          ]
        }
      }
    ]
  },
  {
    title: false,
    subtitle: false,
    name: 'Latitude/Longitude',
    contentTitle: 'Locate by Lat/Lon',
    content: [
      {
        type: 'p',
        text: 'To search for a specific location using the latitude and longitude coordinates, enter them in the input boxes and click the right arrow button to select the desired point.'
      },
      {
        type: 'img',
        heading: 'Search Interface',
        fileName: 'latlon.png',
        width: '200px'
      },
      {
        type: 'p',
        text: <span>This search method only allows coordinates in decimal format. To convert from degree-minute-second (DMS) format, use the handy tool at <a href='https://www.fcc.gov/media/radio/dms-decimal' target='_blank' rel='noreferrer' className='link-inline'>https://www.fcc.gov/media/radio/dms-decimal</a></span>
      },
      {
        type: 'p',
        text: 'Negative signs are ignored, as these fields assume North (positive) latitude and West (negative) longitude. Data is only available for the seven states in the study – Connecticut, Maine, Massachusetts, New Hampshire, New York, Rhode Island, and Vermont. Selecting a point elsewhere will result in very odd results or an error message. In addition, the resolution of the project dataset is approximately 0.5km, so a selected point will be matched with the closest grid point to pull the output data.'
      },
      {
        type: 'li',
        lists: {
          'seeAlso': [
            'Select Location',
            'Locate by Address',
            'Locate by State/County',
            'Latitude-Longitude Conversion Tool'
          ]
        }
      }
    ]
  },
  {
    title: false,
    subtitle: false,
    name: 'State/County',
    contentTitle: 'Locate by State/County',
    content: [
      {
        type: 'p',
        text: 'For users that only have a vague location, we have built-in a tool to automatically select the approximate geographic center of each state and county in the project. Please note, the point selected using this method is not necessarily a representative or average point for the entire state or county – it is simply the geocoded geographic center according to the MapBox API.'
      },
      {
        type: 'p',
        text: 'To utilize this search method, select the desired state or county in the dropdown box and the center will automatically be selected.'
      },
      {
        type: 'img',
        heading: 'Search Interface',
        fileName: 'statecounty.png'
      },
      {
        type: 'p',
        text: 'Data is only available for the 129 counties in the seven states in the study – Connecticut, Maine, Massachusetts, New Hampshire, New York, Rhode Island, and Vermont. The resolution of the project dataset is approximately 0.5km, so a selected point will be matched with the closest grid point to pull the output data.'
      },
      {
        type: 'li',
        lists: {
          'seeAlso': [
            'Select Location',
            'Locate by Address',
            'Locate by Lat/Lon'
          ]
        }
      }
    ]
  },
  {
    title: true,
    subtitle: false,
    name: 'Products',
    contentTitle: 'About this Users Manual',
    content: <AboutManual />
  },
  {
    title: false,
    subtitle: false,
    name: 'Table - HTML',
    contentTitle: 'Extreme Precipitation Tables - HTML',
    content: [
      {
        type: 'p',
        text: 'The Extreme Precipitation Tables product presents an easy way to extract data from the project database for a given point. Output will include all return period estimates and confidence limits (upper and lower bounds) for all durations and recurrences.'
      },
      {
        type: 'p',
        text: 'The HTML product produces a series of easy-to-read tables compatible with any web browser. In addition, by setting Delivery to Download, the data can be saved locally for future reference (default file name is output.html).'
      },
      {
        type: 'img',
        heading: 'Sample output:',
        fileName: 'html.png'
      },
      {
        type: 'li',
        lists: {
          'linkedOptions': ['Smoothing', 'Delivery'],
          'seeAlso': ['Extreme Precipitation Tables - Text/CSV']
        }
      }
    ]
  },
  {
    title: false,
    subtitle: false,
    name: 'Table - Text/CSV',
    contentTitle: 'Extreme Precipitation Tables - Text/CSV',
    content: [
      {
        type: 'p',
        text: 'The Extreme Precipitation Tables product presents an easy way to extract data from the project database for a given point. Output will include return period estimates and confidence limits (upper and lower bounds) for selected durations and all recurrences.'
      },
      {
        type: 'p',
        text: 'The Text/CSV product produces a special data file, designed to be read by the WinTR-20 software package by NRCS. This product will automatically download a comma-separated text file (default file name is output.csv). This output is not designed for easy human viewing, but rather to import data into compatible software for analysis.'
      },
      {
        type: 'img',
        fileName: 'text.png',
        heading: 'Sample output:'
      },
      {
        type: 'li',
        lists: {
          'linkedOptions': ['Smoothing'],
          'seeAlso': ['Extreme Precipitation Tables - HTML']
        }
      }
    ]
  },
  {
    title: false,
    subtitle: false,
    name: 'Partial Duration Series (by Point)',
    contentTitle: 'Partial Duration Series - by Point',
    content: [
      {
        type: 'p',
        text: 'In computing the extreme precipitation statistics in this project, we have utilized a Partial Duration Series (PDS) to model the entire precipitation record. For n complete years in a station record, the n highest values comprise the PDS. This series is then pushed into the Beta-P statistical distribution to determine the return period storms for each recurrence.'
      },
      {
        type: 'p',
        text: 'This product will retrieve the Partial Duration Series for any station used in the final analysis. Please note, this product does not simulate or model point-specific data. When a location is selected, the nearest station is selected and displayed.'
      },
      {
        type: 'img',
        heading: 'Sample output:',
        fileName: 'pds.png'
      },
      {
        type: 'p',
        text: 'For more control over which PDS to view, use the Partial Duration Series – by Station product.'
      },
      {
        type: 'p',
        text: 'Only selected durations are available, as some duration periods (such as the subhourly times – 5min, 10min, etc.) are ratio-driven calculations based on other duration return periods.'
      },
      {
        type: 'li',
        lists: {
          'linkedOptions': [
            'Duration',
            'Delivery'
          ],
          'seeAlso': [
            'Partial Duration Series - by Station',
            'Technical Documentation'
          ]
        }
      }
    ]
  },
  {
    title: false,
    subtitle: false,
    name: 'Partial Duration Series (by Station)',
    contentTitle: 'Partial Duration Series - by Station',
    content: [
      {
        type: 'p',
        text: 'In computing the extreme precipitation statistics in this project, we have utilized a Partial Duration Series (PDS) to model the entire precipitation record. For n complete years in a station record, the n highest values comprise the PDS. This series is then pushed into the Beta-P statistical distribution to determine the return period storms for each recurrence.'
      },
      {
        type: 'p',
        text: 'This product will retrieve the Partial Duration Series for any station used in the final analysis. Please note, this product does not simulate or model point-specific data. Users are able to search for station by State, Station ID number (six-digit numeric cooperative ID only), or station name (case insensitive). After submitting, a new window will present a choice of station series. If multiple search parameters are entered, they are essentially processed with an “AND” boolean – all parameters must be present for a given station for it to show in results. Also, searches that return a large number of stations may time out a user’s web browser – at this point, the query can be tried again or made more specific to limit results.'
      },
      {
        type: 'img',
        heading: 'Sample search results:',
        fileName: 'pdsstn.png'
      },
      {
        type: 'img',
        heading: 'Sample output:',
        fileName: 'pds.png'
      },
      {
        type: 'p',
        text: 'To view the PDS closest to a given point, use the Partial Duration Series – by Point product.'
      },
      {
        type: 'p',
        text: 'Only selected durations are available, as some duration periods (such as the subhourly times – 5min, 10min, etc.) are ratio-driven calculations based on other duration return periods.'
      },
      {
        type: 'li',
        lists: {
          'linkedOptions': [
            'Search by State',
            'Search by Station ID',
            'Search by Name',
            'Duration'
          ],
          'seeAlso': [
            'Partial Duration Series - by Point',
            'Technical Documentation'
          ]
        }
      }
    ]
  },
  {
    title: false,
    subtitle: false,
    name: 'Distribution Curve (graphical)',
    contentTitle: 'Distribution Curves - Graphical',
    content: [
      {
        type: 'p',
        text: 'The output from the Distribution Curves product is a special graphic used by NRCS hydraulic engineers for time of concentration and other streamflow calculations. These graphics are comparable to the Type II, IIA, III, etc. distribution curves. (These type curves can be added to the output via the Compare to field.) This project, however, is able to dynamically calculate these curves for any point in the Northeast US, rather than taking broad national averages of precipitation distributions.'
      },
      {
        type: 'p',
        text: 'The precipitation distribution curves are calculated using the same algorithm as the NRCS WinTR-20 software package (with minor rounding differences due to difficulties in the original WinTR-20 programming language). This methodology assumes the maximum rainfall intensity (the amount of the 5min) storm to occur at exactly the midpoint of a 24-hour period – the 12hr mark. The curve is then successively constructed outward in both directions.'
      },
      {
        type: 'p',
        text: 'This product creates a PNG image of the curve. The x-axis represents duration (in hours), while the unitless y-axis essentially represents the cumulative percent of rain that has fallen into a watershed. In addition, values are displayed in a table below the graphic, at a resolution of 1/10-hour (six minutes). Smoothing is highly recommended for this product to round out corners from the graph, producing more realistic and reliable results.'
      },
      {
        type: 'img',
        heading: 'Sample output:',
        fileName: 'dist.png'
      },
      {
        type: 'p',
        text: 'For more details about how these curves are constructed and potential uses, view the WinTR-20 software documentation.'
      },
      {
        type: 'li',
        lists: {
          'linkedOptions': [
            'Recurrence',
            'Compare to',
            'Smoothing'
          ],
          'seeAlso': [
            'Distribution Curves - Text/TBL'
          ]
        }
      }
    ]
  },
  {
    title: false,
    subtitle: false,
    name: 'Distribution Curve (Text/TBL)',
    contentTitle: 'Distribution Curves - Text/TBL',
    content: [
      {
        type: 'p',
        text: 'The output from the Distribution Curves product is a special graphic used by NRCS hydraulic engineers for time of concentration and other streamflow calculations. These graphics are comparable to the Type II, IIA, III, etc. distribution curves. (These type curves can be added to the output via the Compare to field.) This project, however, is able to dynamically calculate these curves for any point in the Northeast US, rather than taking broad national averages of precipitation distributions.'
      },
      {
        type: 'p',
        text: 'The precipitation distribution curves are calculated using the same algorithm as the NRCS WinTR-20 software package (with minor rounding differences due to difficulties in the original WinTR-20 programming language). This methodology assumes the maximum rainfall intensity (the amount of the 5min) storm to occur at exactly the midpoint of a 24-hour period – the 12hr mark. The curve is then successively constructed outward in both directions.'
      },
      {
        type: 'p',
        text: 'This product generates a tab-delimited text file of the curve, at a resolution of 1/10-hour (six minutes). This file is specially formatted to match the output of WinTR-20. This allows for automatic input and analysis via the NRCS WinTR-55 software package. Smoothing is highly recommended for this product to round out corners from the graph, producing more realistic and reliable results.'
      },
      {
        type: 'img',
        heading: 'Sample output:',
        fileName: 'disttext.png'
      },
      {
        type: 'p',
        text: 'For more details about how these curves are constructed and potential uses, view the WinTR-20 software documentation.'
      },
      {
        type: 'li',
        lists: {
          'linkedOptions': [
            'Recurrence',
            'Smoothing',
            'Delivery'
          ],
          'seeAlso': [
            'Distribution Curves - Graphical'
          ]
        }
      }
    ]
  },
  {
    title: false,
    subtitle: false,
    name: 'Intensity Duration Frequency Graphs (IDF)',
    contentTitle: 'Intensity Frequency Duration Graphs',
    content: [
      {
        type: 'p',
        text: 'Intensity Frequency Duration (also known as Intensity Duration Frequency or IDF) graphs estimate peak rainfall intensity over a range of durations (subhourly, hourly, or daily) for a constant recurrence interval.'
      },
      {
        type: 'p',
        text: 'This product specifically derives intensity (not return period amount) by dividing the return period estimate by the number of days, hours, or minutes. (The units of duration and intensity are fully customizable for this product.) The output display will show a graph generally with decreasing intensity as duration increases, since the highest intensity extreme precipitation events are usually observed with shorter durations.'
      },
      {
        type: 'p',
        text: 'This product also provides a linear interpolation of intensity for intermediate durations that aren¢t specifically calculated in this project (4hr, 5day, etc.) A table under the graph shows the calculated and estimated values for each duration.'
      },
      {
        type: 'img',
        heading: 'Sample output:',
        fileName: 'ifd.png'
      },
      {
        type: 'p',
        text: 'As previously mentioned, the x-axis is customizable, to display subhourly (5min-120min), hourly (1hr-48hr), or daily (1day-10day) durations. The units for the horizontal axis can also be changed to inches/minute, inches/hour, or inches/day. If the display of this product appears odd, be sure the selected Duration Range and Intensity Units options match.'
      },
      {
        type: 'li',
        lists: {
          'linkedOptions': [
            'Duration Range',
            'Intensity Units',
            'Recurrence'
          ],
          'seeAlso': [
            'Precipitation Frequency Duration Graphs'
          ]
        }
      }
    ]
  },
  {
    title: false,
    subtitle: false,
    name: 'Precipitation Duration Frequency Graphs (PDF)',
    contentTitle: 'Precipitation Frequency Duration Graphs',
    content: [
      {
        type: 'p',
        text: 'Precipitation Frequency Duration (also known as Precipitation Duration Frequency or PDF) graphs estimate extreme rainfall over a range of durations (subhourly, hourly, or daily) for a constant recurrence interval.'
      },
      {
        type: 'p',
        text: "Not only does this product generate a graphical display of the standard durations and recurrence intervals, but this product also provides a linear interpolation for intermediate durations that aren't specifically calculated in this project (4hr, 5day, etc.) A table under the graph shows the calculated and estimated values for each duration."
      },
      {
        type: 'img',
        heading: 'Sample output:',
        fileName: 'pfd.png'
      },
      {
        type: 'p',
        text: 'The x-axis is customizable, to display subhourly (5min-120min), hourly (1hr-48hr), or daily (1day-10day) durations.'
      },
      {
        type: 'li',
        lists: {
          'linkedOptions': [
            'Duration Range',
            'Recurrence'
          ],
          'seeAlso': [
            'Intensity Frequency Duration Graphs'
          ]
        }
      }
    ]
  },
  {
    title: false,
    subtitle: false,
    name: 'GIS Data Files',
    contentTitle: 'GIS Data Files',
    content: [
      {
        type: 'p',
        text: 'The GIS (Geographic Information System) product provides the easiest way to download extreme precipitation data for the entire region. The files produced (file name output.asc) are in a special space-delimited ASCII raster file format, to allow for easy import into ArcGIS or similar software packages. Please note these files are relatively large (~12MB) and may take a while to download on a slow internet connection.'
      },
      {
        type: 'p',
        text: 'All values in the file that is generated are in inches, multiplied by a factor of 1000. Missing values are denoted by -999. (The appropriate metadata is included with the file so it can be directly imported into most GIS programs.)'
      },
      {
        type: 'img',
        heading: 'Sample output (ASCII raster text file):',
        fileName: 'gis.png'
      },
      {
        type: 'img',
        heading: 'Sample output (after import into GIS program):',
        fileName: 'quantum.png'
      },
      {
        type: 'p',
        text: 'The map projection used to generate this data is WGS-72, as requested by the NRCS who funded this project. Most high-end GIS software packages will be able to manipulate and analyze our data to suit your needs. We provide a regional shapefile in the Documentation section of this web site for your convenience in showing state boundaries.'
      },
      {
        type: 'p',
        text: 'Because it is rather tedious to download all 504 GIS data files, we can make arrangements to bundle files together for more convenient access. Please contact us at precip@cornell.edu to make these special arrangements. Handling fees may apply. Please note, we will likely be unable to provide data in a format different than what can be downloaded from this web site.'
      },
      {
        type: 'p',
        text: 'If you are unfamiliar with GIS files, the Regional/State Maps product may better suit your needs.'
      },
      {
        type: 'li',
        lists: {
          'linkedOptions': [
            'Data Type',
            'Duration',
            'Recurrence'
          ],
          'seeAlso': [
            'Extreme Precipitation Tables - HTML',
            'Regional/State Maps',
            'GIS Shapefile in Documentation'
          ]
        }
      }
    ]
  },
  {
    title: false,
    subtitle: false,
    name: 'Regional/State Maps',
    contentTitle: 'Regional/State Maps',
    content: [
      {
        type: 'p',
        text: 'Graphical display of the final data for all durations and recurrences are available. A variety of display options are available, including limiting the map to a single state or selected among full color, greyscale, or black-and-white.'
      },
      {
        type: 'img',
        heading: 'Sample output (Color):',
        fileName: 'maps_color.png'
      },
      {
        type: 'img',
        heading: 'Sample output (Greyscale):',
        fileName: 'maps_greyscale.png'
      },
      {
        type: 'img',
        heading: 'Sample output (Black & White):',
        fileName: 'maps_blackwhite.png'
      },
      {
        type: 'img',
        heading: 'Sample output (State Color):',
        fileName: 'maps_state.png'
      },
      {
        type: 'p',
        text: 'You are authorized to use the graphics generated on web sites, in publications, or other similar uses. Please leave the “ACIS” logo in tact, and/or give attribution to the Northeast Regional Climate Center.'
      },
      {
        type: 'p',
        text: 'If you are an advanced user with a need to analyze data across the entire Northeast US region (or across entire states), the GIS Data Files product may better suit your needs.'
      },
      {
        type: 'li',
        lists: {
          'linkedOptions': [
            'Region/State',
            'Duration',
            'Recurrence',
            'Display'
          ],
          'seeAlso': [
            'Extreme Precipitation Tables - HTML',
            'GIS Data Files'
          ]
        }
      }
    ]
  },
  {
    title: true,
    subtitle: '(for PDS-Station product only)',
    name: 'Search',
    contentTitle: 'Select Search Parameter(s)',
    content: [
      {
        type: 'p',
        text: 'Used only in the Partial Duration Series (by Station) product, this tool allows users to search for a specific station. The search parameters available are full or partial station ID number (6-digit COOP), station name (case insensitive), and state.'
      },
      {
        type: 'p',
        text: "The search parameters use an 'AND' boolean, so entering search terms in more than one box would enforce all of them in the final result set."
      },
      {
        type: 'p',
        text: 'Be aware that many station names are abbreviated throughout the database. In addition, if you attempt to return a large number of station results using this product (for instance, all stations in New York), the web server will likely time out and you will receive a blank result page. If this happens to you, please make your query more specific and try again. If you have a specific need for a mass download of all partial duration series, please contact us.'
      },
      {
        type: 'li',
        lists: {
          'products': ['Partial Duration Series - by Station'],
          'seeAlso': [
            'Search by State',
            'Search by Station ID',
            'Search by Name'
          ]
        }
      }
    ]
  },
  {
    title: false,
    subtitle: false,
    name: 'Search by ID',
    contentTitle: 'Search by Station ID',
    content: [
      {
        type: 'p',
        text: 'Option box appears only on PDS by Station product.'
      },
      {
        type: 'p',
        text: "This box allows users to search for a station's partial duration series by use of the 6-digit Cooperative (COOP) Station ID number (i.e. 304174)."
      },
      {
        type: 'p',
        text: <span><span style={{ fontStyle: 'italic' }}>From the <a className='link-inline' href="/">National Climatic Data Center</a>:</span> These stations are identified by a 6-digit number and are part of the National Weather Service&apos;s Cooperative Station Network. The first two digits designate a USA state or territory code. The last four digits are assigned to stations within a state in general accordance with the alphabetic order of the station name.</span>
      },
      {
        type: 'li',
        lists: {
          'products': ['Partial Duration Series - by Station'],
          'seeAlso': [
            'Select Search Parameter(s)',
            'Search by State',
            'Search by Name'
          ]
        }
      }
    ]
  },
  {
    title: false,
    subtitle: false,
    name: 'Search by Name',
    contentTitle: 'Search by Name',
    content: [
      {
        type: 'p',
        text: 'Option box appears only on PDS by Station product.'
      },
      {
        type: 'p',
        text: 'This search box allows users to search for a station by its "official" name. Please note that many stations use abbreviations in their names. Search is case insensitive.'
      },
      {
        type: 'li',
        lists: {
          'products': ['Partial Duration Series - by Station'],
          'seeAlso': [
            'Select Search Parameter(s)',
            'Search by State',
            'Search by Station ID'
          ]
        }
      }
    ]
  },
  {
    title: false,
    subtitle: false,
    name: 'Search by State',
    contentTitle: 'Search by State',
    content: [
      {
        type: 'p',
        text: 'Option box appears only on PDS by Station product.'
      },
      {
        type: 'p',
        text: 'This search box will allow users to limit their partial duration series search to a specific state. It is highly recommended use of this search box be combined with either the "Search by Name" or "Search by Station ID" products. If there are too many results, the server will likely time out and show a blank response. If this happens, please limit your search query (by making it more specific) and try again.'
      },
      {
        type: 'li',
        lists: {
          'products': ['Partial Duration Series - by Station'],
          'seeAlso': [
            'Select Search Parameter(s)',
            'Search by Station ID',
            'Search by Name'
          ]
        }
      }
    ]
  },
  {
    title: true,
    subtitle: false,
    name: 'Options',
    contentTitle: 'Select Options',
    content: [
      {
        type: 'p',
        text: 'Each available product has a set of options to customize the output display. All option dropdown boxes must have a value (either the default or user-selected) to activate the submit button.'
      },
      {
        type: 'p',
        text: 'For more information about a specific option, see the respective help page.'
      },
      {
        type: 'li',
        lists: {
          'seeAlso': [
            'Smoothing',
            'Delivery',
            'Duration',
            'Recurrence',
            'Compare to',
            'Duration Range',
            'Intensity Units',
            'Confidence Limits',
          ]
        }
      }
    ]
  },
  {
    title: false,
    subtitle: false,
    name: 'Compare to',
    contentTitle: 'Compare to',
    content: [
      {
        type: 'p',
        text: 'This option on the distribution curves product will allow the user to overlay an additional display over the selected distribution curve. This includes the option to overlay a standard Type II or III curve, used by the NRCS as regional design storms.'
      },
      {
        type: 'p',
        text: 'For a more complete explanation of the type curves and this product in general, refer to the Distribution Curves product help page or the Technical Manual.'
      },
      {
        type: 'li',
        lists: {
          'options': [
            'Type II Curve',
            'Type III Curve',
            '1yr',
            '2yr',
            '5yr',
            '10yr',
            '25yr',
            '50yr',
            '100yr',
            '200yr',
            '500yr'
          ],
          'products': [
            'Distribution Curves - Graphical'
          ],
          'seeAlso': [
            'Select Options',
            'Technical Manual'
          ]
        }
      }
    ]
  },
  {
    title: false,
    subtitle: false,
    name: 'Confidence Limits',
    contentTitle: 'Confidence Limits',
    content: [
      {
        type: 'p',
        text: 'This option will display the lower and upper confidence limit bounds on the product output. The confidence limits calculated here are 90% intervals, and the lower and upper bounds represent the 5th and 95th percentile respectively. These bounds were calculated via a Monte Carlo approach with 1,000 trials, then the intervals were gridded and smoothed across the entire region.'
      },
      {
        type: 'li',
        lists: {
          'options': ['Show', 'Hide'],
          'products': ['Intensity Frequency Duration Graphs', 'Precipitation Frequency Duration Graphs'],
          'seeAlso': ['Select Options']
        }
      }
    ]
  },
  {
    title: false,
    subtitle: false,
    name: 'Delivery',
    contentTitle: 'Delivery',
    content: [
      {
        type: 'p',
        text: 'The Delivery option dictates how the product will be displayed to the user, either in a new browser window (Popup) or as a file that will automatically begin to download (Download). Please note that selecting Popup delivery and then saving the output file in your browser may not properly save the data and output format.'
      },
      {
        type: 'li',
        lists: {
          'options': ['Popup', 'Download'],
          'products': [
            'Extreme Precipitation Tables - HTML',
            'Partial Duration Series - by Point',
            'Distribution Curves - Text/TBL'
          ],
          'seeAlso': ['Select Options']
        }
      }
    ]
  },
  {
    title: false,
    subtitle: false,
    name: 'Display',
    contentTitle: 'Display',
    content: [
      {
        type: 'p',
        text: 'Maps for all states and the Northeast region can be displayed in a variety of color formats: color contours, greyscale contours, and contour lines only. The color scale is automatically generated for pre-determined contour intervals for each duration and recurrence.'
      },
      {
        type: 'p',
        text: 'You are authorized to use the graphical map products generated on this site for any publication, web site, etc. with proper attribution, and as long as the "Powered by ACIS" logo remains intact.'
      },
      {
        type: 'li',
        lists: {
          'options': ['Color','Greyscale','Black/White'],
          'products': ['Regional/State Maps'],
          'seeAlso': ['Select Options'],
        }
      }
    ]
  },
  {
    title: false,
    subtitle: false,
    name: 'Duration',
    contentTitle: 'Duration',
    content: [
      {
        type: 'p',
        text: 'This option allows the user to select the duration (time) for the product display.'
      },
      {
        type: 'li',
        lists: {
          'options': [
            '5min',
            '10min',
            '15min',
            '30min',
            '60min',
            '120min',
            '1hr',
            '2hr',
            '3hr',
            '6hr',
            '12hr',
            '24hr',
            '48hr',
            '1day',
            '2day',
            '4day',
            '7day',
            '10day'
          ],
          'products': [
            'Partial Duration Series - by Point',
            'Partial Duration Series - by Station',
            'GIS Data Files',
            'Regional/State Maps'
          ],
          'seeAlso': [
            'Select Options'
          ]
        }
      }
    ]
  },
  {
    title: false,
    subtitle: false,
    name: 'Duration Range',
    contentTitle: 'Duration Range',
    content: [
      {
        type: 'p',
        text: 'For the IDF and PDF curves, the duration range represents the time range along the X (horizontal) axis of the graph. It is important to select this option and the Intensity Units option correctly (if present) to avoid very odd output.'
      },
      {
        type: 'li',
        lists: {
          'options': [
            '5min-120min',
            '1hr-48hr',
            '1day-10day'
          ],
          'products': [
            'Intensity Frequency Duration Graphs',
            'Precipitation Frequency Duration Graphs'
          ],
          'seeAlso': [
            'Select Options',
            'Intensity Units'
          ]
        }
      }
    ]
  },
  {
    title: false,
    subtitle: false,
    name: 'Intensity Units',
    contentTitle: 'Intensity Units',
    content: [
      {
        type: 'p',
        text: 'For the IDF curves product, this option represents the units (inches per minute, inches per hour, or inches per day) of the precipitation intensity along the Y axis. It is important to sync this to (or at least select it strategically along with) the Duration Range option.'
      },
      {
        type: 'li',
        lists: {
          'options': [
            'inches/minute',
            'inches/hour',
            'inches/day'
          ],
          'products': [
            'Intensity Frequency Duration Graphs'
          ],
          'seeAlso': [
            'Select Options',
            'Duration Range'
          ]
        }
      }
    ]
  },
  {
    title: false,
    subtitle: false,
    name: 'Recurrence',
    contentTitle: 'Recurrence',
    content: [
      {
        type: 'p',
        text: 'This option allows the user to select the Recurrence (Year) or probability of occurrence. The respective probabilities for each recurrence are listed in parentheses. Note the 1yr storm is treated differently, using an Annual Maximum Series comparison. See the Technical Documentation for more details on these definitions.'
      },
      {
        type: 'li',
        lists: {
          'options': [
            '1yr',
            '2yr (50%)',
            '5yr (20%)',
            '10yr (10%)',
            '25yr (4%)',
            '50yr (2%)',
            '100yr (1%)',
            '200yr (0.5%)',
            '500yr (0.2%)'
          ],
          'products': [
            'Distribution Curves - Graphical',
            'Distribution Curves - Text/TBL',
            'Intensity Frequency Duration Graphs',
            'Precipitation Frequency Duration Graphs',
            'GIS Data Files',
            'Regional/State Maps'
          ],
          'seeAlso': [
            'Select Options'
          ]
        }
      }
    ]
  },
  {
    title: false,
    subtitle: false,
    name: 'Smoothing',
    contentTitle: 'Smoothing',
    content: [
      {
        type: 'p',
        text: 'This option dictates whether the smoothing routine developed for the NRCS WinTR20 software package should be applied to data before output. Note that choosing No will NOT remove all smoothing from the analysis, producing "raw" results. The regionalization, gridding, and smoothing techniques employed in this project will always be present in data output. This extra smoothing step resolves some of the inconsistencies among the n-minute and hourly durations.'
      },
      {
        type: 'p',
        text: 'This smoothing step only affects the 5min, 10min, 15min, 30min, 120min, 3hr, 6hr, and 12hr durations. While the 60min and 24hr durations are used in the smoothing calculations, they remain unchanged.'
      },
      {
        type: 'p',
        text: 'For more information, please refer to the Technical Manual.'
      },
      {
        type: 'li',
        lists: {
          'options': [
            'Yes',
            'No'
          ],
          'products': [
            'Extreme Precipitation Tables - HTML',
            'Extreme Precipitation Tables - Text',
            'Distribution Curves - Graphical',
            'Distribution Curves - Text/TBL'
          ],
          'seeAlso': [
            'Select Options',
            'Technical Manual'
          ]
        }
      }
    ]
  }
];