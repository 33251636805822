import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

// Create style classes for entire component
const useStyles = makeStyles(() => ({
  documentationCont: {
    boxSizing: 'border-box',
    display: 'flex',
    flexWrap: 'wrap',
    width: '922px',
    height: '602px',
    backgroundColor: '#eeeeee',
    border: '1px solid black',
    boxShadow: '3px 4px 3px 1px rgba(0,0,0,0.7)',
    margin: '0 auto',
    zIndex: '2',
    padding: '7px',
    justifyContent: 'center',
    gap: '60px'
  },
  column: {
    boxSizing: 'border-box',
    height: '585px',
    width: '320px',
    border: '1px solid black',
    padding: '3px 10px'
  },
  columnTitle: {
    // eslint-disable-next-line quotes
    fontFamily: "'Tahoma', 'Helvetica', 'Arial', sans-serif",
    fontWeight: '700',
    fontSize: '18px',
    color: '#660000',
    textAlign: 'center',
    marginBottom: '8px'
  },
  categoryCol: {
    backgroundColor: '#dddddd',
  },
  listCont: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
  },
  category: {
    display: 'flex',
    padding: '6px',
    boxSizing: 'border-box',
    height: '63px',
    border: '1px solid black',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgb(184,201,235)'
    }
  },
  selected: {
    backgroundColor: 'rgb(255,255,187) !important',
    borderRight: 'none',
    width: '370px',
    position: 'relative'
  },
  unselected: {
    backgroundColor: 'rgb(204,221,255)'
  },
  categoryName: {
    fontSize: '19px',
    fontWeight: '700',
    color: 'rgb(0,0,153)',
    padding: '14px 0px 16px 12px'
  },
  fileCol: {
    backgroundColor: 'rgb(255,255,187)'
  },
  file: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '48px',
    width: '302px',
    padding: '5px 0px 5px 10px',
    boxSizing: 'border-box',
    border: '1px solid black',
    backgroundColor: 'rgb(238,238,238)',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgb(218,218,218)'
    }
  },
  fileName: {
    fontSize: '16px',
    fontWeight: '700',
    color: 'rgb(0,0,153)',
  },
  fileDescription: {
    fontSize: '12px',
    fontWeight: '400',
    color: 'rgb(0,0,0)',
  },
  docsLink: {
    textDecoration: 'none'
  },
  main: {
    position: 'relative',
    zIndex: '1',
    padding: '0 17px'
  }
}));



export default function Documentation({ categories }) {
  const [selectedCategory, setSelectedCategory] = useState(categories[0].name);
  const [files, setFiles] = useState(categories[0].files);

  const classes = useStyles();

  const handleCategoryClick = (categoryName, categoryFiles) => {
    setSelectedCategory(categoryName);
    setFiles(categoryFiles);
  };

  return (
    <main className={classes.main}>
      <div className={classes.documentationCont}>
        <div className={`${classes.column} ${classes.categoryCol}`}>
          <Typography variant='h3' className={classes.columnTitle}>Categories</Typography>
      
          <div className={classes.listCont}>
            {categories.map(category => {
              return (
                <div
                  key={category.name}
                  className={`${classes.category} ${selectedCategory === category.name ? classes.selected : classes.unselected}`}
                  onClick={() => handleCategoryClick(category.name, category.files)}
                >
                  {category.icon}
                  <Typography className={classes.categoryName}>{category.name}</Typography>
                </div>
              );
            })}
          </div>
        </div>
      
        <div className={`${classes.column} ${classes.fileCol}`}>
          <Typography variant='h3' className={classes.columnTitle}>Files</Typography>
          <div className={classes.listCont}>
            {files.map((file, index) => {
              if (file.loc === 'local') {
                return (
                  <a
                    key={file.name + index}
                    href={process.env.PUBLIC_URL + file.href}
                    className={classes.docsLink}
                    target='_blank'
                    rel="noreferrer"
                  >
                    <div className={classes.file}>
                      <Typography className={classes.fileName}>{file.name}</Typography>
                      <Typography className={classes.fileDescription}>{file.description}</Typography>
                    </div>
                  </a>
                );
              } else if (file.loc === 'download') {
                return (
                  <a
                    key={file.name + index}
                    className={classes.docsLink}
                    href={process.env.PUBLIC_URL + file.href}
                    download
                  >
                    <div className={classes.file}>
                      <Typography className={classes.fileName}>{file.name}</Typography>
                      <Typography className={classes.fileDescription}>{file.description}</Typography>
                    </div>
                  </a>
                );
              } else if (file.loc === 'external') {
                return (
                  <a
                    key={file.name + index}
                    href={file.href}
                    className={classes.docsLink}
                    target='_blank'
                    rel="noreferrer"
                  >
                    <div className={classes.file}>
                      <Typography className={classes.fileName}>{file.name}</Typography>
                      <Typography className={classes.fileDescription}>{file.description}</Typography>
                    </div>
                  </a>
                );
              } else {
                return (
                  <Link
                    key={file.name + index}
                    to={file.href}
                    className={classes.docsLink}
                    target='_blank'
                    rel="noreferrer"
                  >
                    <div className={classes.file}>
                      <Typography className={classes.fileName}>{file.name}</Typography>
                      <Typography className={classes.fileDescription}>{file.description}</Typography>
                    </div>
                  </Link>
                );
              }
            })}
          </div>
        </div>
      </div>
    </main>
  );
}

Documentation.propTypes = {
  categories: PropTypes.array.isRequired
};