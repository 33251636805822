import pako from 'pako';
import { round2Digits, round3Digits } from './rounding';

function capFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export async function getStationList(url, date=false) {
  let results = fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(data => data.json())
    .then(jData => {
      let capAlls = ['AP', 'SUNY', 'NE','SE','SW','NW','NNE','ENE','ESE','SSE','SSW','WSW','WNW','NNW'];
      
      let resStations = {};
      jData.data.forEach(station => {
        let values = [];
        for (let i = 0; i < station.data.length; i++) {
          const element = station.data[i][0];
            
          if (element === 'M') {
            values.push('M');
          } else if (element.includes('A')) {
            values.push(parseFloat(element.split('A')[0]));
          } else if (element === 'T' || element === 'S') {
            values.push(0.00);
          } else {
            values.push(parseFloat(element));
          }
        }

        let sids = station.meta.sids;
        sids.forEach(sid => {
          let sidArr = sid.split(' ');
          
          if (sidArr[1] === '2') {
            let stnName = station.meta.name.replaceAll('-', '-| ').split(' ');
            stnName = stnName.map(str => {
              str = capFirst(str.toLowerCase());
                
              if (capAlls.includes(str.toUpperCase())) {
                str = str.toUpperCase();
              }
                
              return str;
            });
            stnName = stnName.join(' ').replaceAll('-| ', '-');
            
            if (values.length === 10) {
              let newValues = [1,2,4,7,10].map(dur => {
                let days = values.slice(10 - dur);
                if (days.includes('M')) {
                  return 'Missing Data';
                } else {
                  let total = days.reduce((sum, val) => sum += val);
                  return round2Digits(total);
                }
              });

              resStations[stnName] = {
                [date]: newValues,
                meta: {
                  state: station.meta.state,
                  lat: round3Digits(station.meta.ll[1]),
                  lon: round3Digits(station.meta.ll[0]),
                  elev: station.meta.elev,
                  name: stnName,
                  sid: sidArr[0]
                }
              };
            } else {
              resStations[stnName] = sidArr[0];
            }
          }
        });
      });

      let sortedStns = Object.keys(resStations).sort();

      return sortedStns.map(station => [station, resStations[station]]);
    })
    .catch(e => {
      console.log(e);
      return false;
    });

  return results;
}

export async function getStationCalendarData(year, sid) {
  let results = fetch(`https://data.rcc-acis.org/StnData?sId=${sid}&sDate=${year}-01-01&eDate=${year}-12-31&elems=pcpn&output=json`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(data => data.json())
    .catch(e => {
      console.log(e);
      return false;
    });

  return results;
}

export async function getMapData(bDate, eDate, duration) {
  let years = ['1yr','2yr','5yr','10yr','25yr','50yr','100yr','200yr','500yr'];
  let capAlls = ['AP', 'SUNY', 'NE','SE','SW','NW','NNE','ENE','ESE','SSE','SSW','WSW','WNW','NNW'];
  
  let results = Promise.all([fetchFromAcis(bDate, eDate), fetchStationFile()])
    .then(dataset => {
      let acisData = dataset[0];
      let fileData = dataset[1];

      let results = [];
      acisData.data.forEach(stn => {
        try {
          let stnID = '';
          stn.meta.sids.forEach(id => {
            let thisID = id.split(' ');

            if (thisID[1] === '2' && thisID[0].length === 6) {
              stnID = thisID[0];
            }
          });

          // Check that station is in network
          if (stnID !== '') {
            let val = parseFloat(stn.data[0]);
            
            // Check that value for day is not missing or trace
            if (!isNaN(val)) {
              // Retrieve year storms for station at given duration
              let xPrecip = fileData[stnID][duration].slice(0,9);

              // Check that value is greater than lowest storm value
              if (val >= xPrecip[0]) {
                let xPrecip2 = [ ...xPrecip, val + 0.001];
                xPrecip2.sort((a,b)=>a-b);
                let idx = xPrecip2.indexOf(val + 0.001) - 1;

                let stnName = stn.meta.name.replaceAll('-', '-| ').split(' ');
                stnName = stnName.map(str => {
                  str = capFirst(str.toLowerCase());

                  if (capAlls.includes(str.toUpperCase())) {
                    str = str.toUpperCase();
                  }

                  return str;
                });
                stnName = stnName.join(' ').replaceAll('-| ', '-');

                results.push({
                  id: stnID,
                  name: stnName,
                  state: stn.meta.state,
                  lat: parseFloat(stn.meta.ll[1]),
                  lon: parseFloat(stn.meta.ll[0]),
                  elev: parseInt(stn.meta.elev),
                  yrStorm: years[idx],
                  val,
                  xPrecip
                });
              }
            }
          }
        } catch {
          // Don't add to results
        }
      });

      return results.sort((stnA, stnB) => {
        if (stnA.yrStorm === stnB.yrStorm) {
          if (stnA.name < stnB.name) {
            return -1;
          } else {
            return 1;
          }
        } else {
          if (parseInt(stnA.yrStorm.split('y')[0]) < parseInt(stnB.yrStorm.split('y')[0])) {
            return 1;
          } else {
            return -1;
          }
        }
      });
    });

  
  return results;
}

async function fetchFromAcis(bDate, eDate) {
  let results = fetch('https://data.rcc-acis.org/MultiStnData', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'state': ['CT','MA','ME','NH','NY','RI','VT'],
      'date':eDate,
      'elems':[
        {
          'name':'pcpn',
          'duration':'std',
          'season_start':bDate.slice(5),
          'reduce':'sum'
        }
      ]
    })
  })
    .then(data => data.json())
    .catch(e => {
      console.log(e);
      return false;
    });

  return results;
}

async function fetchStationFile() {
  try {
    const data = fetch(`${process.env.PUBLIC_URL}/data/realtime/stations.json.gz`, {
      mode: 'cors'
    })
      .then(res => res.arrayBuffer())
      .then(arrBuff => pako.inflate(arrBuff))
      .then(buff => new TextDecoder().decode(buff))
      .then(str => JSON.parse(str));

    return data;
  } catch (err) {
    console.error('Unable to fetch and decompress data...', err);
    return [];
  }
}