import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  mark: {
    // eslint-disable-next-line quotes
    fontFamily: "'Tahoma', 'Helvetica', 'Arial', sans-serif",
    display: 'inline-block',
    boxSizing: 'border-box',
    position: 'relative',
    top: '-5px',
    left: '-2px',
    height: '16px !important',
    width: '16px !important',
    paddingTop: '2px !important',
    marginRight: '3px !important',
    color: '#aa0000',
    fontSize: '11px !important',
    fontWeight: 'bold',
    textShadow: '1px 1px rgb(110,110,110)',
    textAlign: 'center',
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

export default function QuestionMark({ value }) {
  const classes = useStyles();
  
  let href = value.replaceAll(' ', '_').replaceAll('/','&');

  return (
    <Link className={classes.mark} to={'/user_manual/' + href} target='_blank'>?</Link>
  );
}

QuestionMark.propTypes = {
  value: PropTypes.string.isRequired
};