/* eslint-disable no-undef */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { leftFooterLogos, rightFooterLogos } from '../Assets/footer';

// Create style classes for entire component
const useStyles = makeStyles(() => ({
  footerCont: {
    position: 'relative',
    height: '100px',
    width: '960px',
    margin: '15px auto',
    padding: '3px',
    borderRadius: '25px',
    backgroundColor: 'rgb(152,152,152)',
    boxShadow: 'inset -1px -1px 3px 2px rgba(0,0,0,0.5), 2px 3px 4px 1px rgba(0,0,0,0.6)',
    boxSizing: 'border-box',
    zIndex: '0',
  },
  inset: {
    borderRadius: '25px',
    boxShadow: 'inset 3px 3px 3px 2px rgba(255,255,255,0.5)',
    position: 'absolute',
    top: '-1px',
    bottom: '-1px',
    left: '-1px',
    right: '-1px',
    zIndex: '-1'
  },
  footerText: {
    // eslint-disable-next-line quotes
    fontFamily: "'Tahoma', 'Helvetica', 'Arial', sans-serif",
    fontWeight: '700',
    textAlign: 'center'
  },
  footerLinkText: {
    fontSize: '14px',
    color: 'rgb(0,0,153)',
  },
  verAndCopy: {
    display: 'flex',
    width: '300px',
    justifyContent: 'space-around',
    margin: '0px auto',
  },
  noDec: {
    textDecoration: 'none'
  },
  footerMiddle: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  tileCont: {
    display: 'flex',
    justifyContent: 'center',
    gap: '5px',
    height: '30px',
  },
  contact: {
    display: 'flex',
    justifyContent: 'center',
    gap: '5px'
  },
  tile: {
    height: '30px',
    backgroundColor: 'white'
  }
}));



export default function Footer() {
  const classes = useStyles();

  return (
    <footer className={classes.footerCont}>
      <div className={classes.inset}></div>

      <div className={classes.verAndCopy}>
        <Typography className={classes.footerText}>Version 2.0</Typography>
        <Typography className={classes.footerText}>Copyright 2010-2022</Typography>
      </div>
      
      <Typography className={classes.footerText}>This project is a joint collaboration between:</Typography>

      <div className={classes.footerMiddle}>
        <div>
          <a className={classes.noDec} target="_blank"  href="http://www.nrcc.cornell.edu" rel="noreferrer">
            <Typography className={`${classes.footerLinkText} ${classes.footerText}`}>Northeast Regional Climate Center (NRCC)</Typography>
          </a>
          <div className={classes.tileCont}>
            {
              leftFooterLogos.map((logoInfo) => {
                return (
                  <a key={logoInfo.src} target='_blank' href={logoInfo.url} rel="noreferrer">
                    <img src={process.env.PUBLIC_URL + '/assets/Logos/' + logoInfo.src} className={classes.tile}></img>
                  </a>
                );
              })
            }
          </div>
        </div>
        
        <div>
          <a className={classes.noDec} target="_blank"  href="http://www.nrcs.usda.gov" rel="noreferrer">
            <Typography className={`${classes.footerLinkText} ${classes.footerText}`}>Natural Resources Conservation Service (NRCS)</Typography>
          </a>
          <div className={classes.tileCont}>
            {
              rightFooterLogos.map((logoInfo) => {
                return (
                  <a key={logoInfo.src} target='_blank' href={logoInfo.url} rel="noreferrer">
                    <img src={process.env.PUBLIC_URL + '/assets/Logos/' + logoInfo.src} className={classes.tile}></img>
                  </a>
                );
              })
            }
          </div>
        </div>
      </div>
      
      <div className={classes.contact}>
        <Typography className={classes.footerText}>Contact: </Typography>
        <a className={classes.noDec} href="mailto:precip@cornell.edu"><Typography className={`${classes.footerLinkText} ${classes.footerText}`} style={{fontSize: '14px'}}>precip@cornell.edu</Typography></a>
      </div>
    </footer>
  );
}
