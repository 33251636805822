/* eslint-disable no-undef */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

// Create style classes for entire component
const useStyles = makeStyles(() => ({
  headerCont: {
    position: 'relative',
    boxSizing: 'border-box',
    boxShadow: '2px 3px 4px 1px rgba(0,0,0,0.6)',
    borderRadius: '25px',
    width: '960px',
    height: '100px',
    overflow: 'hidden',
    margin: '0 auto',
  },
  headingTitle: {
    position: 'absolute',
    top: '13px',
    left: '29px',
  },
  title: {
    fontSize: '36px',
    fontWeight: '800',
    textShadow: '2px 2px 4px #ffffff',
  },
  subtitle: {
    fontWeight: '600',
    fontSize: '1.4rem',
    fontStyle: 'italic',
    textShadow: '2px 2px 4px #ffffff',
    lineHeight: '30px',
  },
  inset: {
    borderRadius: '25px',
    boxShadow: 'inset 3px 3px 3px 2px rgba(255,255,255,0.5)',
    position: 'absolute',
    top: '0px',
    bottom: '0px',
    left: '0px',
    right: '0px',
  },
  inset2: {
    borderRadius: '25px',
    boxShadow: 'inset -2px -2px 3px 2px rgba(0,0,0,0.4)',
    position: 'absolute',
    top: '0px',
    bottom: '0px',
    left: '0px',
    right: '0px',
  },
}));

export default function Header() {
  const classes = useStyles();

  return (
    <header className={classes.headerCont}>
      <div className={classes.headImgCont}>
        <img
          className={classes.headerImg}
          src={process.env.PUBLIC_URL + '/assets/Header/clouds.jpg'}
          alt='Clouds background'
        />
      </div>
      <div className={classes.headingTitle}>
        <Typography className={classes.title} variant='h1'>
          Extreme Precipitation in New York &amp; New England
        </Typography>
        <Typography className={classes.subtitle} variant='subtitle1'>
          An Interactive Web Tool for Extreme Precipitation Analysis
        </Typography>
      </div>
      <div className={classes.inset2}></div>
      <div className={classes.inset}></div>
    </header>
  );
}
