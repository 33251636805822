/* eslint-disable no-undef */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './Components/App';
import reportWebVitals from './reportWebVitals';
import { HashRouter } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';

// eslint-disable-next-line quotes
const fonts = "'Tahoma', 'Helvetica', 'Arial', sans-serif";
// const fonts = "'Alegreya Sans', 'Roboto', 'Helvetica', 'Arial', sans-serif";
// eslint-disable-next-line quotes
const secondaryFonts = "'Arial', sans-serif";

// Define custom theme for coloring and breakpoints
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 400,
      md: 750,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: fonts,
    h1: {
      fontSize: '2.3rem'
    },
    h2: {
      fontSize: '1.5rem'
    },
    h3: {
      fontSize: '1.3rem'
    },
    h4: {
      fontSize: '1.0rem'
    },
    h5: {
      fontSize: '0.8rem'
    },
    body1: {
      fontFamily: secondaryFonts,
      fontSize: '0.85rem',
      fontWeight: 400,
      lineHeight: 1.15
    }
  },
  'palette': {
    'common': {
      'black': '#000',
      'white': '#fff'
    },
    'background': {
      'paper': '#fff',
      'default': 'rgba(242, 242, 242, 1)'
    },
    'primary': {
      'light': 'rgba(255, 101, 101, 1)',
      'main': 'rgba(255, 0, 0, 1)',
      'dark': 'rgba(181, 0, 0, 1)',
      'contrastText': '#fff'
    },
    'secondary': {
      'light': 'rgba(203, 203, 203, 1)',
      'main': 'rgba(157, 157, 157, 1)',
      'dark': 'rgba(99, 99, 99, 1)',
      'contrastText': '#fff'
    },
    'error': {
      'light': 'rgba(244, 147, 54, 1)',
      'main': 'rgba(244, 206, 54, 1)',
      'dark': 'rgba(238, 195, 19, 1)',
      'contrastText': 'rgba(0, 0, 0, 1)'
    },
    'text': {
      'primary': 'rgba(0, 0, 0, 0.87)',
      'secondary': 'rgba(0, 0, 0, 0.54)',
      'disabled': 'rgba(0, 0, 0, 0.38)',
      'hint': 'rgba(0, 0, 0, 0.38)'
    }
  }
});


ReactDOM.render(
  <ThemeProvider theme={theme}>
    <HashRouter>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </HashRouter>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
