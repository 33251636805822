import pako from 'pako';

import { round3Digits, round1Digits, round0Digits, round2Digits } from '../HelperScripts/rounding';

function distance_between(lat1, lon1, lat2, lon2) {
  // Convert coordinates to from degrees to radians
  let radians = Math.PI / 180;
  let phi1 = (90 - lat1) * radians;
  let phi2 = (90 - lat2) * radians;
  
  let theta1 = lon1 * radians;
  let theta2 = lon2 * radians;
  
  // Compute distance between points
  let cos = Math.sin(phi1) * Math.sin(phi2) * Math.cos(theta1 - theta2) +
  Math.cos(phi1) * Math.cos(phi2);

  let arc;
  if (cos >= -1.0 && cos <= 1.0) {
    arc = Math.acos( cos );
  } else {
    arc = -999;
  }
  
  return arc * 3959;
}

async function fetchPdsData(duration, stnID) {
  try {
    const data = fetch(`${process.env.PUBLIC_URL}/data/pds/time_${duration}/stn_${stnID}.json.gz`, {
      mode: 'cors'
    })
      .then(res => res.arrayBuffer())
      .then(arrBuff => pako.inflate(arrBuff))
      .then(buff => new TextDecoder().decode(buff))
      .then(str => JSON.parse(str));

    return data;
  } catch (err) {
    console.error('Unable to fetch and decompress data...', err);
    return [];
  }
}

async function fetchXprecipData(row, col) {
  try {
    const data = fetch(`${process.env.PUBLIC_URL}/data/xprecip/${row}.json.gz`, {
      mode: 'cors'
    })
      .then(res => res.arrayBuffer())
      .then(arrBuff => pako.inflate(arrBuff))
      .then(buff => new TextDecoder().decode(buff))
      .then(str => JSON.parse(str))
      .then(jData => jData[col]);

    return data;
  } catch (err) {
    console.error('Unable to fetch and decompress data...', err);
    return [];
  }
}

async function fetchNearestStation(options) {
  let period = options['Duration'].includes('day') ? 'daily' : 'hourly';
  
  // Get stations from duration type
  let stations = await fetchStations(period);

  // Loop through saving the distance between and info for the nearest station with omega >= .75 and yor >= 20
  return stations.reduce((acc, station) => {
    if (station.omega >= 0.75 && station.yor >= 20) {
      let distance = distance_between(options.lat, options.lon, station.lat, station.lon);

      if (distance < acc.distanceFrom) {
        acc = {
          stationData: station,
          distanceFrom: distance
        };
      }
    }

    return acc;
  }, { stationData: {}, distanceFrom: 9999 });
}

function smooth_rf_data(arr) {
  // Dan's magical fudge
  if (arr[8] >= arr[9]) {
    arr[8] = arr[9] - 0.1;
  }

  let inc_int = [];
  for (let i = 0; i < 10; i++) {
    let mult;
    if (i === 0) {
      inc_int.push(arr[i] * 12);
      continue;
    } else if (i <= 2) {
      mult = 12;
    } else if (i === 3) {
      mult = 4;
    } else if (i === 4) {
      mult = 2;
    } else if (i <= 6) {
      mult = 1;
    } else if (i === 7) {
      mult = (1/3);
    } else if (i === 8) {
      mult = (1/6);
    } else if (i === 9) {
      mult = (1/12);
    }
    
    inc_int.push((arr[i] - arr[i - 1]) * mult);
  }

  let n = 1;
  let temp_5_min = arr[0];
  let temp_dur_precip = [...arr];
  let error_increase_flag = 0;
  let sq_error = Array(21).fill(0);

  while (n <= 51) {
    let LN_ii_5 = Math.log(temp_5_min * 12);
    let LN_ii_60 = Math.log(inc_int[4]);

    temp_dur_precip[0] = temp_5_min;
    temp_dur_precip[4] = arr[4];
    temp_dur_precip[9] = arr[9];

    let j = 1;
    while (j <= 12) {
      let coeff = (LN_ii_60 - LN_ii_5) / (Math.log(60.) - Math.log(5));
      let LN_ii_10 = LN_ii_5 + ((Math.log(10) - Math.log(5)) * coeff);
      let LN_ii_15 = LN_ii_5 + ((Math.log(15) - Math.log(5)) * coeff);
      let LN_ii_30 = LN_ii_5 + ((Math.log(30) - Math.log(5)) * coeff);
      
      inc_int[0] = Math.exp(LN_ii_5);
      inc_int[1] = Math.exp(LN_ii_10);
      inc_int[2] = Math.exp(LN_ii_15);
      inc_int[3] = Math.exp(LN_ii_30);
      
      temp_dur_precip[1] = temp_5_min + inc_int[1] / 12;
      temp_dur_precip[2] = temp_dur_precip[1] + inc_int[2] / 12;
      temp_dur_precip[3] = temp_dur_precip[2] + inc_int[3] / 4;
      
      let temp_dur_freq = temp_dur_precip[3] + inc_int[4] / 2;
      let difference = Math.abs(temp_dur_freq - arr[4]);
      
      if (difference < 0.005) {
        break;
      } else {
        inc_int[4] = inc_int[4] * arr[4] / temp_dur_freq;
        LN_ii_60 = Math.log(inc_int[4]);
      }
      
      j += 1;
    }

    LN_ii_60 = Math.log(inc_int[4]);
    let LN_ii_24 = Math.log(inc_int[9]);
    j = 1;
    while (j <= 20) {
      let coeff = (LN_ii_24 - LN_ii_60) / (Math.log(1440) - Math.log(60));
      let LN_ii_2 = LN_ii_60 + ((Math.log(120) - Math.log(60)) * coeff);
      let LN_ii_3 = LN_ii_60 + ((Math.log(180) - Math.log(60)) * coeff);
      let LN_ii_6 = LN_ii_60 + ((Math.log(360) - Math.log(60)) * coeff);
      let LN_ii_12 = LN_ii_60 + ((Math.log(720) - Math.log(60)) * coeff);
      
      inc_int[5] = Math.exp(LN_ii_2);
      inc_int[6] = Math.exp(LN_ii_3);
      inc_int[7] = Math.exp(LN_ii_6);
      inc_int[8] = Math.exp(LN_ii_12);
      
      temp_dur_precip[5] = arr[4] + inc_int[5];
      temp_dur_precip[6] = temp_dur_precip[5] + inc_int[6];
      temp_dur_precip[7] = temp_dur_precip[6] + inc_int[7] * 3;
      temp_dur_precip[8] = temp_dur_precip[7] + inc_int[8] * 6;
     
      let temp_dur_freq = temp_dur_precip[8] + inc_int[9] * 12;
      let difference = Math.abs(temp_dur_freq - arr[9]);
      
      if (difference < 0.01) {
        break;
      } else {
        inc_int[9] = inc_int[9] * arr[9] / temp_dur_freq;
        LN_ii_24 = Math.log(inc_int[9]);
      }
      
      j += 1;
    }

    sq_error[n] = Math.pow((temp_dur_precip[0] - arr[0]),2) + Math.pow((temp_dur_precip[1] - arr[1]),2) + Math.pow((temp_dur_precip[2] - arr[2]),2) + Math.pow((temp_dur_precip[3] - arr[3]),2);
    
    if (n === 1) {
      var RF_inc = -0.01;
      temp_5_min = temp_5_min + RF_inc;
      inc_int[0] = temp_5_min * 12.0;
    } else {
      if (n < 6) {
        if (sq_error[n] > sq_error[n-1]) {
          RF_inc = -1.0 * RF_inc;
        }
        
        temp_5_min = temp_5_min + RF_inc;
        inc_int[0] = temp_5_min * 12.0;
      } else {
        if (error_increase_flag === 1) { break; }
        
        if (sq_error[n] > sq_error[n-1]) {
          RF_inc = -1.0 * RF_inc;
          error_increase_flag = 1;
        }
        
        if (n === 51) { break; }
        
        temp_5_min = temp_5_min + RF_inc;
        
        if (temp_5_min === 0) { break; }
        
        inc_int[0] = temp_5_min * 12.0;
      }
    }

    n += 1;
  }

  return temp_dur_precip.map(val => parseFloat(round2Digits(val)));
}



export async function fetchStations(period) {
  try {
    const data = fetch(process.env.PUBLIC_URL + '/data/stations/' + period + '.json.gz', {
      mode: 'cors'
    })
      .then(res => res.arrayBuffer())
      .then(arrBuff => pako.inflate(arrBuff))
      .then(buff => new TextDecoder().decode(buff))
      .then(str => JSON.parse(str));

    return data;
  } catch (err) {
    console.error('Unable to fetch and decompress data...', err);
    return {};
  }
}

export async function getPds(options, STATE_CODES) {
  let meta, data;

  if (options.point) {
    let closest = await fetchNearestStation(options);
    let sData = closest.stationData;

    data = await fetchPdsData(options['Duration'], sData.id);

    meta = {
      point: [
        ['State', options.State],
        ['Location', options.Name],
        ['Latitude', `${round3Digits(options.lat)} degrees North`],
        ['Longitude', `${Math.abs(round3Digits(options.lon))} degrees West`],
        ['Elevation', `${options.elev} feet`],
        ['Date/Time', Date()]
      ],
      station: [
        ['Station ID/Name', `#${sData.id} - ${sData.name}`],
        ['State', STATE_CODES[sData.id.slice(0,2)][1]],
        ['Latitude', `${round3Digits(sData.lat)} degrees North`],
        ['Longitude', `${Math.abs(round3Digits(sData.lon))} degrees West`],
        ['Elevation', `${sData.elev} feet`],
        ['Distance from Point', `${round1Digits(closest.distanceFrom)} miles`],
        ['Period of Record', `${sData.start} - ${sData.end}`],
        ['Percent Complete', `${round0Digits(sData.omega * 100)}%`],
        ['Non-missing Years', `${sData.yor} years`]
      ]
    };
  } else{
    data = await fetchPdsData(options['Duration'], options['Station ID']);

    meta = {
      station: [
        ['Station ID/Name', `#${options['Station ID']} - ${options.Name}`],
        ['State', options.State],
        ['Latitude', `${round3Digits(options.lat)} degrees North`],
        ['Longitude', `${Math.abs(round3Digits(options.lon))} degrees West`],
        ['Elevation', `${options.elev} feet`],
        ['Period of Record', `${options.start} - ${options.end}`],
        ['Percent Complete', `${round0Digits(options.omega * 100)}%`],
        ['Non-missing Years', `${options.yor} years`]
      ]
    };
  }

  return { meta, data };
}

export const getCoordinates = async (location, token) => {
  let results = fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${location.replaceAll(' ', '%20')}.json?proximity=-73.39,44.11&limit=1&access_token=${token}`, { method: 'GET' })
    .then(response => response.json())
    .catch(e => {
      console.log(e);
      return false;
    });

  return results;
};

export const getState = async (lat, lon, token) => {
  let results = fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${lon},${lat}.json?types=region&limit=1&access_token=${token}`, { method: 'GET' })
    .then(response => response.json())
    .catch(e => {
      console.log(e);
      return false;
    });

  return results;
};

export const getElevation = async (lat, lon, token) => {
  let results = fetch(`https://api.mapbox.com/v4/mapbox.mapbox-terrain-v2/tilequery/${lon},${lat}.json?layers=contour&limit=50&access_token=${token}`, { method: 'GET' })
    .then(response => response.json())
    .catch(e => {
      console.log(e);
      return false;
    });

  return results;
};

export async function getXprecip(lat, lon, smoothing) {
  const TIMES_LIST = ['5min','10min','15min','30min','60min','120min','1hr','2hr','3hr','6hr','12hr','24hr','48hr','1day','2day','4day','7day','10day'];
  const YEARS_LIST = ['1yr','2yr','5yr','10yr','25yr','50yr','100yr','200yr','500yr'];

  // Initialize output object
  let dataObj = {};
  TIMES_LIST.forEach(time => {
    dataObj[time] = {};
    YEARS_LIST.forEach(year => {
      dataObj[time][year] = [-1,-1,-1];
    });
  });
  
  // Calculate data file location

  // Proper rounding
  // let row = round0Digits((lat - 37) / 0.00833333333333);
  // let col = round0Digits((lon - -83) / 0.00833333333333);

  // Floor rounding as in old tool
  let row = Math.floor((lat - 37) / 0.00833333333333);
  let col = Math.floor((lon - -83) / 0.00833333333333);
  
  // Get Point Data
  let xprecips = await fetchXprecipData(row, col);

  // Fill output with data
  for (let i = 0; i < xprecips.length; i++) {
    let line = xprecips[i];

    for (let j = 0; j < line.length; j++) {
      let column = line[j];

      for (let k = 0; k < column.length; k++) {
        let val = parseFloat(column[k])/1000;
        if (val >= 0) {
          dataObj[TIMES_LIST[j]][YEARS_LIST[i]][k] = val;
        }
      }
    }
  }

  // Smooth if necessary
  if (smoothing === 'Yes') {
    YEARS_LIST.forEach(year => {
      let values = TIMES_LIST.map(time => dataObj[time][year][0]);
      let sum = values.reduce((acc, num) => acc + num, 0);

      if (sum < 0) {
        values = Array(18).fill(-999);
      } else {
        let smoothed = smooth_rf_data([...values.slice(0,6), ...values.slice(8,12)]);
        values = [
          smoothed[0],
          smoothed[1],
          smoothed[2],
          smoothed[3],
          smoothed[4],
          smoothed[5],
          values[6],
          values[7],
          smoothed[6],
          smoothed[7],
          smoothed[8],
          smoothed[9],
          values[12],
          values[13],
          values[14],
          values[15],
          values[16],
          values[17]
        ];
      }

      TIMES_LIST.forEach((time, index) => dataObj[time][year][0] = values[index]);
    });
  }
  

  return dataObj;
}

export function compute_rf_table(duration_precip) {
  let Ratio_1_6 = [0,0.01377,0.02763,0.0416,0.05567,
    0.06983,0.0841,0.09847,0.11293,0.1275,0.14217,0.15693,
    0.1718,0.18677,0.20183,0.217,0.23227,0.24763,0.2631,
    0.27867,0.29433,0.3101,0.32597,0.34193,0.358,0.37417,
    0.39043,0.4068,0.42327,0.43983,0.4565,0.47317,0.48993,
    0.5068,0.52377,0.54083,0.558,0.57527,0.59263,0.6101,
    0.62767,0.64533,0.6631,0.68097,0.69893,0.717,0.73517,
    0.75343,0.7718,0.79027,0.80883,0.8275,0.84627,0.86513,
    0.8841,0.90317,0.92233,0.9416,0.96097,0.98043];
  let Ratio_6_9 = [0,0.02773,0.05587,0.0844,0.11333,
    0.14267,0.1724,0.20253,0.23307,0.264,0.29533,0.32707,
    0.3592,0.39173,0.42467,0.458,0.49133,0.52507,0.5592,
    0.59373,0.62867,0.664,0.69973,0.73587,0.7724,0.80933,
    0.84667,0.8844,0.92253,0.96107];
  let Ratio_9_105 = [0,0.05267,0.10733,0.164,0.22267,0.28333,
    0.346,0.41067,0.47733,0.546,0.61667,0.68933,0.764,
    0.84067,0.91933];
  let Ratio_105_11 = [0,0.18,0.37,0.57,0.78];
  let Ratio_11_115 = [0,0.18,0.37,0.57,0.78];

  duration_precip.unshift(0);

  //Calculate ratios of x / 24 hour precip
  let ratio = [0];
  let j = 1;
  while (j <= 10) {
    ratio[j] = duration_precip[j] / duration_precip[10];
    ++j;
  }

  //Calculate first-stage distribution based on ratios
  let raw_dist = [0];
  j = 1;
  while (j <= 9) {
    raw_dist[j] = 0.5 - (ratio[10-j] / 2.);
    ++j;
  }

  raw_dist[10] = 0.5;
  
  let i = 2;
  j = 11;
  while (j <= 19) {
    raw_dist[j] = 1.0 - raw_dist[j - i];
    i = i + 2;
    ++j;
  }

  //Calculate 24-hr distribution at 0.1 hour time interval
  let dist_24_hour = [0];
  dist_24_hour[1] = 0.0;
  //Time 0.1 - 6.0 hr
  j = 2;
  while (j <= 60) {
    dist_24_hour[j] = Ratio_1_6[j-1] * raw_dist[1];
    ++j;
  }
  dist_24_hour[61] = raw_dist[1];
  //Time 6.1 - 9.0 hr
  j = 62;
  while (j <= 90) {
    dist_24_hour[j] = dist_24_hour[61] + Ratio_6_9[j-61] * (raw_dist[2] - raw_dist[1]);
    ++j;
  }
  dist_24_hour[91] = raw_dist[2];
  //Time 9.1 - 10.5 hr
  j = 92;
  while (j <= 105) {
    dist_24_hour[j] = dist_24_hour[91] + Ratio_9_105[j-91] * (raw_dist[3] - raw_dist[2]);
    ++j;
  }
  dist_24_hour[106] = raw_dist[3];
  //Time 10.6 - 11.0 hr
  j = 107;
  while (j <= 110) {
    dist_24_hour[j] = dist_24_hour[106] + Ratio_105_11[j-106] * (raw_dist[4] - raw_dist[3]);
    ++j;
  }
  dist_24_hour[111] = raw_dist[4];
  //Time 11.1 - 11.5 hr
  j = 112;
  while (j <= 115) {
    dist_24_hour[j] = dist_24_hour[111] + Ratio_11_115[j-111] * (raw_dist[5] - raw_dist[4]);
    ++j;
  }
  dist_24_hour[116] = raw_dist[5];
  //Time 11.6 hr
  dist_24_hour[117] = dist_24_hour[116] + 0.38 * (raw_dist[6] - raw_dist[5]);
  //Time 11.7 hr
  dist_24_hour[118] = dist_24_hour[116] + 0.78 * (raw_dist[6] - raw_dist[5]);
  //Time 11.8 hr
  dist_24_hour[119] = raw_dist[6] + (0.4 * (raw_dist[7] - raw_dist[6]));
  //Time 11.9 hr
  dist_24_hour[120] = raw_dist[7] + (0.6 * (raw_dist[8] - raw_dist[7]));
  //Time 12.0 hr
  dist_24_hour[121] = 0.5;
  //Time 12.1 to 23.9 hours, distribution is symmetrical around 12 hours
  i = 2;
  j = 122;
  while (j <= 240) {
    dist_24_hour[j] = 1.0 - dist_24_hour[j-i];
    i = i + 2;
    ++j;
  }
  //Time 24.0 hr
  dist_24_hour[241] = 1.0;
  
  //Calculate maximum 6 minute ratio from 5-min and 10-min ratios
  let Max_6_min_ratio = ratio[1] + (0.2 * (ratio[2] - ratio[1]));

  //Replace value for 12.0 hours based on having the maximum 6 minute rainfall between 12.0 and 12.1 hours.
  dist_24_hour[121] = dist_24_hour[122] - Max_6_min_ratio;
  
  dist_24_hour.shift();
  return dist_24_hour;
}

export const TYPE_II = [.0000,.0010,.0020,.0030,.0041,.0051,.0062,.0072,.0083,.0094,.0105,.0116,.0127,.0138,.0150,.0161,.0173,.0184,.0196,.0208,.0220,.0232,.0244,.0257,.0269,.0281,.0294,.0306,.0319,.0332,.0345,.0358,.0371,.0384,.0398,.0411,.0425,.0439,.0452,.0466,.0480,.0494,.0508,.0523,.0538,.0553,.0568,.0583,.0598,.0614,.0630,.0646,.0662,.0679,.0696,.0712,.0730,.0747,.0764,.0782,.0800,.0818,.0836,.0855,.0874,.0892,.0912,.0931,.0950,.0970,.0990,.1010,.1030,.1051,.1072,.1093,.1114,.1135,.1156,.1178,.1200,.1222,.1246,.1270,.1296,.1322,.1350,.1379,.1408,.1438,.1470,.1502,.1534,.1566,.1598,.1630,.1663,.1697,.1733,.1771,.1810,.1851,.1895,.1941,.1989,.2040,.2094,.2152,.2214,.2280,.2350,.2427,.2513,.2609,.2715,.2830,.3068,.3544,.4308,.5679,.6630,.6820,.6986,.7130,.7252,.7350,.7434,.7514,.7588,.7656,.7720,.7780,.7836,.7890,.7942,.7990,.8036,.8080,.8122,.8162,.8200,.8237,.8273,.8308,.8342,.8376,.8409,.8442,.8474,.8505,.8535,.8565,.8594,.8622,.8649,.8676,.8702,.8728,.8753,.8777,.8800,.8823,.8845,.8868,.8890,.8912,.8934,.8955,.8976,.8997,.9018,.9038,.9058,.9078,.9097,.9117,.9136,.9155,.9173,.9192,.9210,.9228,.9245,.9263,.9280,.9297,.9313,.9330,.9346,.9362,.9377,.9393,.9408,.9423,.9438,.9452,.9466,.9480,.9493,.9507,.9520,.9533,.9546,.9559,.9572,.9584,.9597,.9610,.9622,.9635,.9647,.9660,.9672,.9685,.9697,.9709,.9722,.9734,.9746,.9758,.9770,.9782,.9794,.9806,.9818,.9829,.9841,.9853,.9864,.9876,.9887,.9899,.9910,.9922,.9933,.9944,.9956,.9967,.9978,.9989,1.000];
export const TYPE_III = [.0000,.0010,.0020,.0030,.0040,.0050,.0060,.0070,.0080,.0090,.0100,.0110,.0120,.0130,.0140,.0150,.0160,.0170,.0180,.0190,.0200,.0210,.0220,.0231,.0241,.0252,.0263,.0274,.0285,.0296,.0308,.0319,.0331,.0343,.0355,.0367,.0379,.0392,.0404,.0417,.0430,.0443,.0456,.0470,.0483,.0497,.0511,.0525,.0539,.0553,.0567,.0582,.0597,.0612,.0627,.0642,.0657,.0673,.0688,.0704,.0720,.0736,.0753,.0770,.0788,.0806,.0825,.0844,.0864,.0884,.0905,.0926,.0948,.0970,.0993,.1016,.1040,.1064,.1089,.1114,.1140,.1167,.1194,.1223,.1253,.1284,.1317,.1350,.1385,.1421,.1458,.1496,.1535,.1575,.1617,.1659,.1703,.1748,.1794,.1842,.1890,.1940,.1993,.2048,.2105,.2165,.2227,.2292,.2359,.2428,.2500,.2578,.2664,.2760,.2866,.2980,.3143,.3394,.3733,.4166,.5000,.5840,.6267,.6606,.6857,.7020,.7134,.7240,.7336,.7422,.7500,.7572,.7641,.7708,.7773,.7835,.7895,.7952,.8007,.8060,.8110,.8158,.8206,.8252,.8297,.8341,.8383,.8425,.8465,.8504,.8543,.8579,.8615,.8650,.8683,.8716,.8747,.8777,.8806,.8833,.8860,.8886,.8911,.8936,.8960,.8984,.9007,.9030,.9052,.9074,.9095,.9116,.9136,.9156,.9175,.9194,.9212,.9230,.9247,.9264,.9280,.9296,.9312,.9327,.9343,.9358,.9373,.9388,.9403,.9418,.9433,.9447,.9461,.9475,.9489,.9503,.9517,.9530,.9544,.9557,.9570,.9583,.9596,.9609,.9621,.9634,.9646,.9658,.9670,.9682,.9694,.9706,.9718,.9729,.9741,.9752,.9764,.9775,.9786,.9797,.9808,.9818,.9829,.9839,.9850,.9860,.9870,.9880,.9890,.9900,.9909,.9919,.9928,.9938,.9947,.9956,.9965,.9974,.9983,.9991,1.000];