import React from 'react';
import PropTypes from 'prop-types';

// Styles in this component are inline to allow for style html downloads
export default function DistributionCurvesTextTbl({ tableData }) {
  return (
    <main style={{marginLeft: '80px'}}>
      {
        tableData && tableData.map(row => {
          return (
            <pre key={row[1]} style={{margin: '1px'}}>
              { row.map(value => value === '' ? '          ' : value + '   ') }
            </pre>
          );
        })
      }
    </main>
  );
}

DistributionCurvesTextTbl.propTypes = {
  tableData: PropTypes.array
};