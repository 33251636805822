import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

// Create style classes for entire component
const useStyles = makeStyles(() => ({
  navCont: {
    width: '952px',
    display: 'flex',
    justifyContent: 'space-evenly',
    zIndex: '1',
    margin: '15px auto 0px auto',
  },
  tabLink: {
    textDecoration: 'none',
    borderRadius: '30px 30px 0px 0px'
  },
  tab: {
    // eslint-disable-next-line quotes
    fontFamily: "'Tahoma', 'Helvetica', 'Arial', sans-serif",
    fontSize: '17px',
    fontWeight: '700',
    color: 'black',
    height: '35px',
    width: '200px',
    borderRadius: '30px 30px 0px 0px',
    backgroundColor: 'rgb(254,220,135)',
    textAlign: 'center',
    paddingTop: '8px',
    boxSizing: 'border-box',
    boxShadow: 'inset 4px 2px 3px 2px rgb(255,255,162), 7px 9px 10px 3px rgba(0,0,0,0.5)',
    '&:hover': {
      color: 'rgb(0,0,153)'
    }
  },
  selectedTab: {
    backgroundColor: 'rgb(227,227,227)',
    color: 'rgb(136, 0, 0)',
    boxShadow: 'inset 4px 2px 3px 2px rgb(255,255,255), 7px 9px 10px 3px rgba(0,0,0,0.5)',
    '&:hover': {
      color: 'rgb(136,0,0)'
    }
  }
}));



export default function Nav({ path, tabs }) {
  const classes = useStyles();

  return (
    <nav className={classes.navCont}>
      {tabs.map((tab) => {
        return (
          <Link className={classes.tabLink} key={tab.name} to={tab.url}>
            <div className={`${classes.tab} ${path === tab.url && classes.selectedTab}`}>{tab.name}</div>
          </Link>
        );
      })}
    </nav>
  );
}

Nav.propTypes = {
  path: PropTypes.string.isRequired,
  tabs: PropTypes.array.isRequired
};