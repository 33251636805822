const countiesByState = {
  'CONNECTICUT': [
    'Fairfield',
    'Hartford',
    'Litchfield',
    'Middlesex',
    'New Haven',
    'New London',
    'Tolland',
    'Windham'
  ],
  'MAINE': [
    'Androscoggin',
    'Aroostook',
    'Cumberland',
    'Franklin',
    'Hancock',
    'Kennebec',
    'Knox',
    'Lincoln',
    'Oxford',
    'Penobscot',
    'Piscataquis',
    'Sagadahoc',
    'Somerset',
    'Waldo',
    'Washington',
    'York'
  ],
  'MASSACHUSETTS': [
    'Barnstable',
    'Berkshire',
    'Bristol',
    'Dukes',
    'Essex',
    'Franklin',
    'Hampden',
    'Hampshire',
    'Middlesex',
    'Nantucket',
    'Norfolk',
    'Plymouth',
    'Suffolk',
    'Worcester',
  ],
  'NEW HAMPSHIRE': [
    'Belknap',
    'Carroll',
    'Cheshire',
    'Coos',
    'Grafton',
    'Hillsborough',
    'Merrimack',
    'Rockingham',
    'Strafford',
    'Sullivan',
  ],
  'NEW YORK': [
    'Albany',
    'Allegany',
    'Broome',
    'Cattaraugus',
    'Cayuga',
    'Chautauqua',
    'Chemung',
    'Chenango',
    'Clinton',
    'Columbia',
    'Cortland',
    'Delaware',
    'Dutchess',
    'Erie',
    'Essex',
    'Franklin',
    'Fulton',
    'Genesee',
    'Greene',
    'Hamilton',
    'Herkimer',
    'Jefferson',
    'Lewis',
    'Livingston',
    'Madison',
    'Monroe',
    'Montgomery',
    'Nassau',
    'New York City',
    'Niagara',
    'Oneida',
    'Onondaga',
    'Ontario',
    'Orange',
    'Orleans',
    'Oswego',
    'Otsego',
    'Putnam',
    'Rensselaer',
    'Rockland',
    'Saratoga',
    'Schenectady',
    'Schoharie',
    'Schuyler',
    'Seneca',
    'St. Lawrence',
    'Steuben',
    'Suffolk',
    'Sullivan',
    'Tioga',
    'Tompkins',
    'Ulster',
    'Warren',
    'Washington',
    'Wayne',
    'Westchester',
    'Wyoming',
    'Yates',
  ],
  'RHODE ISLAND': [
    'Bristol',
    'Kent',
    'Newport',
    'Providence',
    'Washington',
  ],
  'VERMONT': [
    'Addison',
    'Bennington',
    'Caledonia',
    'Chittenden',
    'Essex',
    'Franklin',
    'Grand Isle',
    'Lamoille',
    'Orange',
    'Orleans',
    'Rutland',
    'Washington',
    'Windham',
    'Windsor',
  ]
};

export default countiesByState;