import React from 'react';
import PropTypes from 'prop-types';

import {
  List,
  ListItem,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { makeStyles, styled } from '@material-ui/core/styles';

import exporting from 'highcharts/modules/exporting';
import Highcharts from 'highcharts';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more'; 
import HC_offline_exporting from 'highcharts/modules/offline-exporting';
highchartsMore(Highcharts); 
exporting(Highcharts);
HC_offline_exporting(Highcharts);
NoDataToDisplay(Highcharts);

import { round3Digits, round4Digits } from '../HelperScripts/rounding';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 12,
    padding: '0px',
    lineHeight: '12px',
    boxSizing: 'border-box',
    fontFamily: '"monospace", "sans-serif"',
    borderBottom: '3px double black',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    border: 'none',
    // borderTop: '1px solid rgb(128,128,128)',
    color: theme.palette.common.black,
    padding: '2px',
    lineHeight:'12px',
    boxSizing: 'border-box',
    fontFamily: '"monospace", "sans-serif"',
  },
}));

// Create style classes for entire component
const useStyles = makeStyles(() => ({
  contentCont: {
    width: 'fit-content !important',
    marginLeft: '80px'
  },
  li: {
    padding: '2px 0px !important'
  },
  ul: {
    padding: '6px 0px !important'
  }
}));



export default function DistributionCurvesGraph({ options, graphData }) {
  const classes = useStyles();
  
  const renderChart = () => {
    let chartTitle = 'Precipitation Distribution';
    let subtitle = `(${round3Digits(options.lat)}N, ${round3Digits(options.lon)}W) - ${graphData.table2 ? `${graphData.title1}/${graphData.title2}` : graphData.title1} - ${options.Smoothing === 'Yes' ? 'Smoothed' : 'Unsmoothed'}`;
    let series1 = [];
    let series2 = [];

    for (let i = 0; i < graphData.table1.length; i++) {
      series1.push([i/10, graphData.table1[i]]);

      if (graphData.table2) {
        series2.push([i/10, graphData.table2[i]]);
      }
    }
    
    let chartOptions =  {
      exporting:{
        fallbackToExportServer: false,
        enabled: true,
        buttons: {
          contextButton: {
            menuItems: ['viewFullscreen', 'printChart', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadSVG']
          }
        }
      },

      credits: {enabled: false},

      chart: {
        type: 'line',
        height: 600,
        width: 600,
        backgroundColor: 'rgb(255,255,255)',
        plotBorderWidth: 1,
        plotBorderColor: 'black',
        events: {
          load: function() {
            this.renderer
              .image((process.env.PUBLIC_URL + '/assets/Logos/logo_acis.png'),485,482,90,40)
              .on('click', function() {
                location.href = 'http://www.rcc-acis.org';
              })
              .attr({zIndex: 3})
              .css({cursor: 'pointer'})
              .add();
          }
        }
      },

      plotOptions: {
        line: {
          lineWidth: 1
        },
        series: {
          states: {
            inactive: {
              opacity: 1
            }
          },
        }
      },

      legend: {
        align: 'left',
        verticalAlign: 'top',
        layout: 'vertical',
        backgroundColor: 'white',
        floating: true,
        x: 53,
        y: 54,
        borderColor: 'black',
        borderWidth: 1,
        padding: 10
      },

      title: {
        text: chartTitle,
        x: 40
      },

      subtitle: {
        text: subtitle,
        style: {
          color: '#333333',
          fontSize: '18px'
        },
        x: 40
      },

      series: [
        {
          data: series1,
          name: graphData.title1,
          color: 'blue',
          marker: {
            enabled: false
          }
        }
      ],

      xAxis: {
        title: {
          text: 'Duration (hours)'
        },
        showFirstLabel: false,
        showLastLabel: false,
        min: -1,
        max: 25,
        tickInterval: 1,
        gridLineDashStyle: 'ShortDot',
        gridLineColor: 'rgb(80,80,80)',
        gridLineWidth: 1,
        labels: {
          step: 1,
          style: {
            color: 'black'
          }
        },
        lineColor: 'black',
        tickColor: 'black',
      },

      yAxis: {
        title: {
          text: 'Dimensionless Accumulation'
        },
        startOnTick: false,
        endOnTick: false,
        min: -0.05,
        max: 1.05,
        tickInterval: 0.1,
        tickWidth: 1,
        tickColor: 'black',
        gridLineDashStyle: 'ShortDot',
        gridLineColor: 'rgb(80,80,80)',
        gridLineWidth: 1,
        labels: {
          step: 1,
          style: {
            color: 'black'
          },
          format: '{value:.1f}'
        },
        lineColor: 'black',
      },

      tooltip: {
        formatter: function () {
          return this.points.reduce(function (acc, point) {
            if (point.color === 'blue') {
              return `<b>${point.x} hours</b><hr/><b>${point.series.name}: </b>${round4Digits(point.y)}<br/>${acc}`;
            } else {
              return `${acc}<b>${point.series.name}: </b>${round4Digits(point.y)}`;
            }
          }, '');
        },
        backgroundColor: '#FFFFFF',
        shared: true,
        useHTML: true,
        outside: true
      }
    };

    if (graphData.table2) {
      chartOptions.series.push({
        data: series2,
        name: graphData.title2,
        color: 'red',
        marker: {
          enabled: false
        }
      });
    }

    return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
  };

  const renderTable = () => {
    let timeArr = graphData.table1.map((_, i) => i/10);
    let headers = ['Time (hours)', `${graphData.title1} Accumulation (dimensionless)`];
    if (graphData.table2) {
      headers.push(`${graphData.title2} ${graphData.title2.includes('Type') ? '' :'Accumulation'} (dimensionless)`);
    }

    
    return (
      <TableContainer className={classes.contentCont}>
        <Table size='small'>
          <TableHead>
            {
              headers.map((colHead, index) => {
                let width = '100px';
                
                if (index === 1) {
                  width = '165px';
                }
                
                if (index === headers.length - 1) {
                  width = 'fit-content';
                }

                return <StyledTableCell key={colHead} style={{ width: width }}>{
                  <List className={classes.ul}>
                    <ListItem className={classes.li}>{colHead.split('(')[0]}</ListItem>
                    <ListItem className={classes.li}>({colHead.split('(')[1]}</ListItem>
                  </List>  
                }</StyledTableCell>;
              })
            }
          </TableHead>
          <TableBody>
            {
              timeArr.map((time, i) => {
                return (
                  <TableRow key={time}>
                    <StyledTableCell>{time.toFixed(1)}</StyledTableCell>
                    <StyledTableCell>{round4Digits(graphData.table1[i])}</StyledTableCell>
                    <StyledTableCell>{graphData.table2 && round4Digits(graphData.table2[i])}</StyledTableCell>
                  </TableRow>
                );
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
    );
  };
  
  return (
    <main>
      <div>
        {Object.keys(graphData).length > 0 && renderChart()}
      </div>

      <div>
        {Object.keys(graphData).length > 0 && renderTable()}
      </div>
    </main>
  );
}

DistributionCurvesGraph.propTypes = {
  options: PropTypes.object,
  graphData: PropTypes.object,
};