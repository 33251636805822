import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactMapGL, {
  NavigationControl,
  Marker,
  FlyToInterpolator
} from 'react-map-gl';

import RoomIcon from '@mui/icons-material/Room';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import QuestionMark from './QuestionMark';

import productInfoList from '../Assets/products';
import countiesByState from '../Assets/counties';

import { getState, getCoordinates, getElevation } from '../HelperScripts/getData';


// Loading error fix
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
// eslint-disable-next-line no-undef
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;


// Create style classes for entire component
const useStyles = makeStyles(() => ({
  dataCont: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: '922px',
    height: '602px',
    padding: '10px',
    backgroundColor: '#eeeeee',
    border: '1px solid black',
    boxShadow: '3px 4px 3px 1px rgba(0,0,0,0.7)',
    margin: '0 auto',
    zIndex: '2'
  },
  mapCont: {
    width: '700px',
    height: '420px',
    border: '1px solid black'
  },
  mapNav: {
    position: 'absolute',
    bottom: '25px',
    right: '5px'
  },
  italicized: {
    color: 'black',
    fontSize: '15px',
    fontWeight: 'normal',
    fontStyle: 'italic'
  },
  selectText: {
    color: '#aa0000',
    fontWeight: 'bold',
    // eslint-disable-next-line quotes
    fontFamily: "'Tahoma', 'Helvetica', 'Arial', sans-serif",
    fontSize: '16px'
  },
  selectProduct: {
    fontSize: '15px',
  },
  selectProductCont: {
    height: '20px',
    padding: '2px 0px'
  },
  mapTitle: {
    display: 'flex',
    marginBottom: '5px'
  },
  productsCont: {
    boxSizing: 'border-box',
    padding: '2px',
    flexDirection: 'column',
    width: '160px',
    height: '502px',
    backgroundColor: 'rgb(170,204,255)',
    textAlign: 'center',
    border: '1px solid black',
  },
  productItem: {
    boxSizing: 'border-box',
    height: '50px',
    padding: '7px 0px',
    color: 'rgb(0,0,170)',
    borderTop: '1px solid rgb(150,150,150)',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgb(255,255,221)'
    }
  },
  qMark: {
    position: 'relative',
    bottom: 0,
    left: 75
  },
  productText: {
    // eslint-disable-next-line quotes
    fontFamily: "'Tahoma', 'Helvetica', 'Arial', sans-serif",
    fontWeight: '700',
    fontSize: '12px',
    lineHeight: '16px'
  },
  halfsize: {
    height: '36px'
  },
  selected: {
    backgroundColor: 'rgb(221,255,255)',
    '&:hover': {
      cursor: 'default',
      backgroundColor: 'rgb(221,255,255)',
    }
  },
  optionsCont: {
    boxSizing: 'border-box',
    height: '135px',
    width: '702px',
    padding: '5px 0px 10px 0px'
  },
  optionsText: {
    display: 'flex',
    marginBottom: '5px'
  },
  submitCont: {
    margin: '0 auto',
    width: 'fit-content',
  },
  submit: {
    width: '300px',
    fontSize: '24px',
    fontWeight: 'bold',
    marginTop: '15px'
  },
  ready: {
    backgroundColor: '#aaffaa',
    color: 'black',
    border: '2px solid rgb(118,118,118)',
    borderRightColor: 'rgb(0,0,0)',
    borderBottomColor: 'rgb(0,0,0)',
    '&:hover': {
      cursor: 'pointer'
    }
  }, 
  notReady: {
    backgroundColor: 'rgb(255,170,170)',
  },
  hidden: {
    display: 'none'
  },
  inputs: {
    display: 'flex',
    width: '702px',
    justifyContent: 'space-evenly'
  },
  optionBox: {
    border: '1px solid black'
  },
  optionLabelCont: {
    borderBottom: '1px solid rgb(110,110,110)',
    backgroundColor: 'rgb(255,255,204)',
    display: 'flex',
    height: '20px',
    boxSizing: 'border-box',
    paddingTop: '4px',
    justifyContent: 'center'
  },
  optionLabel: {
    color: '#000066',
    fontSize: '13px',
    fontWeight: '700',
  },
  optionSelectCont: {
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#eeeeee'
  },
  selector: {
    height: '20px',
    marginTop: '4px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  textBox: {
    height: '16px',
    margin: '4px auto 0px auto',
    width: '100px'
  },
  inputsOnly: {
    marginBottom: '25px'
  },
  mapTop: {
    position: 'absolute',
    top: '27px',
    right: '23px',
    gap: '3px',
    display: 'flex',
  },
  mapTopInput: {
    width: '180px',
  },
  mapTopTextBox: {
    height: '19px',
    width: '44px',
    boxSizing: 'border-box',
    marginTop: '4px',
  },
  mapTopSelectCont: {
    fontSize: '13px',
    fontFamily: 'Arial',
    fontWeight: 'normal',
    lineHeight: '26px'
  },
  longText: {
    width: '149px'
  },
  mapTopSubmit: {
    width: '18px',
    height: '19px',
    padding: '0px',
    marginTop: '4px',
    marginLeft: '6px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  main: {
    position: 'relative',
    zIndex: '1',
    padding: '0 17px'
  },
  styleSelector: {
    position: 'absolute',
    top: '27px',
    left: '4px',
  },
  styleBtn: {
    border: 'none',
    width: '70px',
    height: '24.5px',
    boxSizing: 'border-box',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgb(228,228,228)'
    }
  },
  topLeft: {
    borderRight: '1px solid rgb(180,180,180)'
  },
  bottomLeft: {
    borderRight: '1px solid rgb(180,180,180)'
  },
  styleBtnRowTop: {
    boxSizing: 'border-box',
    display: 'flex',
    width: '139px',
    border: '1px solid black',
    borderBottom: '1px solid rgb(180,180,180)'
  },
  styleBtnRowBottom: {
    boxSizing: 'border-box',
    display: 'flex',
    width: '139px',
    border: '1px solid black',
    borderTop: 'none'
  },
  selectedStyle: {
    backgroundColor: 'rgb(220,220,220)',
    boxShadow: 'inset 1px 1px 1px 1px rgba(0,0,0,0.15)',
    '&:hover': {
      cursor: 'default',
      backgroundColor: 'rgb(220,220,220)'
    }
  },
  marker: {
    color: 'red',
    fontSize: '30px !important'
  }
}));



export default function Data({ token, STATE_CODES, setOptions, blankOptions, setMeta }) {
  const [submitReady, setSubmitReady] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(productInfoList[0]);
  const [bottomOptions, setBottomOptions] = useState(productInfoList[0].menus.options.reduce((acc, opt) => {
    acc[opt.name] = opt?.selected ? opt.selected : opt.options[0];
    return acc;
  }, {}));
  const [mapStyle, setMapStyle] = useState('mapbox://styles/mapbox/satellite-streets-v11');
  const [selectedStyle, setSelectedStyle] = useState('Hybrid');
  const [marker, setMarker] = useState(false);
  const [address, setAddress] = useState('');
  const [lat, setLat] = useState('');
  const [lon, setLon] = useState('');
  const [location, setLocation] = useState(false);
  const [stateName, setStateName] = useState('');
  const [stateAbbr, setStateAbbr] = useState('');
  const [stateCounty, setStateCounty] = useState('');
  const [placeName, setPlaceName] = useState('');
  const [topOptions, setTopOptions] = useState({
    'State': '',
    'Station ID': '',
    'Name': '',
    'Data Type': 'Return Period Estimates',
    'Region/State': ''
  });
  const [viewport, setViewport] = useState({
    latitude: 44.11,
    longitude: -73.39,
    zoom: 4.9,
    minZoom: 4.9,
  });

  const classes = useStyles();

  useEffect(() => {
    if (location) {
      setViewport({
        ...viewport,
        latitude: location[0],
        longitude: location[1],
        transitionInterpolator: new FlyToInterpolator(),
        transitionDuration: 1500
      });
    }
  }, [location]);

  useEffect(() => {
    let name = selectedProduct.name;
    if (name === 'GIS Data Files') {
      setSubmitReady(true);
    } else if (name === 'Partial Duration Series - by Station'){
      setSubmitReady(topOptions['State'] !== '' || topOptions['Station ID'] !== '' || topOptions['Name'] !== '');
    } else if (name === 'Regional/State Maps') {
      setSubmitReady(topOptions['Region/State'] === '' ? false : true);
    } else {
      setSubmitReady(location ? true : false);
      setTimeout(() => {
        setMarker(renderMarker());
      }, 0);
    }
  }, [selectedProduct, topOptions, location]);

  useEffect(() => {
    setBottomOptions(selectedProduct.menus.options.reduce((acc, opt) => {
      acc[opt.name] = opt?.selected ? opt.selected : opt.options[0];
      return acc;
    }, {}));
  }, [selectedProduct]);


  const handleDataRequest = (e) => {
    e.preventDefault();
    
    let name = selectedProduct.name;
    if (
      name === 'GIS Data Files' ||
      name === 'Partial Duration Series - by Station' ||
      name === 'Regional/State Maps'
    ) {

      setOptions({
        ...blankOptions,
        ...topOptions,
        ...bottomOptions,
        'productName': name,
      });

      if (name === 'Partial Duration Series - by Station') {
        setMeta(prev => !prev);
      }

      localStorage.setItem('trigger', 'true');
    } else {
      getElevation(lat, lon, token)
        .then(results => {
          let elevations = results.features.map(feature => feature.properties.ele);
          let newOptions = {
            ...blankOptions,
            ...topOptions,
            ...bottomOptions,
            'productName': name,
            'lat': lat,
            'lon': lon,
            'stateAbbr': stateAbbr,
            'elev': Math.max(...elevations),
            'State': stateName,
            'Name': placeName,
            'point': true,
          };

          if (name === 'Extreme Precipitation Tables - Text/CSV') {
            newOptions['Delivery'] === 'Download';
          }

          setOptions(newOptions);
          localStorage.setItem('trigger', 'true');
        });
    }
  };

  const checkBounds = (lt, ln) => {
    return (lt >= 40 && lt <= 49 && ln >= -81 && ln <= -66 );
  };

  const handleDblClick = (event) => {
    let coords = event.lngLat;
    
    if (checkBounds(coords[1], coords[0])) {
      getState(coords[1], coords[0], token)
        .then(results => {
          try {
            let region = results.features[0];
            setLat(coords[1]);
            setLon(coords[0]);
            setLocation([coords[1], coords[0]]);
            setAddress('');
            setStateCounty('');
            setStateName(region.text);
            setStateAbbr(region.properties.short_code.slice(3));
            setPlaceName(region.place_name);
          } catch {
            alert('ERROR: An error occurred while attempting to retrieve the state name for the selected location. Please try again later.');
          }
        });
    } else {
      alert('Please limit selection areas to be within the study area.');
    }
  };

  const handleAddressSubmit = () => {
    if (address === '') {
      setLocation(false);
      setLat('');
      setLon('');
      setStateCounty('');
    } else {
      getCoordinates(address, token)
        .then(results => {
          try {
            let latitude = results.features[0].center[1];
            let longitude = results.features[0].center[0];
    
            if (checkBounds(latitude, longitude)) {
              setAddress(results.features[0].place_name);
              setLat(latitude);
              setLon(longitude);
              setLocation([latitude, longitude]);
              setStateCounty('');
            } else {
              alert('The address that was found is outside of the study area. Please enter a different address.');
            }
          } catch {
            alert('ERROR: An error occurred while attempting to retrieve the coordinates for the given address. Please ensure that the address is well formed and that it resides within the study area.');
          }
        });
    }
  };

  const handleLatLonSubmit = () => {
    if (lat === '' || lon === '') {
      setLocation(false);
    } else if (checkBounds(lat, lon)) {
      getState(lat, lon, token)
        .then(results => {
          try {
            let region = results.features[0];
            setAddress('');
            setStateCounty('');
            setLocation([parseFloat(lat), parseFloat(lon)]);
            setStateName(region.text);
            setStateAbbr(region.properties.short_code.slice(3));
            setPlaceName(region.place_name);
          } catch {
            alert('ERROR: An error occurred while attempting to retrieve the state name for the selected location. Please try again later.');
          }
        });
    } else {
      alert('The coordinates provided are outside of the study area. Please enter different coordinates.');
    }
  };

  const handleStateCountyChange = (value) => {
    let newLat, newLon;
    if (value === 'CONNECTICUT') {
      newLat = 41.67;
      newLon = -72.61;
    } else if (value === 'MAINE') {
      newLat = 45.26;
      newLon = -68.97;
    } else if (value === 'MASSACHUSETTS') {
      newLat = 42.21;
      newLon = -71.74;
    } else if (value === 'NEW HAMPSHIRE') {
      newLat = 43.96;
      newLon = -71.35;
    } else if (value === 'NEW YORK') {
      newLat = 42.78;
      newLon = -75.69;
    } else if (value === 'RHODE ISLAND') {
      newLat = 41.69;
      newLon = -71.51;
    } else if (value === 'VERMONT') {
      newLat = 43.90;
      newLon = -73.01;
    } else {
      getCoordinates(value, token)
        .then(results => {
          try {
            let region = results.features[0].context.find(obj => obj.id.includes('region'));
            let coordinates = results.features[0].center;
            setStateAbbr(region.short_code.slice(3));
            setStateName(region.text);
            setLat(coordinates[1]);
            setLon(coordinates[0]);
            setLocation([coordinates[1], coordinates[0]]);
            setAddress('');
            setPlaceName(results.features[0].place_name);
            setStateCounty(value);
          } catch {
            alert('ERROR: An error occurred while attempting to retrieve the coordinates for the selected county. Please try again later.');
          }
        });
      return;
    }
    
    let stCode = STATE_CODES[Object.keys(STATE_CODES).find(code => STATE_CODES[code][1].toUpperCase() === value)];
    setStateAbbr(stCode[0]);
    setStateName(stCode[1]);
    setLat(newLat);
    setLon(newLon);
    setLocation([newLat, newLon]);
    setAddress('');
    setPlaceName('');
    setStateCounty(value);
  };
  
  const handlePanning = (view) => {
    let nextView = view;

    if (nextView.latitude > 49 || nextView.latitude < 40) {
      nextView.latitude = viewport.latitude;
    }

    if (nextView.longitude > -66 || nextView.longitude < -81) {
      nextView.longitude = viewport.longitude;
    }

    setViewport(nextView);
  };

  const handleStyleSelect = (name, style) => {
    setSelectedStyle(name);
    setMapStyle(style);
  };

  const renderTopOfForm = () => {
    let extraObj = selectedProduct.menus.extra;
    let numOptions = extraObj.options.length;

    
    let width = '230px';
    if (numOptions === 3) {
      width = '182px';
    } else if (numOptions === 1) {
      width = '382px';
    }
    
    return (
      <>
        <div className={classes.optionsText}>
          <Typography className={classes.selectText}>Select {extraObj.name}</Typography>
          <QuestionMark value={`Select ${extraObj.name}`} />
        </div>

        <div className={`${classes.inputs} ${!selectedProduct.mapNeeded && classes.inputsOnly}`}>
          {
            extraObj.options.map(option => {
              return (
                <div key={option.name} className={classes.optionBox} style={{ width }}>
                  <div className={classes.optionLabelCont}>
                    <Typography className={classes.optionLabel}>Search by {option.name}</Typography>
                    <QuestionMark value={`Search by ${option.name}`} />
                  </div>
                  <div className={classes.optionSelectCont}>
                    
                    {
                      option.options.length === 0 ? 
                        <input
                          type='text'
                          className={classes.textBox}
                          value={topOptions[option.name]}
                          onChange={() => setTopOptions({
                            ...topOptions,
                            [option.name]: event.target.value
                          })}>
                        </input>
                        : 
                        <select
                          className={classes.selector}
                          name={option.name}
                          value={topOptions[option.name]}
                          onChange={() => setTopOptions({
                            ...topOptions,
                            [option.name]: event.target.value
                          })}
                        >
                          {
                            option.options.map(item => <option key={item} value={item}>{item}</option>)
                          }
                        </select>
                    }
                    
                  </div>
                </div>
              );
            })
          }
        </div>
      </>
    );
  };

  const renderBottomOfForm = () => {
    return (
      <>
        <div className={classes.optionsText}>
          <Typography className={classes.selectText}>Select Options</Typography>
          <QuestionMark value={'Select Options'} />
        </div>
        <div className={`${classes.inputs} ${!selectedProduct.mapNeeded && classes.inputsOnly}`}>
          {
            selectedProduct.menus.options.map((option) => {
              // let defaultOption = option?.selected;
              let width = '230px';
              let numOptions = selectedProduct.menus.options.length;
              if (numOptions === 3) {
                width = '182px';
              } else if (numOptions === 1) {
                width = '382px';
              }
      
              return (
                <div key={option.name} className={classes.optionBox} style={{ width }}>
                  <div className={classes.optionLabelCont}>
                    <Typography className={classes.optionLabel}>{option.name}</Typography>
                    <QuestionMark value={option.name} />
                  </div>
                  <div className={classes.optionSelectCont}>
                    <select
                      className={classes.selector}
                      name={option.name}
                      value={bottomOptions[option.name]}
                      onChange={(event) => setBottomOptions(prev => {
                        return {
                          ...prev,
                          [event.target.name]: event.target.value
                        };
                      })}
                    >
                      {
                        option.options.map(item => item === 'blank' ? <option key={item} value=''></option> : <option key={item} value={item}>{item}</option>)
                      }
                    </select>
                  </div>
                </div>
              );
            })
          }
        </div>
      </>
    );
  };

  const renderMapTopOptions = () => {
    return (
      <div className={classes.mapTop}>
        <div className={`${classes.optionBox} ${classes.mapTopInput}`}>
          <div className={classes.optionLabelCont}>
            <Typography className={classes.optionLabel}>Locate by Address</Typography>
            <QuestionMark value={'Locate by Address'} />
          </div>
          <div className={`${classes.optionSelectCont} ${classes.mapTopSelectCont}`}>
            <input
              name='address'
              value={address}
              onChange={(event) => setAddress(event.target.value)}
              type='text'
              className={`${classes.mapTopTextBox} ${classes.longText}`}
            ></input>
            <button onClick={handleAddressSubmit} className={classes.mapTopSubmit}>&rarr;</button>
          </div>
        </div>
        
        <div className={`${classes.optionBox} ${classes.mapTopInput}`}>
          <div className={classes.optionLabelCont}>
            <Typography className={classes.optionLabel}>Locate by Lat/Lon</Typography>
            <QuestionMark value={'Locate by Lat/Lon'} />
          </div>
          <div className={`${classes.optionSelectCont} ${classes.mapTopSelectCont}`}>
            <input
              name='lat'
              value={lat}
              onChange={(event) => setLat(event.target.value)}
              type='text'
              className={classes.mapTopTextBox}
            ></input>&deg;N
            <input
              name='lon'
              value={lon}
              onChange={(event) => setLon(event.target.value)}
              type='text'
              className={classes.mapTopTextBox}
              style={{ marginLeft: '6px' }}
            ></input>&deg;W
            <button onClick={handleLatLonSubmit} className={classes.mapTopSubmit}>&rarr;</button>
          </div>
        </div>
        
        <div className={`${classes.optionBox} ${classes.mapTopInput}`}>
          <div className={classes.optionLabelCont}>
            <Typography className={classes.optionLabel}>Locate by State/County</Typography>
            <QuestionMark value={'Locate by State/County'} />
          </div>
          <div className={`${classes.optionSelectCont} ${classes.mapTopSelectCont}`}>
            <select
              name='stateCounty'
              value={stateCounty}
              onChange={(event) => handleStateCountyChange(event.target.value)}
              className={classes.selector}
            >
              <option value=''></option>
              {
                Object.keys(countiesByState).map((state, index) => {
                  let stateCounties = countiesByState[state].map(county => <option key={county} value={`${county} ${state}`}>{county}</option>);
                  stateCounties.unshift(<option key={state} value={state}>{state}</option>);
                  stateCounties.unshift(<option key={index} value='' disabled></option>);
                  return stateCounties;
                })
              }
            </select>
          </div>
        </div>
      </div>
    );
  };

  const renderMarker = () => {
    if (location) {
      return (
        <Marker
          latitude={location[0]}
          longitude={location[1]}
          offsetLeft={-15}
          offsetTop={-29}
        >
          <RoomIcon className={classes.marker} />
        </Marker>
      );
    } else {
      return false;
    }
  };


  return (
    <main className={classes.main}>
      <div className={classes.dataCont}>
        <div className={classes.productsCont}>
          <div className={classes.selectProductCont}>
            <Typography className={`${classes.selectText} ${classes.selectProduct}`}>Select Product <QuestionMark value={'Select Product'} /></Typography>
          </div>
          {
            productInfoList.map(product => {
              return (
                <div
                  key={product.name}
                  className={`${classes.productItem} ${product.size === 'half' && classes.halfsize} ${selectedProduct.name === product.name && classes.selected}`}
                  onClick={() => setSelectedProduct(product)}
                >
                  <Typography className={classes.productText}>{product.name}</Typography>
                  <div className={classes.qMark}>
                    <QuestionMark value={product.name} />
                  </div>
                </div>
              );
            })
          }
        </div>
      
        <div style={{ position: 'relative' }}>
          <div className={`${classes.mapTitle} ${!selectedProduct.mapNeeded && classes.hidden}`}>
            <Typography className={classes.selectText}>Select Location</Typography>
            <QuestionMark value={'Select Location'} />
            <Typography className={classes.italicized}>Double-click map to place a marker, or enter address or latitude/longitude.</Typography>
          </div>
      
          <div className={`${classes.mapCont} ${!selectedProduct.mapNeeded && classes.hidden}`}>
            <ReactMapGL
              {...viewport}
              width= "100%"
              height= "100%"
              onViewportChange={nextViewport => handlePanning(nextViewport)}
              mapboxApiAccessToken={token}
              mapStyle={mapStyle}
              dragRotate={false}
              doubleClickZoom={false}
              onDblClick={handleDblClick}
            >
              {marker}

              <NavigationControl
                className={classes.mapNav}
                showCompass={false}
              />
            </ReactMapGL>

            <div className={classes.styleSelector}>
              <div className={classes.styleBtnRowTop}>
                <button className={`${classes.styleBtn} ${classes.topLeft} ${selectedStyle === 'Hybrid' && classes.selectedStyle}`} onClick={() => handleStyleSelect('Hybrid', 'mapbox://styles/mapbox/satellite-streets-v11')}>Hybrid</button>
                <button className={`${classes.styleBtn} ${selectedStyle === 'Map' && classes.selectedStyle}`} onClick={() => handleStyleSelect('Map', 'mapbox://styles/mapbox/streets-v11')}>Map</button>
              </div>
              <div className={classes.styleBtnRowBottom}>
                <button className={`${classes.styleBtn} ${classes.bottomLeft} ${selectedStyle === 'Satellite' && classes.selectedStyle}`} onClick={() => handleStyleSelect('Satellite', 'mapbox://styles/mapbox/satellite-v9')}>Satellite</button>
                <button className={`${classes.styleBtn} ${selectedStyle === 'Terrain' && classes.selectedStyle}`} onClick={() => handleStyleSelect('Terrain', 'mapbox://styles/mapbox/outdoors-v11')}>Terrain</button>
              </div>
            </div>
          </div>

          {selectedProduct.mapNeeded && renderMapTopOptions()}
      
          <div className={classes.optionsCont}>
            <form>
              {!selectedProduct.mapNeeded && renderTopOfForm()}
              {renderBottomOfForm()}
              <div className={classes.submitCont}>
                <button
                  className={`${classes.submit} ${submitReady ? classes.ready : classes.notReady}`}
                  onClick={handleDataRequest}
                  disabled={!submitReady}
                >
                  Submit
                </button>
                <QuestionMark value={'Submit'} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
}

Data.propTypes = {
  token: PropTypes.string.isRequired,
  STATE_CODES: PropTypes.object.isRequired,
  setOptions: PropTypes.func.isRequired,
  blankOptions: PropTypes.object.isRequired,
  setMeta: PropTypes.func
};