/* eslint-disable no-undef */
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const buildSection = (classes, itemType, section, sectionName, areLinks = true) => {
  return (
    <Fragment key={sectionName}>
      <h2 className={classes.sectionHeading}>{sectionName}</h2>
      <ul className={classes.list}>
        {
          section.map(name => {
            let href = name.replaceAll(' ', '_').replaceAll('/', '&');

            if (name === 'Latitude-Longitude Conversion Tool') {
              return <li className={classes.li}><a href='https://www.fcc.gov/media/radio/dms-decimal' target='_blank' rel='noreferrer' className={classes.link}>{name}</a></li>;
            } else if (name === 'Google Maps') {
              return <li className={classes.li}><a href='https://www.google.com/maps' target='_blank' rel='noreferrer' className={classes.link}>{name}</a></li>;
            } else if (name === 'MapBox Documentation') {
              return <li className={classes.li}><a href='https://docs.mapbox.com/' target='_blank' rel='noreferrer' className={classes.link}>{name}</a></li>;
            } else if (name === 'Technical Manual') {
              return <li className={classes.li}><a href='/docs/xprecip_techdoc.pdf' target='_blank' rel='noreferrer' className={classes.link}>{name}</a></li>;
            } else {
              if (areLinks) {
                return <li key={name} className={classes.li}><Link to={href} className={itemType}>{name}</Link></li>;
              } else {
                return <li key={name} className={classes.li}><span to={href} className={itemType}>{name}</span></li>;
              }
            }
          })
        }
      </ul>
    </Fragment>
  );
};

export const generateManualLists = (obj, classes) => {
  // Multi 'if' statements to ensure order of lists
  let els = [];
  if (obj?.options) {
    els.push(buildSection(classes, classes.nonLinkItem, obj.options, 'Options', false));
  }

  if (obj?.linkedOptions) {
    els.push(buildSection(classes, classes.link, obj.linkedOptions, 'Options'));
  }

  if (obj?.products) {
    els.push(buildSection(classes, classes.link, obj.products, 'Products'));
  }

  if (obj?.seeAlso) {
    els.push(buildSection(classes, classes.link, obj.seeAlso, 'See Also'));
  }

  if (obj?.addresses) {
    return (
      <>
        <h3 className={classes.heading}>Sample address searches:</h3>
        <ul className={classes.list}>
          <li className={classes.li}><span className={classes.nonLinkItem}>Cornell University, Ithaca, NY 14850</span></li>
          <li className={classes.li}><span className={classes.nonLinkItem}>306 Tower Rd, Ithaca, NY</span></li>
          <li className={classes.li}><span className={classes.nonLinkItem}>306 Tower Rd, 14850</span></li>
          <li className={classes.li}><span className={classes.nonLinkItem}>Ithaca, NY</span></li>
          <li className={classes.li}><span className={classes.nonLinkItem}>Tompkins County, NY</span></li>
          <li className={classes.li}><span className={classes.nonLinkItem}>NY</span></li>
          <li className={classes.li}><span className={classes.nonLinkItem}>New York</span></li>
          <li className={classes.li}><span className={classes.nonLinkItem}>14850</span></li>
        </ul>
      </>
    );
  }

  return els;
};

export const generateManualParagraph = (text, classes) => {
  return <p className={classes.text}>{text}</p>;
};

export const generateManualImage = (imgFileName, width, heading, classes) => {
  return (
    <>
      <h3 className={classes.heading}>{heading}</h3>
      <img src={process.env.PUBLIC_URL + '/assets/Manual/' + imgFileName} className={classes.img} style={{ width: width }}></img>
    </>
  );
};