import React from 'react';
import PropTypes from 'prop-types';

import { round2Digits } from '../HelperScripts/rounding';



// Styles in this component are inline to allow for style html downloads
export default function PdsResults({ options, pds, meta }) {
  if (options && pds) {
    try {
      return (
        <main>
          <h1 style={{fontSize: '32px', fontWeight: '700', margin: '5px 0px'}}>{options.product || 'Partial Duration Series'}</h1>
          <h2 style={{fontSize: '24px', fontWeight: '700', margin: '5px 0px'}}>Northeast Regional Climate Center</h2>
          <h4 style={{fontSize: '16px', fontWeight: '700', fontStyle: 'italic', margin: '5px 0px'}}>Data represents point estimates calculated from partial duration series. All precipitation amounts are displayed in inches.</h4>

          {meta['point'] && 
            <div style={{border: '3px double black', boxSizing: 'border-box', width: '605px', margin: '10px 20px 20px 20px'}}>
              <h3 style={{fontSize: '19px', fontWeight: '700', margin: '10px 0px', textAlign: 'center'}}>Metadata for Point</h3>

              {
                meta['point'].map(tup => {
                  return (
                    <div key={tup[0]} style={{display: 'flex'}}>
                      <div style={{width: '175px', padding: '2px', fontSize: '16px', fontWeight: '700', textAlign: 'center'}}>{tup[0]}</div>
                      <div style={{boxSizing: 'border-box', padding: '2px 2px 2px 10px'}}>{tup[1]}</div>
                    </div>
                  );
                })
              }
            </div>
          }

          <div style={{border: '3px double black', boxSizing: 'border-box', width: '605px', margin: '10px 20px 20px 20px'}}>
            <h3 style={{fontSize: '19px', fontWeight: '700', margin: '10px 0px', textAlign: 'center'}}>Metadata for {options.duration || `Nearest ${options.duration}`} Station</h3>

            {
              meta['station'].map(tup => {
                return (
                  <div key={tup[0]} style={{display: 'flex'}}>
                    <div style={{width: '175px', padding: '2px', fontSize: '16px', fontWeight: '700', textAlign: 'center'}}>{tup[0]}</div>
                    <div style={{boxSizing: 'border-box', padding: '2px 2px 2px 10px'}}>{tup[1]}</div>
                  </div>
                );
              })
            }
          </div>
          
          <div style={{border: '1px solid black', width: '300px', boxSizing: 'border-box', margin: '10px 20px 20px 20px'}}>
            <h3 style={{fontSize: '19px', fontWeight: '700', margin: '10px 0px', textAlign: 'center'}}>Partial Duration Series - {options.duration}</h3>

            <ul style={{textDecoration: 'none', padding: '2px'}}>
              {
                pds.map(pair => {
                  return (
                    <li key={pair[0] + pair[1]} style={{display: 'flex', justifyContent: 'center', gap: '40px', padding: '2px', fontFamily: 'monospace', fontSize: '11pt'}}>
                      <div>{round2Digits(pair[0])}</div>
                      <div>{pair[1]}</div>
                    </li>
                  );
                })
              }
            </ul>
          </div>

          <a href="http://www.nrcc.cornell.edu/"><img src={process.env.PUBLIC_URL + '/assets/Logos/logo_acis.png'} alt='Powered by ACIS' style={{width: '144px'}}></img></a>
        </main>
      );
    } catch (e) {
      console.log(e);
      return (
        <main>
          <div style={{maxWidth: '700px', minWidth: '500px', margin: '0 auto', border: '1px solid rgb(128,128,128)'}}>
            There was an error getting your data. Please try again later.
          </div>
        </main>
      ); 
    }
  } else {
    return (
      <main>
        <div style={{maxWidth: '700px', minWidth: '500px', margin: '0 auto', border: '1px solid rgb(128,128,128)'}}>
          NO OPTIONS WERE PROVIDED
        </div>
      </main>
    );
  }
}

PdsResults.propTypes = {
  options: PropTypes.object.isRequired,
  pds: PropTypes.array,
  meta: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object
  ]),
};