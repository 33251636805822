import ReactDOMServer from 'react-dom/server';
import pako from 'pako';

import { round3Digits, round2Digits } from '../HelperScripts/rounding';

export function downloadAsHtml(component, fileName) {
  var text = ReactDOMServer.renderToStaticMarkup(component);

  var element = document.createElement('a');
  element.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
  );
  element.setAttribute('download', fileName);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

function downloadCSV(dataArr, fileName, mimeType) {
  var dataContent = '';

  dataArr.forEach(function (infoArray, index) {
    let dataString = infoArray.join(', ');
    dataContent += index < dataArr.length ? dataString + '\n' : dataString;
  });

  var a = document.createElement('a');
  mimeType = mimeType || 'application/octet-stream';

  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(
      new Blob([dataContent], {
        type: mimeType,
      }),
      fileName
    );
  } else if (URL && 'download' in a) {
    a.href = URL.createObjectURL(
      new Blob([dataContent], {
        type: mimeType,
      })
    );
    a.setAttribute('download', fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    location.href =
      'data:application/octet-stream,' + encodeURIComponent(dataContent);
  }
}

function convertData(options, data) {
  const TIMES_LIST = [
    '5min',
    '10min',
    '15min',
    '30min',
    '60min',
    '120min',
    '3hr',
    '6hr',
    '12hr',
    '24hr',
    '2day',
    '4day',
    '7day',
    '10day',
  ];
  const YEARS_LIST = ['1', '2', '5', '10', '25', '50', '100', '200', '500'];
  const HEADER_ROW = [
    'Freq (yr)',
    '5-min',
    '10-min',
    '15-min',
    '30-min',
    '60-min',
    '120-min',
    '3-hr',
    '6-hr',
    '12-hr',
    '24-hr',
    '2-day',
    '4-day',
    '7-day',
    '10-day',
  ];
  let dataArr = [
    [
      'Northeast Regional Climate Center Extreme Precipitation estimates (inches)',
    ],
    [
      'Point Estimates',
      `${options['Smoothing'] === 'Yes' ? 'Smoothed' : 'Unsmoothed'}`,
    ],
    ['Data series', 'Partial duration series'],
    ['State', options['State']],
    ['Location', options['Name']],
    ['Lon (dd)', round3Digits(options['lon'])],
    ['Lat (dd)', round3Digits(options['lat'])],
    ['Elev (feet)', options['elev']],
  ];

  [
    'MEAN PRECIPITATION FREQUENCY ESTIMATES',
    'UPPER LIMIT PRECIPITATION FREQUENCY ESTIMATES',
    'LOWER LIMIT PRECIPITATION FREQUENCY ESTIMATES',
  ].forEach((title, index) => {
    dataArr.push([]);
    dataArr.push([title]);
    dataArr.push(HEADER_ROW);

    YEARS_LIST.forEach((year) => {
      let year_key = year + 'yr';
      let row = [year];

      TIMES_LIST.forEach((time) => {
        row.push(round2Digits(data[time][year_key][index]));
      });

      dataArr.push(row);
    });
  });

  dataArr.push([]);
  dataArr.push([`Date/time: ${Date()}`]);

  return dataArr;
}

export function downloadAsTextCSV(options, data, fileName) {
  let dataArr = convertData(options, data);
  downloadCSV(dataArr, fileName, 'text/csv;encoding:utf-8;');
}

export function downloadAsTextTbl(dataArr) {
  let fileName = 'distribution_curves_table.tbl';
  let mimeType = 'text/csv;encoding:utf-8;';

  var dataContent = '';

  dataArr.forEach(function (infoArray, index) {
    let row = infoArray.map((val) => (val === '' ? '       ' : val));

    let dataString = row.join('   ');
    dataContent += index < dataArr.length ? dataString + '\n' : dataString;
  });

  var a = document.createElement('a');
  mimeType = mimeType || 'application/octet-stream';

  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(
      new Blob([dataContent], {
        type: mimeType,
      }),
      fileName
    );
  } else if (URL && 'download' in a) {
    a.href = URL.createObjectURL(
      new Blob([dataContent], {
        type: mimeType,
      })
    );
    a.setAttribute('download', fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    location.href =
      'data:application/octet-stream,' + encodeURIComponent(dataContent);
  }
}

export function downloadGIS(url) {
  fetch(url)
    .then((res) => res.arrayBuffer())
    .then((arrBuff) => pako.inflate(arrBuff))
    .then((buff) => new TextDecoder().decode(buff))
    .then((str) => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(new Blob([str], { type: 'text/plain' }));
      link.download = 'output.asc';
      link.click();
    })
    .catch(console.error);
}

export function downloadMap(url) {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      if (blob.type === 'image/jpeg') {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'output.jpg';
        link.click();
      } else {
        alert(
          'Unfortunately this map does not exist in this color scheme. Please change the "display" option and try again. We are sorry for the inconvenience.'
        );
      }
    })
    .catch(console.error);
}
