import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { v4 as uuidv4 } from 'uuid';

// Create style classes for entire component
const useStyles = makeStyles(() => ({
  cell: {
    border: '2px solid rgb(103,103,103)',
    padding: '4px 16px',
    textAlign: 'center'
  },
  topRow: {
    borderTop: 'none',
    fontWeight: '700',
    fontSize: '13pt',
    fontFamily: '"Arial", "sans-serif"'
  },
  firstCol: {
    borderLeft: 'none',
    fontWeight: '700',
    fontSize: '13pt',
    fontFamily: '"Arial", "sans-serif"'
  },
  date: {
    color: 'rgb(170,0,0)'
  },
  table: {
    margin: '50px auto 0px'
  }
}));

export default function DailyTable({ data, LEGEND_BINS, YEARS_LIST }) {
  const classes = useStyles();
  
  const renderTable = () => {
    let date = Object.keys(data).find(key => !key.includes('yr') && key !== 'meta');

    return [date, ...YEARS_LIST].map(dur => {
      return (
        <tr key={uuidv4()}>
          <th className={`${classes.cell} ${classes.firstCol} ${dur === date && classes.date}`}>{dur}</th>

          {
            data[dur].map((val, i) => {
              let color;
              if (dur === date) {
                color = YEARS_LIST.reduce((c, yr) => {
                  return parseFloat(val) >= parseFloat(data[yr][i]) ? LEGEND_BINS[yr] : c;
                }, 'inherit');
              } else {
                color = LEGEND_BINS[dur];
              }
              
              return <td key={uuidv4()} className={classes.cell} style={{backgroundColor: color}}>{val}{val === 'Missing Data' ? '' : '"'}</td>;
            })
          }
        </tr>
      );
    });
  };

  return (
    <table className={classes.table}>
      <thead>
        <tr key={uuidv4()}>
          <th className={`${classes.cell} ${classes.topRow} ${classes.firstCol}`}></th>
          <th className={`${classes.cell} ${classes.topRow}`}>1day</th>
          <th className={`${classes.cell} ${classes.topRow}`}>2day</th>
          <th className={`${classes.cell} ${classes.topRow}`}>4day</th>
          <th className={`${classes.cell} ${classes.topRow}`}>7day</th>
          <th className={`${classes.cell} ${classes.topRow}`}>10day</th>
        </tr>
      </thead>
      <tbody>
        {
          renderTable()
        }
      </tbody>
    </table>
  );
}

DailyTable.propTypes = {
  data: PropTypes.object,
  LEGEND_BINS: PropTypes.object,
  YEARS_LIST: PropTypes.array,
};