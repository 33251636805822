import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

// Create style classes for entire component
const useStyles = makeStyles(() => ({
  aboutCont: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: '922px',
    height: '602px',
    padding: '10px',
    backgroundColor: '#eeeeee',
    border: '1px solid black',
    boxShadow: '3px 4px 3px 1px rgba(0,0,0,0.7)',
    margin: '0 auto',
    zIndex: '2'
  },
  paraHeading: {
    color: 'white',
    background: 'linear-gradient(90deg, rgba(25,33,103,1) 0%, rgba(25,33,103,1) 90%, rgba(23,163,228,1) 95%, #eeeeee 100%)',
    fontWeight: '400',
    padding: '5px 0px 5px 7px'
  },
  paraText: {
    padding: '6px 12px 18px 12px'
  },
  section: {
    margin: '0px 10px',
    width: '430px'
  },
  mailingList: {
    display: 'flex',
    width: '324px',
    height: '64px',
    border: '2px solid black',
    backgroundColor: 'rgb(255,204,153)',
    '&:hover': {
      backgroundColor: 'rgb(255,255,170)'
    }
  },
  mailingHeader: {
    color: 'rgb(0,0,153)',
    fontSize: '23px',
    fontWeight: '700',
  },
  mailingSubtitle: {
    color: 'black',
    fontSize: '12px',
    fontStyle: 'italic',
    fontWeight: '700',
  },
  noDec: {
    margin: '0px auto 10px auto',
    textDecoration: 'none'
  },
  subscribe: {
    fontSize: '15px'
  },
  sampleCont: {
    height: '180px',
  },
  mapDiv: {
    margin: '10px auto',
    border: '1px solid black',
    height: '180px'
  },
  iconBackground: {
    backgroundColor: 'white',
    borderRadius: '50%',
    height: '48px',
    width: '48px',
    margin: '7px',
    border: '1px solid black'
  },
  helpIcon: {
    height: '48px',
    width: '48px',
    color: '#17177d',
    boxSizing: 'border-box',
    margin: '0px'
  },
  main: {
    position: 'relative',
    zIndex: '1',
    padding: '0 17px'
  }
}));



export default function About() {
  const classes = useStyles();

  return (
    <main className={classes.main}>
      <div className={classes.aboutCont}>
        <section className={classes.section}>
          <Typography variant='body1' className={classes.paraText}>The climatology of very large precipitation events is a critical component of engineering design and regulations for structures and facilities that must withstand or protect against such events. These events can produce localized urban and widespread flooding with damage to property, degradation of water quality, and potential loss of life. On a national level, a comprehensive climatology of rainfall events has not been updated since the early 1960s</Typography>
        </section>
        
        <section className={classes.section}>
          <Typography variant='h3' className={classes.paraHeading}>Past Extreme Rainfall Analyses</Typography>
          <Typography variant='body1' className={classes.paraText}>In New York and New England this is a concern as the current climatology excludes almost 50 additional years of data. The National Weather Service is using a regional approach to update the 1960s analysis with two climatologies completed for the southwestern and middle Atlantic regions of the U.S. The Mid-Atlantic analysis extends as far north as Pennsylvania and thus excludes New York and New England. In these states, several regional and state-specific extreme rainfall analyses were conducted in the 1990 and early 2000s, but even these analyses are over a decade old and differences in the data records used do not provide a consistent regional analysis of rainfall extremes.</Typography>
        </section>
        
        <section className={classes.section}>
          <Typography variant='h3' className={classes.paraHeading}>Extreme Rainfall Since the 1960s</Typography>
          <Typography variant='body1' className={classes.paraText}>The previous climatologies have been based on the premise that the extreme rainfall series do not change through time. Therefore it is assumed that older analyses reflect current conditions. Recent analyses show that this is not the case, particularly in New York and New England where the frequency of 2 inch rainfall events has increased since the 1950s and storms once considered a 1 in 100 year event have become more frequent. Such storms are now likely to occur almost twice as often.</Typography>
        </section>

        <section className={classes.section}>
          <Typography variant='h3' className={classes.paraHeading}>Web Site Features</Typography>
          <Typography variant='body1' className={classes.paraText}>A number of features are included in this website to make it compatible with the NWS analysis for the Middle Atlantic region and to enhance its usability. The design of the site and its products have been reviewed by stakeholders with the U.S. Natural Resource Conservation Service (NRCS), various state agencies, and private engineering consulting firms. The site includes estimates of extreme rainfall for various durations (from 5 minutes to 10 days) and recurrence intervals (1 year to 500 years). These data are interpolated to a 30-second grid. Confidence intervals for these values are also included as are the partial duration rainfall series used in their computation. Regional extreme rainfall maps and graphic products are also available. Precipitation distribution curves can be generated for each grid either directly or from the USDA NRCS Win TR-20 software, eliminating the need to use a static Type II or Type III curve.</Typography>
        </section>
        
        <div className={classes.mapDiv}>
          <a target='_blank' href={process.env.PUBLIC_URL + '/assets/About/map_NRCS_1day_100yr_color.jpg'} rel="noreferrer">
            <img src={process.env.PUBLIC_URL + '/assets/About/sample_map.jpg'} className={classes.sampleCont}></img>
          </a>
        </div>
      </div>
    </main>
  );
}